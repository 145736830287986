<script>
export default {
  props: {
    fieldInfo: {
      type: Object,
      required: true,
    },
    odnumber:null,
    selectedQuotationType:null
  },
  data: function () {
    return {
      isLoading: false,
      language:'Robin'
    };
  }, 
  created(){
   if(this.fieldInfo.select_options==''){
    this.fieldInfo.select_options=[]
   }
  },
  updated(){
console.log(this.fieldInfo);
  },
  methods: {
    getRuleObj(){
      var initialRule={}
      if(this.selectedQuotationType != 1 && this.fieldInfo.name=='client_id'){
        initialRule.required=false
      }else{
        initialRule.required=this.fieldInfo.is_required;
      }
         return initialRule;      
    },
    setSelectedValue:function(item, val, isFor, type){
            this.$emit('setSelectedValue',item, val, isFor, type);
          },  
    onSearchDDL: function(search, objItem, isFor){
            this.$emit('onSearchDDL',search, objItem, isFor);
          },        
          GetOptions(){
       return this.fieldInfo.select_options=this.fieldInfo.select_options?this.fieldInfo.select_options:[];   
          } 
     
  },
};
</script> 
<template>
  <Field class="w-100"
    :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)"
    :rules="{'required':fieldInfo.is_required}"
    v-slot="{errors,changed}">   
    <Multiselect  
      v-model="fieldInfo.value"
      :placeholder="GetFieldPlaceHolder(fieldInfo.label,fieldInfo.display_name,'select')"   
      v-bind:disabled="(fieldInfo.name == 'account_id' && $route.params.reqFromId && $route.params.reqFromId!= '0') || (odnumber == 'Drafted')"
      :filter-results="false"
      :object="true"
       label="name"
       :showNoOptions="true"
       :searchable="true"
       track-by="value"
       :show-labels="false"
      :show-no-results="false"
      :internal-search="false"
      :loading="isLoading"
       :clear-on-select="false" 
       :close-on-select="true"
        open-direction="bottom"
      v-bind:class="{'w-100':true,'is-invalid':errors[0]}" 
      @select="setSelectedValue(fieldInfo,$event)"
      @update:modelValue="changed"
      @search-change="onSearchDDL($event,fieldInfo)"
     :options="GetOptions()">
     <span slot="noOptions">
     Type to search {{ $t(fieldInfo.label,fieldInfo.display_name) }}
    </span>
    </Multiselect>
   <span   :name="fieldInfo.name" class="invalid-feedback d-block">{{errors[0]}}</span>  
  </Field>
</template>

