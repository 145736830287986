<script>
    export default {
   
        props: {
              fieldInfo: {
                type: Object,
                required: true
            }                                              
        },
        data: function () {
            return {                
            }
        },
        methods: {             
           showField:function(field){
            return (field.name !='gin_number' && field.name !='order_number');
           },
           getRuleObj(){
               var initialRule={}
               initialRule.required=this.fieldInfo.is_required;
               initialRule.email=this.fieldInfo.validation_type=='email';
               initialRule.url=this.fieldInfo.dt_code=='url'?true: this.fieldInfo.validation_type=='url'?true:false;
               initialRule.numeric=this.fieldInfo.name=='employees'?true:false;
               
               if(this.fieldInfo.name=='commission_percentage')
               {
                initialRule.regex=/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
                initialRule.between=[0,100];
               }
               if(this.fieldInfo.name=='account_zip_code'){
                initialRule.regex=/^[a-zA-Z0-9 ]*$/;
                initialRule.min=[5];
                initialRule.max=[15];
               }
               else if(this.fieldInfo.validation_type=='email')
               { 
                initialRule.max=[100];
               }else
               if(this.fieldInfo.length)
               {
                initialRule.max=[this.fieldInfo.length];
               }
               return initialRule;
            }
        },       
        
    }
</script>
<template>
    <Field v-if="showField(fieldInfo)" :name="GetFormSchemaFieldName(fieldInfo.label,fieldInfo.display_name)" class="w-100" :rules="getRuleObj()" v-slot="{errors}">
        <input  :name="fieldInfo.name" :id="fieldInfo.name" type="text" v-bind:class="{'form-control w-100' : true,'is-invalid': errors[0]}"
         v-model="fieldInfo.value" :placeholder="GetFieldPlaceHolder(fieldInfo.label,fieldInfo.display_name)"
        />
        <span  v-if="errors[0]" :name="fieldInfo.name" class="invalid-feedback">{{errors[0]}}</span>
    </Field>
    <div class="form-control bg-grey" v-else>{{fieldInfo.value}}</div>
</template>
<!-- <style>
.form-group .input-group span{width: 100%;}
</style> -->