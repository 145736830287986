<template>
    <section>
        <div class="modal my-popups right fade show" role="dialog" style="display: block;">
            <loader :is-visible="isLoading"></loader>
            <div class="modal-dialog mw-1100" role="document">
                <div class="modal-content">
                    <div class="modal-header mheader-user">
                        <button type="button" class="close" @click="onCancel"><i class="fa fa-times"
                                aria-hidden="true"></i></button>
                        <h4 id="myModalLabel" class="modal-title">{{ $t("ManageCampaign") }}</h4>
                    </div>
                    <div class="modal-body">
                        <template>
                            <ul id="divChannelTypes" class="shortcuts-menu pb-3 man_camp">  
                                <li :class="{'position-relative':true, 'active':campaignType == 'email', 'disabled':campaignType != 'email' && campaignId > 0}"> 
                                <a href="javascript:" v-on:click="onClickCampaignType('email')" data-toggle="tooltip" title="" data-placement="bottom" class="short-menu-item" data-toggle-tooltip="tooltip">
                                    <span class="left-icon-m">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                    <span class="short-item-name">
                                        <span class="w-100 float-left">Email Campaign</span>
                                        <span class="font-12 float-left">Enhance your engagement today.</span>
                                    </span>
                                </a>
                            </li>
                            <li :class="{'position-relative':true, 'active':campaignType == 'socialMedia', 'disabled':campaignType != 'socialMedia'  && campaignId > 0}">
                                <a href="javascript:" v-on:click="onClickCampaignType('socialMedia')" data-toggle="tooltip" title="" data-placement="bottom" class="short-menu-item" data-toggle-tooltip="tooltip">
                                    <span class="left-icon-m">
                                        <i class="fa fa-bullhorn"></i>
                                    </span>
                                    <span class="short-item-name">
                                        <span class="w-100 float-left">Social Media Campaign</span>
                                        <span class="font-12 float-left">A place where ideas grow.</span>
                                    </span>
                                </a>
                            </li>
                                <li :class="{'position-relative':true, 'active':campaignType == 'sms', 'disabled':campaignType != 'sms'  && campaignId > 0}">
                                <a href="javascript:" v-on:click="onClickCampaignType('sms')" data-toggle="tooltip" title="" data-placement="bottom" class="short-menu-item" data-toggle-tooltip="tooltip">
                                    <span class="left-icon-m">
                                        <i class="fa fa-comments"></i>
                                    </span>
                                    <span class="short-item-name">
                                        <span class="w-100 float-left">SMS Campaign</span>
                                        <span class="font-12 float-left">Be the first. To ensure higher sales rate.</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                        </template>
                    <div class="border p-3 overflow-auto w-100" style="overflow-x: hidden !important;">
                        <dynamic-form v-if="campaignType == 'email'" ref="AddForm" :schema="FormSchema" :buttons="buttons" v-on:OnSubmit="onSubmit">
                            <template v-slot:tgslot-SelectTemplate="{ data }">
                                <div class="w-100 float-left mt-3"> 
                                    <a class="btn btn-success p-4 d-block btn_template" @click="createTemplate()">
                                        <i class="fa fa-plus mr-2 font-16"></i>Create New Template
                                    </a>
                                </div> &nbsp;
                                <div class="row">
                                    <div class="col-3 float-right">
                                        <input name="search" v-model="searchTemplate" placeholder="Search Template"
                                            type="text" class="form-control" @keyup="onSearch">
                                    </div>
                                </div>
                                <div class="row mt-2"> &nbsp;                                                   
                                    <div class="col-lg-12 mb-3">
                                        <h6>
                                            <span>Choose From Templates:</span> <span class="text-danger">*</span> 
                                        </h6>&nbsp;
                                        <span v-if="isTemplateError" name="template_id" class="error-class">The Template field is required</span>
                                    </div>
                                    <span v-if="!EmailTemplateData.length > 0" class="text-center text-danger no-record ml-4">No record(s) found</span>
                                    <div class="listing col-12 p-0">
                                      <div class="row custom-box-width default_templates">
                                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 default-tag-vissible" v-for="(item, index) in displayedTemplates" :key="index">
                                          <div class="box border positionrelative float-left w-100">
                                            <div class="default-tags">
                                              <div class="defaultpic_control">
                                                <span class="w-100 float-left p-2 font-weight-medium text-secondary">{{ item.TEMPLATE_NAME }}</span>
                                                <a title="Select" href="javascript:;" class="btn btn-select mr-2" @click="Templateclick(item.TEMPLATE_ID)">Select</a>
                                                <a title="View" href="javascript:;" @click="ViewTemplate(item)" class="btn btn-view mr-2">View</a>
                                              </div>
                                            </div>
                                            <div :class="{'img-ico text-center p-4 ticket-attachments ':true,'active':item.TEMPLATE_ID==templateId}">
                                                <!-- <img v-bind:src="PreviewThumbnail(item)"> -->
                                                <div id="textcontent" v-html="item.TEMPLATE_CONTENT"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary mt-2 float-right text-white" v-if="!showAll" @click="showAll = true">View All</button>
                            </template>
                        </dynamic-form>
                        <modalSocialMediaCampaign @GetListing="GetListing" v-if="campaignType=='socialMedia'"></modalSocialMediaCampaign>
                        <modalSMSCampaign v-if="campaignType=='sms'" :reqType="reqType" :CampaignId="campaignId" :reqForm="reqForm"></modalSMSCampaign>
                        <div class="col-lg-6 float-left">
                            <div class="py-2">
                                <span class="mandatory">{{$t('MandatoryString')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <add-email-template v-if="showEmailTemplate" @closepopup="closeemailtemplatepopup"></add-email-template>
        <TemplatePreview v-if="isViewTemplate" :htmlContent="htmlContent" @closepopup="closeemailtemplatepopup"></TemplatePreview>
        <modalManageSchedule v-if="showManageSchedule" :campaignId="campaignId" :campaignName="''" :mailChimpTemplateId="MailChimpTemplateId" :campaignType="campaignType" :reqFrom="reqForm"  @close="showManageSchedule = false"></modalManageSchedule>
    </section>
</template>
<script>
import DataServices from "../../../services/DataService";
import modalSocialMediaCampaign from "./modalSocialMediaCampaign.vue";
import modalSMSCampaign from "./modalSMSCampaign.vue";
import AddEmailTemplate from "./AddEmailTemplate.vue";
import TemplatePreview from "./TemplatePreview.vue";
import modalManageSchedule from "./modalManageSchedule.vue";
export default {
    components:{
        modalSocialMediaCampaign,
        modalSMSCampaign,
        AddEmailTemplate,
        TemplatePreview,
        modalManageSchedule,
    },
    props: { 
        campaignId:  {
            type: Number,
            required: true
        },
        reqType:  {
            type: String
        },
        campaignType: {
            type: String,
            required: true
        },
        reqForm:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            htmlContent:'',
            showManageSchedule: false,
            blobPath: 'https://cogniterblob.blob.core.windows.net/',
            isViewTemplate:false,
            showEmailTemplate:'',
            StaticObj:
            {
                layoutType: "Single",
                Data: [
                    {
                        astype: "SlotField",
                        label: "",
                        name: "CampaignType",
                        value: "",
                        placeholder: "",
                    },
                ],
            },
            StaticObj2:
            {
                layoutType: "Single",
                group_name: 'SelectTemplate',
                group_display_name: this.$t('SelectTemplate'),
                Data: [{
                    astype: "SlotField",
                    name: "SelectTemplate",
                    value: "",
                    placeholder: "",
                },]
            },
            buttons: [
                {
                    type: "submit",
                    class: "btn btn-success",
                    text: "<i class='fa fa-forward pr-2'></i>Next",
                    value: "Save",
                    //onSubmit: this.onSubmit
                },
                {
                    type: "button",
                    class: "btn btn-danger",
                    text: "<i class='fa fa-close pr-2'></i>Cancel",
                    onClick: this.onCancel,
                    isVisible: true,
                },
            ],
            FormSchema: [],
            campaignOwnerDDL: [],
            EmailTemplateData: [],
            SortBy: '',
            SortExp: '',
            PageSize: 10,
            PageNumber: 1,
            showAll: false,
            search: '',
            searchTemplate: '',
            CampaignData: [],
            GroupBy: [],
            isLoading: false,
            CampaignOwnerData: [],
            templateId: 0,
            statusId: 1001,
            CampOwner:'',
            isTemplateError:false,
            CampaignTypeList:[],
            UserId:0,
            ContainerName: null,
            MailChimpTemplateId: 0
        }
    },
    created: async function () {    
        var vm = this;
        vm.UserId= vm.GetUserInfo.ID;
        vm.ContainerName = vm.GetUserInfo.ContainerName;
        await vm.GetCampaignTypes();
        await vm.GetCampaignStatusDDL();
        if( vm.campaignType == 'email'){
            await vm.FetchData();
            await vm.GetDropDownData();
            await vm.emailTemplateData();
        };
        if(vm.reqForm=='socialMedia'){
            vm.campaignType='socialMedia';
        }
        vm.$refs.AddForm.UpdateKeyValue();      
    },
    computed: {
        displayedTemplates() {
            return this.showAll ? this.EmailTemplateData : this.EmailTemplateData.slice(0, 4);
        },
    },
    methods: {
        PreviewThumbnail:function(htmlContent){
            var vm=this;
            var imgPath='';
            if(htmlContent.ATTACHMENT!=null){
                 imgPath=vm.blobPath+'/'+vm.ContainerName+'/'+htmlContent.ATTACHMENT[0].attachement_path;
            }
            return imgPath;
        },
        GetListing: function () {
            var vm = this;
            vm.$emit('GetFBListing');
        },
        ViewTemplate:function(itm){
            this.htmlContent=itm.TEMPLATE_CONTENT;
            this.isViewTemplate=true;
        },
        FetchData: async function () {
            var vm = this;
            vm.isLoading = true;
            const params = `id=${vm.campaignId}&moduleName=CRM&subModuleCode=CRM_CAMPAIGNS&langCode=en`;
            await DataServices.getFormLayout(params).then(async function (response) {
                vm.isLoading = false;
                vm.CampaignData = response.data.data;
                if (vm.CampaignData != null && vm.CampaignData.length > 0) {
                    var groupId = vm.CampaignData[0].group_id;
                    //vm.GroupBy.push(vm.StaticObj);
                    $.each(vm.CampaignData, $.proxy(async function (index, item) {
                        if (item.form_field_visibility == 'NO') {
                            if(item.name == 'template_id'){
                                vm.templateId = item.value;
                            }
                            if(vm.campaignId > 0){
                                if(item.name=='campaign_type'){
                                    var campaignType=vm.CampaignTypeList.filter(x=>x.value==item.value)[0].name.toLowerCase();
                                    if(campaignType=='email'){
                                        vm.campaignType='email';
                                    }else if(campaignType=='sms'){
                                        vm.campaignType='sms';
                                    }else if(campaignType=='facebook' || campaignType=='instagram' || campaignType=='bing' || campaignType=='twitter' || campaignType=='google'){
                                        vm.campaignType='socialMedia';
                                    }
                                }
                            }
                            return;
                        }
                        groupId = item.group_id;
                        if (item.dt_code == 'date') {
                            item.value = item.value == '' ? null : item.value;
                        }
                        if (item.dt_code == 'checkbox') {
                            item.select_value = item.value.split(',');
                        }
                        var chkGroup = $.grep(vm.GroupBy, function (element, index) {
                            return element.group_id == groupId;
                        });
                        if (chkGroup.length == 0) {
                            var returnedData = $.grep(vm.CampaignData, function (element, index) {
                                return element.group_id == groupId;
                            });
                            var finalData = [];
                            returnedData.forEach(item => {
                                if (item.form_field_visibility == 'YES') {
                                    let DataObj = {};
                                    if (item.astype == 'TextField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'NumericField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'CustomSelectField') {
                                        if(vm.campaignId > 0){
                                            vm.CampOwner=item.value;
                                        }else{
                                            vm.CampOwner=vm.UserId;
                                        }
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            config: {
                                                options: []
                                            },
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'SelectField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            config: {
                                                options: JSON.parse(item.select_options)
                                                //options: JSON.parse(item.select_options)
                                            },
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'TextAreaField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'DatetimeField') {
                                        DataObj = {
                                            astype: 'DateField',
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                }
                            })
                            if (returnedData.length > 0) {
                                var objGroup = {
                                    group_id: groupId,
                                    layoutType: "double",
                                    group_name: returnedData[0].group_name,
                                    group_display_name: returnedData[0].group_display_name,
                                    Data: finalData
                                };
                                vm.GroupBy.push(objGroup);
                            }
                        }
                    }))
                    vm.GroupBy.push(vm.StaticObj2);
                    vm.FormSchema = vm.GroupBy;
                }
            });
        },
        async GetDropDownData() {
            var vm = this;
            var url = "id=&moduleName=CRM&type=CRM_USERS";
            vm.isLoading = true;
            await DataServices.getDDLData(url + "&search=").then(response => {
                var json = [];
                if (response.data == '') {
                }
                if (response.data != '') {
                    json = response.data;
                }
                var campDDLData = [];
                vm.CampaignOwnerData = json.DATA;
                if (vm.CampaignOwnerData != null) {
                    vm.CampaignOwnerData.forEach(x => {
                        campDDLData.push({
                            name: x.name,
                            value: x.value
                        })
                    })
                    vm.CampaignOwnerData = campDDLData;

                    vm.GroupBy.forEach(x => {
                        if (x.group_name == 'campaign_details') {
                            x.Data.filter(x=>x.name=='campaign_owner')[0].astype='MultiSelectField';
                            //x.Data.filter(x=>x.name=='campaign_owner')[0].mode='tag';
                            x.Data.filter(x => x.name == 'campaign_owner')[0].config.options = vm.CampaignOwnerData;
                        }
                    })
                    var cObj=vm.CampaignOwnerData.filter(x=>x.value==vm.CampOwner);               
                    var CampObjChild=null;
                    cObj.forEach(item=>{
                        CampObjChild={
                            label:item.name,
                            value:item.value
                            }
                        })
                    vm.FormSchema[0].Data[1].value=CampObjChild;
                }
                vm.isLoading = false;
            });
        },
        closepopup() {
            var vm = this;
            vm.$parent.CloseManageCampaignPopup();
          
        },
        closeemailtemplatepopup(){
            var vm=this;
            vm.showEmailTemplate=false;
            vm.isViewTemplate=false;
        },
        async emailTemplateData() {
            var vm = this;
            var url = 'id=&search=' + vm.search + '&refId=&subModuleCode=CRM_CAMPAIGNS&sortBy=' + vm.SortBy + '&sortExp=' + vm.SortExp + '&pageSize=' + vm.PageSize + '&pageNumber=' + vm.PageNumber;
            DataServices.TemplateListing(url).then(function (response) {
                if (response.data == null || response.data == "") {
                    //vm.NoRecordForModel = true;
                    vm.EmailTemplateData=[];
                }
                else {
                    vm.EmailTemplateData = response.data.data;
                }
            });
        },
        async onSubmit(formfields) {
            var VueObj = this;
            VueObj.isLoading = true;
            if(!VueObj.templateId){
                VueObj.isTemplateError=true;
                VueObj.isLoading = false;
                return false;
            }
            //formfields.CampaignType = VueObj.campaignTypeId;
            formfields.SelectTemplate = VueObj.templateId;
            var data = [];
            var fullJson = VueObj.CampaignData;
            $.each(fullJson, function (key, item) {
                if (item.name == 'campaign_name') {
                    item.value = formfields.campaign_name;
                }
                else if (item.name == 'campaign_owner') {
                    item.value = formfields.campaign_owner;
                }
                else if (item.name == 'campaign_description') {
                    item.value = formfields.campaign_description;
                }
                else if (item.name == 'campaign_type') {
                    VueObj.CampaignTypeList.forEach(res =>{
                    var campType = res.name.toLowerCase();
                    if(campType==VueObj.campaignType){
                        item.value=res.value;
                    }
                })
                }
                else if (item.name == 'status_id') {
                    item.value = VueObj.statusId;
                } else if (item.name == 'template_id') {
                    item.value = VueObj.templateId.toString();
                }
                var fieldValue = (item.name == "campaign_id" ? VueObj.campaignId : item.value);
                if (item.name == "campaign_id" && VueObj.reqType=='Clone') {
                    fieldValue = 0;
                }
                if (item.dt_code == "date") {
                    fieldValue = item.value;
                }
                if (item.dt_code == "checkbox") {
                    var filtered = item.select_value.filter(function (el) {
                        return (el != null && el != "");
                    });
                    fieldValue = filtered.join(',');
                }
                if (item.dt_code == "select") {
                    try {
                        fieldValue = (typeof fieldValue === 'object' && fieldValue !== null) ? fieldValue.value : fieldValue
                    } catch (ex) { console.log(ex) }
                }
                var dataObject = {
                    field_name: item.name,
                    field_value: fieldValue,
                    custom_field_id: item.custom_field_id
                };
                data.push(dataObject);
            });
            var postJSON = {
                data: data,
            };
            var objectPostString = JSON.stringify(postJSON);
            await DataServices.PostCampaign(objectPostString).then(async function (response) {
                VueObj.isLoading = false;
                if (response.data[0].Code == "1") {
                    var emailTempData = VueObj.EmailTemplateData.filter(x=>x.TEMPLATE_ID==VueObj.templateId)[0];
                    await VueObj.AddOrUpdateMailChimpTemplate(emailTempData.TEMPLATE_ID, emailTempData.MAILCHIMP_TEMPLATE_ID,emailTempData.TEMPLATE_NAME,emailTempData.TEMPLATE_CONTENT);
                    if (VueObj.campaignId > 0 && VueObj.reqType=='Update') {
                        VueObj.ShowAlert(VueObj.$t('UpdatedSuccess', [VueObj.$t('Campaign')]), "success", true, VueObj.$t("Alert"));
                        if(VueObj.reqForm!='wizard'){
                            VueObj.$parent.CampaignId=response.data[0].Value;
                            VueObj.$parent.isShowManageSchedule=true;
                            VueObj.onCancel();
                        }
                    }
                    else if (VueObj.campaignId > 0 && VueObj.reqType=='Clone') {
                        VueObj.ShowAlert(VueObj.$t('CloneSuccess', [VueObj.$t('Campaign'),formfields.campaign_name]), "success", true, VueObj.$t("Alert"));
                        VueObj.onCancel(); 
                    }
                    else {
                        VueObj.ShowAlert(VueObj.$t('AddedSuccess', [VueObj.$t('Campaign')]), "success", true, VueObj.$t("Alert"));
                        if(VueObj.reqForm!='wizard'){
                            VueObj.$parent.CampaignId=response.data[0].Value;
                            VueObj.$parent.CampaignName=formfields.campaign_name;
                            VueObj.$parent.isShowManageSchedule=true;
                            VueObj.$parent.MailChimpTemplateId=VueObj.templateId;
                            VueObj.onCancel();
                        }else if(VueObj.reqForm == 'wizard'){
                            VueObj.MailChimpTemplateId=  VueObj.$parent.MailChimpTemplateId;
                            VueObj.showManageSchedule = true;
                        }
                    }
                    if(VueObj.reqForm!='wizard'){
                        VueObj.$parent.FetchData();
                    }
                }
                else {
                    VueObj.ShowAlert(VueObj.$t('AddedError', [VueObj.$t('Campaign')]), "failure", true, VueObj.$t("Alert"));
                }
            });           
        },
        async AddOrUpdateMailChimpTemplate(templateId,mailChimpTemplateId,templateName,htmlContent){
            var vm = this;
            if(mailChimpTemplateId==null){
                mailChimpTemplateId=0;
            }
            var jsonObj ={
                templateId:mailChimpTemplateId,
                templateName:templateName,
                folderId:null,
                htmlContent:htmlContent
            }
            
            var url = JSON.stringify(jsonObj);
           await DataServices.AddOrUpdateTemplate(url).then(async function(response){
                if(response.data !=null){
                    var mailChimpTemplateId= response.data.id;
                    vm.$parent.MailChimpTemplateId= mailChimpTemplateId;
                   await vm.AddUpdateMailChimpTemplateId(templateId,mailChimpTemplateId)
                }
            })
        },
        async AddUpdateMailChimpTemplateId(templateId,mailChimpTemplateId){
            var jsonObj ={
                templateId:templateId,
                mailChimpTemplateId:mailChimpTemplateId
            }
            var url = JSON.stringify(jsonObj);
           await DataServices.AddUpdateMailChimpTemplateId(url).then(function(response){
            })
        },
        async onClickCampaignType(type) {
            var vm = this;
            if(type != ''){
                vm.isLoading = true;
                vm.campaignType = type;
            }
            vm.isLoading = false;
        },
        async onSearch() {
            var vm = this
            vm.search = vm.searchTemplate;
            vm.emailTemplateData();
        },
        async Templateclick(item) {
            var vm = this;
            vm.templateId = item;
            vm.isTemplateError=false;
        },
        async createTemplate() {
            var vm =this;
            vm.showEmailTemplate=true;
        },
        async GetCampaignTypes() {
            var vm = this;
            var url = `id=&moduleName=CRM&type=CRM_CAMPAIGN_TYPES&search=&refId=`;
            await DataServices.getDDLData(url).then(function (response) {
                vm.CampaignTypeList = [];
                if(response.data !=''){
                    var types = response.data.DATA;
                    types.forEach((item, index) => {
                        vm.CampaignTypeList.push({
                            name: item.name,
                            value: item.value
                        });
                    });
                }
            });
        },
        async GetCampaignStatusDDL() {
            var vm = this;
            var url = `id=&moduleName=CRM&type=LEAD_DEAL_STATUS&search=&refId=`;
            await DataServices.getDDLData(url).then(function (response) {
                if(response.data !=''){
                    var status = response.data.DATA;
                    vm.statusId = status.filter(x=>x.name.toLowerCase()=='draft')[0].value;
                }
            });
        },
        onCancel(){
            var vm = this;
            if(vm.reqForm=='wizard'){
                vm.$parent.$parent.showAddWizard=false;
            }else{
                vm.closepopup();
            }
        }
    }
}
</script>
<style>

.error-class {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.default_templates .img-ico.ticket-attachments {
    overflow: hidden;

}
.default_templates .img-ico.ticket-attachments {
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}
</style>