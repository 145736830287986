<template lang="en">
<div class="modal d-block my-popups dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <slot name="header">
                    <h5>{{$t('AddNewAccount')}}</h5>
                </slot>
                <button type="button" title="" v-on:click="$emit('close')" class="close" data-original-title="Close">
                    <em class="fa fa-times" aria-hidden="true"></em>
                </button>
            </div>
            <div class="modal-body">
                <dynamic-form lang="en" :buttons="buttons" :schema="FormSchema" v-on:OnSubmit="onSubmit" />
                <div class="col-lg-6">
                    <div class="py-2">
                        <small class="text-danger">{{ $t('MandatoryString') }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DataService from "../../../services/DataService";
export default {
    props:{
        SendThrough: {
            type: String,
            required: true
        },
        ProfileId:{
            type:String,
            required:false
        }
    },
    data() {
        return {
            FormSchema: [{
                layoutType: "double",
                Data: [{
                        astype: "TextField",
                        label: this.$t('Profile/Domain'),
                        name: "Profile",
                        value: "",
                        placeholder: "",
                        validationRules: {
                            "required": true
                        },
                        visibility:true,
                    },
                    {
                        astype: "TextField",
                        label: this.$t('API Key'),
                        name: "Account",
                        value: "",
                        placeholder: "",
                        validationRules: {
                            "required": true
                        },
                        visibility:true,
                    },
                    {
                        astype: "TextField",
                        label: this.SendThrough.toLowerCase()=='mailchimp' ? 'Audience Id' : this.$t('Secret Key'),
                        name: "Token",
                        value: "",
                        placeholder: "",
                        validationRules: {
                            "required": true
                        },
                        visibility:true,
                    },
                    {
                        astype: "TextField",
                        label: this.$t('PhoneNumber'),
                        name: "PhoneNumber",
                        value: "",
                        placeholder: "",
                        validationRules: {
                            "required": true
                        },
                        visibility:true,
                    }
                ]
            }],
            buttons: [
            {
                type: "submit",
                class: "btn btn-success save",
                text: "<i class='fa fa-save pr-2'></i>Save"
            }]
        }
    },
    async created(){
        var vm=this;
        if(vm.ProfileId > 0){
            await vm.GetSMTPProfile(vm.ProfileId);
        }
        vm.onChangeSendThrough();
    },
    methods: {
        onChangeSendThrough: function(){
            var vm = this;
            if(vm.SendThrough == 'MailChimp'){
                vm.FormSchema[0].Data[3].visibility = false;
            }else if(vm.SendThrough == 'SendGrid'){
                vm.FormSchema[0].Data[2].visibility = false;
                vm.FormSchema[0].Data[3].visibility = false;
            }
        },
        async GetSMTPProfile(ths) {
            var vm = this;
            var url = `profileId=${ths}`;
            await DataService.GetEmailProfile(url).then(response => {
                if(response.data != ''){
                var IntegrationData = response.data[0];
                vm.FormSchema[0].Data[0].value = IntegrationData.smtp_server;
                vm.FormSchema[0].Data[1].value = IntegrationData.key;
                vm.FormSchema[0].Data[2].value = IntegrationData.SecretKey;
                vm.FormSchema[0].Data[3].value = IntegrationData.from_email; 
                }
            });
        },
        onSubmit: function (formfields) {
            var vm = this;
            var jsonObj = {         
                send_through: vm.SendThrough,                       // TWILIO>> key=TwilioAccountSid, SecretKey=TwilioAuthToken,FROM_EMAIL=TwilioPhoneNumber,SMTP_SERVER=profilename
                smtp_server: formfields.Profile,                    // SMTP_SERVER >>  DOMAIN Field in Table 
                key: formfields.Account,
                SecretKey: formfields.Token,
                from_email: formfields.PhoneNumber,                 // Form_EMAIL >> Email field in Table 
                profile_id: vm.ProfileId,
                send_through:vm.SendThrough                 
            }
            var url = JSON.stringify(jsonObj);
            DataService.SaveEmailSetting(url).then(response => {
                if(response.data=='INSERTED'){
                    vm.$parent.FillProfileDropdown(vm.SendThrough);
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.SendThrough]),"success", true, vm.$t("Alert"));
                    vm.$parent.FetchRecord();
                    vm.$emit('close');
                }else if(response.data=='UPDATED'){
                    vm.$parent.FillProfileDropdown(vm.SendThrough);
                    vm.ShowAlert(vm.$t('UpdatedSuccess',[vm.SendThrough]),"success", true, vm.$t("Alert"));
                    vm.$parent.FetchRecord();
                    vm.$emit('close');
                }else{
                    vm.ShowAlert(vm.$t('AddedError',[vm.SendThrough]),"danger", true, vm.$t("Alert"));
                }
            });
        }
    }
}
</script>
