<template>
    <div class="modal d-block my-popups dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header">
                        <h5>{{$t('Emails')}}</h5>
                    </slot>
                    <button type="button" title="" v-on:click="$emit('close')" class="close" data-original-title="Close">
                        <em class="fa fa-times" aria-hidden="true"></em>
                    </button>
                </div>
                <div class="modal-body">
                    <dynamic-form  lang="en" :buttons="buttons" :schema="FormSchema" v-on:OnSubmit="onSubmit">
                        <template v-slot:tgslot-Password="{ data }">
                            <div class="input-group">
                                <input type="password" :class="{'form-control w-100':true,'is-invalid':data.error.length !=''}" v-model="data.fieldInfo.value" :name="'Password'">
                                <span v-if="data.error.length !=''" name="Password" class="invalid-feedback">{{data.error}}</span>
                            </div>
                        </template> 
                    </dynamic-form>
                    <div class="col-lg-6">
                        <div class="py-2">
                            <small class="text-danger">{{ $t('MandatoryString') }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataService from "../../../services/DataService";
export default{
    props:{
        SendThrough: {
            type: String,
            required: true
        },
        ProfileId:{
            type:Number,
            required:false
        }
    },
    data(){
        return{
            FormSchema:[
                {
                    layoutType: "double",
                    Data:[
                        {
                            astype: "TextField",
                            label: this.$t('SMTPServer'),
                            name: "SMTPServer",
                            value: "",
                            placeholder: "",
                            validationRules: { "required": true },
                        },
                        {
                            astype: "TextField",
                            label: this.$t('UserName'),
                            name: "UserName",
                            value: "",
                            placeholder: "",
                            validationRules: { "required": true },
                        },
                        {
                            astype: "SlotField",
                            label: this.$t('Password'),
                            name: "Password",
                            value: "",
                            placeholder: "",
                            validationRules: { "required": true },
                        },
                        {
                            astype: "TextField",
                            label: this.$t('FromEmail'),
                            name: "FromEmail",
                            value: "",
                            placeholder: "",
                            validationRules: { "required": true },
                        },
                        {
                            astype: "SelectField",
                            label: this.$t('SMTPPort'),
                            name: "SMTPPort",
                            value: "",
                            placeholder: "",
                            config: {
                                options: [],
                                showAddIcon: false,
                            },
                        },
                        {
                            astype: "RadioField", 
                            label: this.$t("SMTPSecurity"),                          
                            name: "SMTPSecurity",
                            value: false,
                            placeholder: "",
                            validationRules: { "required": false, max: 200 },           
                            config: {
                                options: [{name:'true', value: true },{name:'false', value: false}],                                                                    
                                showAddIcon: false
                            }
                        },
                        {
                            astype: "SelectField",
                            label: this.$t('SMTPType'),
                            name: "SMTPType",
                            value: "",
                            placeholder: "",
                            config: {
                                options: [],
                                showAddIcon: false,
                            },
                        },
                        {
                            astype: "TextField",
                            label: this.$t('FromName'),
                            name: "FromName",
                            value: "",
                            placeholder: "",
                            validationRules: { "required": false },
                        }
                    ]
                }
            ],
            buttons: [
                {
                    type: "submit",
                    class: "btn btn-success save",
                    text: "<i class='fa fa-save pr-2'></i>Save"
                }
            ],


        }
    },
   async created(){
        var vm=this;
        if(vm.ProfileId > 0){
            await vm.GetSMTPProfile(vm.ProfileId);
        }
        await vm.getModuleIdByModuleName();
    },
    methods:{
      async getModuleIdByModuleName() {
            var vm = this;
            var url = "moduleName=Email";
            await DataService.GetModuleIdByModuleName(url).then(response => {
                const moduleId = response.data;
                vm.BindDropdownData(moduleId);
            });
        },
        async BindDropdownData(moduleId) {
            var vm = this;
            var url = `moduleId=${moduleId}&IsWizard=false`;
            await DataService.GetModuleKeys(url).then(response => {
                    var emailConfig = response.data.StorageKeys;
                    let SMPTPPortArray = emailConfig.filter(x=>x.CORE_CONFIG_KEY.toLowerCase()=='smtpport')[0].CORE_CONFIG_OPTIONS.split("^")
                    let SMTPTypeArray = emailConfig.filter(x=>x.CORE_CONFIG_KEY.toLowerCase()=='smtptype')[0].CORE_CONFIG_OPTIONS.split("^")
                    SMPTPPortArray.forEach((itm, index) => {
                        vm.FormSchema[0].Data[4].config.options.push({
                            name: itm,
                            value: itm
                        })
                    })
                    SMTPTypeArray.forEach((itm, index) => {
                        vm.FormSchema[0].Data[6].config.options.push({
                            name: itm,
                            value: itm
                        })
                    })

                });
        },
        async GetSMTPProfile(ths) {
            var vm = this;
            var url = `profileId=${ths}`;
            await DataService.GetEmailProfile(url).then(response => {
                if(response.data && response.data.length>0){
                vm.FormSchema[0].Data[0].value = response.data[0].smtp_server;
                vm.FormSchema[0].Data[1].value = response.data[0].user_name;
                vm.FormSchema[0].Data[2].value = response.data[0].password;
                vm.FormSchema[0].Data[3].value = response.data[0].from_email;
                vm.FormSchema[0].Data[4].value = response.data[0].smtp_port==null ? '' : response.data[0].smtp_port;
                vm.FormSchema[0].Data[5].value = response.data[0].smtp_security;
                vm.FormSchema[0].Data[6].value = response.data[0].smtp_type==null ? '' : response.data[0].smtp_type;
                vm.FormSchema[0].Data[7].value = response.data[0].from_email;            
                }
                 
            });
        },
        onSubmit(formfields, val) {
            var vm=this;
            var jsonObj={
                smtp_server     : formfields.SMTPServer, 
                user_name       : formfields.UserName,
                password        : formfields.Password,
                from_email      : formfields.FromEmail,
                smtp_port       : formfields.SMTPPort,
                smtp_security   : formfields.SMTPSecurity,
                smtp_type       : formfields.SMTPType,
                from_name       : formfields.FromName,
                send_through    : vm.SendThrough,
                profile_id      : vm.ProfileId
            }
            var url = JSON.stringify(jsonObj);
             DataService.SaveEmailSetting(url).then(response => {
                if(response.data=='INSERTED'){
                    vm.$parent.FetchRecord();
                    vm.$parent.FillProfileDropdown(vm.SendThrough);
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Email')]),"success", true, vm.$t("Alert"));
                    vm.$emit('close');
                }else if(response.data=='UPDATED'){
                    vm.$parent.FillProfileDropdown(vm.SendThrough);
                    vm.ShowAlert(vm.$t('UpdatedSuccess',[vm.$t('Email')]),"success", true, vm.$t("Alert"));
                    vm.$parent.FetchRecord();
                    vm.$emit('close');
                }else{
                    vm.ShowAlert(vm.$t('AddedError',[vm.$t('Email')]),"danger", true, vm.$t("Alert"));
                }
                

            });
        }
    }
}
</script>