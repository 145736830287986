import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  async leadListing(params) {
    return http.instance.get("/LeadListing/", { params });
  }
  GetLeadListing(params) {
    return  http.instance.get("/LeadListing?"+ params);
  }
  async GetLeftSearchStatus(params) {
    return await http.instance.get("/Form/GetDDLData/", { params });
  }
  async leadStatus(params) {
    return http.instance.get("/Form/GetDDLData/", { params });
  }
  statusdropdown(params) {
    return http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
  }

   SearchCriteriaListing(params) {
    return  http.instance.get("/Form/SearchCriteriaListing/", { params });
  }
   SearchCriteriaById(params) {
    return  http.instance.get("/Form/SearchCriteriaById?"+params);
  }
  SearchCriteriaByIdView(params) {
    return  http.instanceCommon.get("/Common/ViewFlterById?"+params);
  }
     GetOperator(params) {
        return  http.instance.get("/Form/GetOperator?"+ params);
    }

     GetFieldName(params) {
        return  http.instance.get("/Form/GetFieldName?"+ params);
    }
    async GetDDLData(params) {
        return await http.instance.get("/Form/GetDDLData/", { params });
    }

   SaveFilter(params) {
       return http.instance.post("/Form/SaveFilter", { postString: params })
    }

    GetFavDashboard() {
        return http.instanceCommon.get("/Common/GetFavDashboard");
    }

  CommonUpdateStatus(params) {
      return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  create(data) {
    return http.instance.post("/user", data);
  }
  update(id, data) {
    return http.instance.put("/user/${id}", data);
  }
  delete(id) {
    return http.instance.delete("/user/${id}");
  }
  deleteAll() {
    return http.instance.delete("/user");
  }
  findByNam(name) {
    return http.instance.get("/user?name=${name}");
  }
  async getLeaOwnerBySearch(params) {
    return await http.instance.get("/Form/GetDDLData?" + params);
  }
  getFormLayout(params) {
    return http.instance.get("/FormLayout?" + params)
  }
  getQuickViewDetails(params) {
    return http.instance.get("/FormLayout/View?" + params)
  }
  getFollowupList(params) {
    return http.instance.get("/ActivityListing?" + params)
  }
  deleteActivityData(params) {
    return http.instance.post("/Form/CommonDelete", { postString: params })
  }
  async getDDLData(params) {
    return await http.instance.get("/Form/GetDDLData?" + params);
  }
  updateActivityData(params) {
    return http.instance.get("/updateActivity?" + params)
  }
  SaveLead(params) {
    return http.instance.post("/SaveLead", { postString: params })
  }
  DealListing(params) {
    return http.instance.get("/DealListing/", { params });
  }
  UdpateDealStage(params) {
    return http.instance.post("/udpateDealStage/", { postString: params });
  }
  GetDealListing(params) {
    return http.instance.get("/DealListing?"+ params );
  }
  DealStatus(params) {
    return http.instance.get("/Form/GetDDLData/", { params });
  }
  SaveNoteData(params) {
    return http.instance.post("/SaveNote", { postString: params })
  }
  CommonDelete(params) {
    return http.instance.post("/Form/CommonDelete", { postString: params })
  }
  Addtask(params) { 
    return http.instance.post("/Addtask", { postString: params })
  }
  FormLayoutView(params) {
    return http.instance.get("/FormLayout/View?" + params)
  }
  async GetDynamicForm(params) {
    return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
  }
  async SaveDynamicForm(params) {
    return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
  }
  TimelineListing(params) {
    return http.instance.get("/Form/TimelineListing?" + params)
  }
  ActivityListing(params) {
    return http.instance.get("/ActivityListing?" + params)
  }
  AttachmentListing(params) {
    return http.instance.get("/AttachmentListing?" + params)
  }
  NoteListing(params) {
    return http.instance.get("/NoteListing?" + params)
  }
  async EmailListing(params) {
    return await http.instance.get("/Form/EmailListing?" + params)
  }
  ContactListing(params) {
    return http.instance.get("/ContactListing?" + params)
  }
  SaveProductPrice(params) {
    return http.instance.post("/SaveProductPrice", { postString: params })
  }
  getItemListForAssociate(params) {
    return http.instance.get("/getItemListForAssociate?" + params)
  }
  SaveContactAsPrimary(params) {
    return http.instance.post("/SaveContactAsPrimary", { postString: params })
  }
  GetCallLogsListinge(params) {
    return http.instance.get("/Form/GetCallLogsListing?" + params)
  }
  additionalLeadOwnerListing(params) {
    return http.instance.get("/additionalLeadOwnerListing?" + params)
  }
  reopenLead(params) {
    return http.instance.post("/ReopenLead", { postString: params })
  }
  PrimaryContactDetails(params) {
    return http.instance.get("/PrimaryContactForDeal?" + params);
  }
  CheckDuplicate(params) {    
    return http.instance.get("/Form/CheckDuplicate?" + params);
  }
  DeleteAttachment(params) {
    return http.instance.get("/DeleteAttachment?" + params);
  }
  SaveIndustryType(params) {
    return http.instance.post("/Form/SaveIndustryType", { postString: params, type: 'Industry' })
  }
  ConvertLead(params) {
    return http.instance.post("/ConvertLead", { postString: params })
  }
  RevertToLead(params) {
    return http.instance.post("/RevertAction", { postString: params })
  }
  SaveContactRoleTitle(params) {

    return http.instance.post("/SaveContactRoleTitle", { postString: params })
  }
  SaveLeadSource(params) {
    return http.instance.post("/Form/SaveIndustryType", { postString: params })
  }
  PostDealOrContract(url, params) {
    return http.instance.post(url, { postString: params });
  }
  UploadFile(params) {
    return http.instance.post("/Form/UploadFile", { postString: params });
  }

  DownloadAttachment(params) {
    return http.instance.get("/Form/DownloadAttachment?" + params);
  }
  SaveScheduleCall(params) {
    return http.instance.post("/SaveScheduleCall", { postString: params });
  }
  SearchCriteriaListing(params) {
    return http.instance.get("/Form/SearchCriteriaListing?" + params);
  }
  ClearView(params) {
    return http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  GetViewList(params) {
    return http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  CommonCommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }
  GetViewById(params) {
    return http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  salesOrderListing(params) {
    return http.instance.get("/salesOrderListing?" + params)
  }
  getStageMappListing(params) {
    return http.instance.get("/getStageMappListing?" + params)
  }
  ApplyView(params) {
    return http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  SaveView(params) {
    return http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }
  ViewListFields(params) {
    return http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  ViewListUsers(params) {
    return http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  GetAllLeadOwnerPermission(params) {

    return http.instance.get("/GetAllLeadOwnerPermission?" + params)
  }
  saveAdditionalOwnerPermission(params) {

    return http.instance.post("/saveAdditionalOwnerPermission", { postString: params });
  }
  saveAdditionalDealOwnerPermission(params){
    return http.instance.post("/saveAdditionalDealOwnerPermission", { postString: params });
  }
  ChangeOwner(params) {
    return http.instance.post("/ChangeOwner", { postString: params });
  }
  async getLeaOwnerBySearch(params) {
    return await http.instance.get("/Form/GetDDLData?" + params);
  }
  GetEmailData(params) {

    return http.instance.get("/Form/GetEmailData?" + params)
  }
  AddEmailTemplate(params) {

    return http.instance.post("/Form/AddEmailTemplate", { postString: params });
  }
  GetEmailLog(params) {

    return http.instance.get("/Form/GetEmailLog?" + params)
  }

  CloseLead(params) {

    return http.instance.post("/CloseLead", { postString: params });
  }
  GetDDLDataBySubModule(params) {
     return http.instance.get("/Form/GetDDLDataBySubModule?" + params)
  }
  SendEmail(params) {
    return http.instance.post("/Form/SendEmail", { postString: params });
  }
  SendBill(params) {
    return http.instance.post("/Form/SendBill", { postString: params });
  }
SaveExistingContactWithMapping(params) {

    return http.instance.post("/SaveExistingContactWithMapping", { postString: params });
  }
  SaveContactWithMapping(params){
    return http.instance.post("/SaveContactWithMapping", { postString: params });
  }
  SalesOrderListing(params){
    return http.instance.get("/SalesOrderListing/", {params} );
  }
  ConvertOrder(params){
    return http.instance.post("/ConvertOrder/", { postString: params });
  }
  GetSingleContactDetails(params) {

    return http.instance.get("/GetSingleContactDetails?" + params)
  }
  CommonDeleteData(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { objectPostString: params })
  }
  SaveProductForMapping(params) {

    return http.instance.post("/SaveProductForMapping", { postString: params });
  }
  DeleteAccountBeforeDeal(params) {
    return http.instance.post("/DeleteAccountBeforeDeal", { postString: params });
  }
  PurchaseOrderListing(params){
    return http.instance.get("/PurchaseOrderListing/", {params} );
  }
  StageListing(params){    
    return http.instance.get("/StageListing/", {params} );
  }
  VendorListing(params){       
    return http.instance.get("/VendorListing/", {params} );
  }
  PriceBookListing(params){
    return http.instance.get("/PriceBookListing/",{params});
  }
  PriceBookListing2(params){
    return http.instance.get("/PriceBookListing?"+params);
  }
  QuotesListing(params){ 
    return http.instance.get("/QuotesListing/", {params} );
  } 
  QuotesListingWthQueryString(params){ 
    return http.instance.get("/QuotesListing?"+params);
  } 
  GoodInwardListing(params){
    return http.instance.get("/GoodInwardListing?" + params);
  }
   AccountList(params) {
    return http.instance.get("/AccountList?",  {params} );
  }
  changestausInwards(params){
    return http.instance.post("/changestausInwards", { postString: params });
  }
  GetGoodInwardAssociatedProducts(params){
    return http.instance.get("/GetGoodInwardAssociatedProducts?" + params);
  }
  GetMultiLocationByCompanyId(params){
    return http.instance.get("/GetMultiLocationByCompanyId?" + params);
  }
  GetGoodInwardForPurchaseOrder(params){
    return http.instance.get("/GetGoodInwardForPurchaseOrder?" + params);
  }
  async QuotationDetails(params){
    return await http.instance.get("/QuotationDetails?" + params);
  }
  GetCommunicationModesList(params){
    return http.instance.get("/CommunicationModesListing/", {params} );
  }
  BrandListing(params){
    return http.instance.get("/BrandListing?", {params});
  }
  FetchAddEditBrandData(params){
    return http.instance.get("/BrandListing?brandid=" + params);
  }
  GetGoodInwardForVendor(params){
    return http.instance.get("/GetGoodInwardForVendor?" + params);
  }
  CampaignListing(params) {
    return http.instance.get("/CampaignListing?",  {params} );
  }
  InvoiceListing(params) {
    return http.instance.get("/InvoiceListing/",  {params} );
  }
  ItemTypeListing(params){    
    return http.instance.get("ItemTypeListing?", {params});
  }
  CommunicationModesDelete(params) {
    return http.instance.post("/CommunicationModesDelete"+params);
  }
  CommunicationModesUpdateStatus(params){
    return http.instance.post("/CommunicationModesUpdateStatus"+params );
  }
  SaveBrand(params){
    return http.instance.post("/SaveBrand", { postString: params }); 
 }
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList" );
  }
  GetFullMenu(){
    return http.instanceCommon.get("/Common/GetFullMenu" );
  }
  GetContextMenu(params) {
  return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  GetFeedBack(params){ 
    return http.instance.get("GetFeedBack?", {params});
  }
  GetCallLogsListing(params){ 
    return http.instance.get("GetCallLogsListing?", {params});
  }
  
  ContactGroupListing(params) {
    return http.instance.get("/ContactGroupListing/",  {params})
  }
  ScheduleListing(params) {
    return http.instance.get("/Form/ScheduleListing"+ params );
  }
  CheckDuplicate(params) {
    return http.instance.get("/Form/CheckDuplicate?"+ params)
 }
 SaveContactGroup(params) {
  return http.instance.post("/SaveContactGroup", { postString: params } )
 }
GetDDLDataForAddGroup(params) {
  return http.instance.get("/Form/GetDDLData?"+ params );
}
SaveAccountAddress(params){
  return http.instance.post("/SaveAccountAddress", { postString: params } );
}
AccountAddressListing(params){
  return http.instance.get("/AccountAddressListing?"+ params);
}
ContractListing(params) {
  return http.instance.get("/ContractListing/",  {params} );
}
SaveTax(params){ 
  return http.instance.post("/Form/SaveTax", { postString: params });
}
GetProductTypeTax(params){ 
  return http.instance.get("/GetProductTypeTax?" + params);
}
DeleteClient(params){
  return http.instance.post("/DeleteClient", { postString: params } )
}
SaveClientInfo(params){
  return http.instance.post("/SaveClientInfo", { postString: params } )
}
UserRoleList(params){
  return http.instance.get("/UserRoleList?"+params);
}
GetAssociateLicense(params){
  return http.instance.get("/GetAssociateLicense?"+params);
}
AssociateDeAssociateLicense(params){
  return http.instance.get("/AssociateDeAssociateLicense?"+params);
}
GetProductList(params){
  return http.instance.get("/ItemListing/", {params} );
}
SavePriceBookForMapping(params){
  return http.instance.post("/SavePriceBookForMapping/", { postString: params } );
}
PostPriceBook(params) {
  return http.instance.post("/SavePriceBook", { postString: params })
}
PostContract(params) {
  return http.instance.post("/PostContract", { postString: params })
}
  GetProductList(params){
    return http.instance.get("/ItemListing/", {params} );
  }
  PostDeal(params){
    return http.instance.post("/PostDeal", {postString: params });
  }
  SaveVendor(params){
    return http.instance.post("/SaveVendor", {postString: params });
  }
  SaveStage(params){
    return http.instance.post("/SaveStage", {postString: params });
  }
  SaveContact(params){
    return http.instance.post("/SaveContact", {postString: params });
  }
  GetStatusList(params) {
    return http.instance.get("/GetStatusList?" + params);
  }
  CommunicationModesAdd(params) {
    return http.instance.post("/ManageCommunicationMode", {postString: params });
  }
  GetCommunicationModeData(params) {
    return http.instance.get("/GetCommunicationModeById?id=" + params);
  }
  PostCampaign(params){
    return http.instance.post("/SaveCampaign", {postString: params });
  }
  SaveGoodInward(params){
    return http.instance.post("/SaveGoodInward", {postString: params });
  }
  getPurchaseOrderProductDetails(params){
    return http.instance.get("/getPurchaseOrderProductDetails?"+params);
  }
  SaveOrder(params){
    return http.instance.post("/SaveOrder", {postString: params });
  }
  CheckItemQuantity(params){
    return http.instance.get("/CheckItemQuantity?"+params);
  }
  GetTaxByProduct(params){
    return http.instance.get("/GetTaxByProduct?"+params);
  }
  GetAddressdata(params){
    return http.instance.get("/GetAddressdata?"+params);
  }
  GetQuoteProductbyDeal(params){
    return http.instance.get("/GetQuoteProductbyDeal?"+params);
  }
  GetAccountTypes(){
    return http.instance.get("/GetAccountTypes");
  }
  SaveAccountInfo(params){
    return http.instance.post("/SaveAccountInfo", {postString: params });
  }
  UpdateStatusAccount(params){
    return http.instance.put("/UpdateStatusAccount?"+params);
  }
  TemplateListing(params) {
    return http.instance.get("/Form/TemplateListing?" + params);
  }
  ContactListing(params) {
    return http.instance.get("/ContactListing?" + params);
  }
  ContactGroupListing(params) {
    return http.instance.get("/ContactGroupListing?" + params);
  }
  SaveEmailScheduler(params){
    return http.instance.post("/SaveEmailScheduler", {postString: params });
  }
  AddGroupEmailTemplate(params){
    return http.instance.post("/AddGroupEmailTemplate", {postString: params });
  }
  PriceBookProduct(params){
    return http.instance.get("/PriceBookProduct?" + params);
  }
  ImportContactsPost(params){
    return http.instance.post("/ImportContactsPost",params);
  } 
  GetContactTableColumn(params){
    return http.instance.get("/GetContactTableColumn?" + params);
  }
  GetContactExcelColumn(params){
    return http.instance.get("/GetContactExcelColumn?" + params);
  }
  Importlead(params){
    return http.instance.get("/Importlead?" + params);
  }
  BulkUploadContact(params){
    return http.instance.post("/BulkUploadContact",{postString:params});
  }
  postImportAccountsOrContact(url,params){
    return http.instance.post(url,{postString:params});
  }
  SaveImportlead(params){
    return http.instance.post("/SaveImportlead",{postString:params});
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  SocialConnect(params){
    return http.instance.get("/SocialConnect?" + params);
  }
  TwitterAuth(){
    return http.instance.get("/TwitterAuth");
  }
  ConvertQuoteToProject(params){
    return http.instance.post("/ConvertQuoteToProject",{postString:params});
  }
  GetProductConfig(params){
    return http.instance.get("/GetProductConfig?" + params);
  }
  AddProduct(params){
    return http.instance.post("/AddProduct", params);
  }
  GetProduct(params){
    return http.instance.get("/GetProduct?"+params);
  }
  ConvertToLeadCampaign(params){
    return http.instance.post("/ConvertToLeadCampaign",{postString:params});
  }
  GetTagsByModuleId(params){
    return http.instanceCommon.get("/Tag/GetTagsByModuleId?"+params);
  }
  SaveTag(params) {
    return http.instanceCommon.post("/Tag/SaveTag", { postString: params })
  }
  SendActivationMail(params){
    return http.instance.post("/Email/SendActivationMail?"+params);
  }
  CRMDownloadFile(params){
    return http.instance.get("/Form/CRMDownloadFile?"+params,{ responseType: "blob" });
  }
  GetCompanyLogo(){
    return http.instance.get("/Form/GetCompanyLogo");
  }
  GetReports(params){
    return http.instance.get("/GetReports?"+params);
  }
  gettoDoList(params){    
    return http.instance.get("/gettoDoList?"+params);
  }
  GetDashboardData(params){
    return http.instanceCommon.get("/Common/GetDashboardData?"+params);
  }
  GetStartEndDate_New(params){
    return http.instance.get("/GetStartEndDate_New?"+params);
  }
  GetPinnedView(params){
    return http.instanceCommon.get("/FormLayout/GetPinnedView?"+params);
  }
  PinView(params){
    return http.instanceCommon.post("/FormLayout/PinView", { postString: params });
  }
  ReportsRemove(params){
    return http.instance.get("/ReportsRemove?"+params);
  }
  GetConfigurationSettings(params){
    return http.instance.get("/Form/GetConfigurationSettings?"+params);
  }
  GetUserLicenseModulesReport(){
return http.instance.get("/GetUserLicenseModulesReport");
  }
  GetConfgurationValueByKey(params){
    return http.instance.get("/GetConfgurationValueByKey?"+params);
  }
  ReportsSave(param){
    return http.instance.post("/ReportsSave?"+param);
  }
  ToDoListDashboard(param){
    return http.instanceProject.get("/Project/ToDoListDashboard?"+param);
  }
  DashBoardMinWidgetData(param){
    return http.instanceProject.get("/Project/DashBoardMinWidgetData?"+param);
  }
  GetQuickViewByProjectIdTaskId(param){
    return http.instanceProject.get("/Project/GetQuickViewByProjectIdTaskId?"+param);
  }
  GetStatusIdByCode(param){
    return http.instanceCommon.get("/Common/GetStatusIdByCode?"+param);
  }
  GetApprovalStatusForWeekly(param){
    return http.instance.get("/GetApprovalStatusForWeekly?"+param);
  }
  ProjectListDashboard(param){
    return http.instanceProject.get("/Project/ProjectListDashboard?"+param);
  }
  DownlaodPDF(params){
    return http.instance.post("/Form/DownloadPDF", { postString: params });
  }
  GetCompanyReportDDLTypes(param){
    return http.instance.get("/GetCompanyReportDDLTypes?"+param);
  }
 GetCompanyLeadOwners(param)
  {
    return http.instance.get("/GetCompanyLeadOwners?"+param);
  }
  GetCompanyClientsByCompanyId()
  {
    return http.instance.get("/GetCompanyClientsByCompanyId");
  }
  GetDDLStages()
  {
    return http.instance.get("/GetDDLStages");
  }
  GetCompanyDealOwners(param)
  {
    return http.instance.get("/GetCompanyDealOwners?"+param);
  }
  ReportMain(params){
    return http.instanceCommon.post("/ReportMain", { postString: params });
  }
  async GetUserWizard(){
    return await http.instanceCommon.get("/Wizard/GetUserWizard");
  }
  async GetAllShift(){
    return await http.instanceCommon.get("/Wizard/GetAllShift");
  }
  async PayClassList(){
    return await http.instanceCommon.get("/Wizard/PayClassList");
  }
  async GetAllDesignation(){
    return await http.instanceCommon.get("/Wizard/GetAllDesignation");
  }
  async GetAllRoles(){
    return await http.instanceCommon.get("/Wizard/GetAllRoles");
  }
  async  GetRolesOfUserId(){
    return await http.instanceCommon.get("/Wizard/GetRolesOfUserId");
  }
  async GetTimeZone(){
    return await http.instanceCommon.get("/Wizard/GetTimeZoneList");
  }
  async GetUserMiniInfo(param){
    return await http.instanceCommon.get("/Wizard/GetUserMiniInfo");
  }
  SaveBookMark(params){
    return http.instanceCommon.post("/Common/SaveBookMark", { postString: params });
  }
  async IsHasPrivilegePermission(param){
    return await http.instanceCommon.get("/Wizard/IsHasPrivilegePermission?"+param);
  }
  async AddUserWizard(params) {
    return await http.instanceCommon.post("/Wizard/addUserWizard", { postString: params });
  }
  FetchProjectData(param){
    return http.instanceProject.get("/FormLayout/?"+param);
  }
 async  FetchDataTemplate(param){
    return await http.instanceProject.get("/Project/TemplateListing?"+param);
  }
  TemplateMasterListing(){ 
    return http.instanceProject.get("/Project/TemplateMasterListing");
  }
  TemplateByTemplateId(param){    
    return http.instanceProject.get("/Project/TemplateByTemplateId?"+param);
  }
  async GetConfigurationValueForCompanyByKey(param){
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?"+param);
  }
  async GetHideContextMenuConfig(){
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async SaveHideContextMenuConfig(param){
    return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?"+param);
  }
  DesignationGetStatusList(params) {
    return http.instanceCommon.get("/Common/GetStatusList?" + params);
  }
  CheckDuplicate(params){        
    return http.instanceCommon.get("/Common/CheckDuplicate?" + params)
  }
  SavedDesignationn(params){    
    return http.instanceCommon.post("/Common/SavedDesignation?" + params)
  }
  GetMultiLocationByCompanyId(params){    
    return http.instanceCommon.get("/Common/GetMultiLocationByCompanyId?" + params)
  }
  SavedShift(params){ 
    return http.instanceCommon.post("/Common/SavedShift", { postString: params })
  }
  async GetModuleList(){ 
    return await http.instanceCommon.get("/Common/ModuleList");
  }
  async GetModuleMasterIdByModuleName(params){ 
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?"+params);
  }
  async GetNotifications(params){ 
    return await http.instanceCommon.get("/Notification/GetNotifications?" + params)
  }
  async GetUserTypes(params){
    return await http.instanceCommon.get("/Wizard/GetUserTypes?" + params)
  }
  async GetAllAddedLicenses(params){
    return await http.instanceCommon.get("/Wizard/GetAllAddedLicenses?" + params)
  }
  async GetUserConfiguration(params){
    return await http.instanceCommon.get("/Wizard/GetUserModules?" + params)
  }
  async GetModuleKeys(params){
    return await http.instanceCommon.get("/Wizard/GetModuleKeys?" + params );
  }
  async AssociateDeAssociateLicenses(params){
    return await http.instanceCommon.get("/Wizard/AssociateDeAssociateLicense?" + params)
  }
  SaveChannel(params){    
        return http.instanceCommon.post("/Common/SaveChannel?" + params)
  }
  GetDDLData(params) {    
    return  http.instanceProject.get("/Common/GetDDLData?" + params);
  }
  SavePriority(params){
    return http.instanceCommon.post("/Common/SavePriority?" + params)
  }
  SaveTag(params){        
    return http.instanceCommon.post("/Tag/SaveTag", { postString: params })
  }
  ProjectBaseRate(){    
    return http.instanceProject.get("/Project/ProjectBaseRate");
  }
  GetProjectWorkingDays(params){      
    return http.instanceProject.get("/Project/ProjectWorkingDays?"+params);
  }
  GetMemberListing(params){    
    return http.instanceProject.get("/Project/GetMemberListing?"+params);
  }
   async UnreadAllItems(params){
    return await http.instanceCommon.post("/Notification/UnreadAllItems?" + params)
  }
  async DeleteByID(params){
    return await http.instanceCommon.post("/Notification/DeleteByID?" + params)
  }
  GetUserTitle(){     
    return http.instanceProject.get("/Project/GetUserTitle");
  }
  GetAllRolesOfUserType(params){ 
    return http.instanceProject.get("/Project/GetAllRolesOfUserType?"+params);
  }
  GetAllDepartment (){    
    return http.instanceProject.get("/Project/GetAllDepartment");
  }
  GetAllPolicy(params){    
    return http.instanceProject.get("/Project/GetAllPolicy?"+params);
  }
  SavedUser(params){    
    return http.instanceProject.post("/Project/SavedUser", { postString: params });
  }
  SaveProject(params){    
    return http.instanceProject.post("/Project/SaveProject", { postString: params })
  }
  GetitemDetail(params){
    return http.instanceCommon.get("/Notification/GetItemDetail?" + params );
  }
  async GetModuleIdByModuleName(params){
    return  http.instanceCommon.get("/Common/GetModuleIdByModuleName?" + params);
  }
  async GetModuleKeys(params){
    return  http.instanceCommon.get("/Common/GetModuleKeys?" + params);
  }
  async SaveEmailSetting(params){
    return await http.instance.post("/SaveEmailSetting", {postString: params });
  }
  async GetEmailProfile(params){
    return await http.instance.get("/GetEmailProfile?" + params);
  }
  async SaveScheduleCampaign(params){
    return await http.instance.post("/SaveScheduleCampaign", {postString: params });
  }
  async AddOrUpdateTemplate(params){
    return await http.instance.post("/MailChimp/AddOrUpdateTemplate", {postString: params });
  }
  async AddUpdateMailChimpTemplateId(params){
    return await http.instance.post("/AddUpdateMailChimpTemplateId", {postString: params });
  }
  async AddCampaignToMailChimp(params){
    return await http.instance.post("/MailChimp/AddCampaign", {postString: params });
  }
  async AddMemberToMailChimp(params){
    return await http.instance.post("/MailChimp/AddMembers", {postString: params });
  }
  async GetContacts(params){
    return await http.instance.post("/GetContacts", {postString: params });
  }
  async SendTwilioSMS(params){
    return await http.instance.post("/Twilio/SendTwilioSMS", {postString: params });
  }
  async ConvertFullURLtoTinyURL(params){
    return await http.instance.get("/Twilio/ConvertFullURLtoTinyURL?"+ params);
  }
  async SendRingCentralSMS(params){
    return await http.instance.post("/RingCentral/SendSMS", {postString: params });
  }
  async GetCampaignStats(params){
    return await http.instance.get("/MailChimp/GetCampaignStats?" + params);
  }
  async CloneCampaign(params){
    return await http.instance.get("/CloneCampaign?" + params);
  }
  async FacebookAuth(params){
    return await http.instance.get("/SocialMedia/FacebookAuth?" + params);
  }
  async UploadFacebookPost(params){
    return await http.instance.post("/SocialMedia/UploadFacebookPost", params);
  }
  async UploadInstagramPost(params){
    return await http.instance.post("/SocialMedia/UploadInstagramPost", params);
  }
  async SaveSocialPost(params){
    return await http.instance.post("/SocialMedia/SaveSocialPost", params);
  }
  async GetCampaignStatistics(params){
    return await http.instance.get("/GetCampaignStatistics?" + params);
  }  
  async GetCustomFieldValue(params){
    return await http.instance.get("/GetCustomFieldValue?" + params);
  }
  async GetTriggerFieldValue(params){
    return await http.instance.get("/GetTriggerFieldValue?" + params);
  }  
  async ManageSMSCampaign(params){
    return await http.instance.post("/ManageSMSCampaign", {postString: params });
  }
  async AssignCampaignOwner(params){
    return await http.instance.post("/AssignCampaignOwner", {postString: params });
  }
  async SMSTemplateListing(params) {
    return await http.instance.get("/SMSTemplateListing?" +  params );
  }
  async GetTriggerLinkListing(params) {
    return await http.instance.get("/GetTriggerLinkListing?" +  params );
  }
  async SaveTriggerLink(params){
    return await http.instance.post("/SaveTriggerLink", {postString: params });
  } 
  GetEmailStatisticsDetails(params) {
    return http.instance.get("/GetEmailStatisticsDetails?" +  params );
  }
  CampaignApprovalListing(params) {
   return http.instance.get("/CampaignApprovalListing?" +  params );
  }
  async ApproveRejectCampaign(params){
    return await http.instance.post("/ApproveRejectCampaign", {postString: params });
  } 
  CampaignListingV1(params) {
    return http.instance.get("/CampaignListingV1?",  {params} );
  }
  async InstagramAuth(params){
    return await http.instance.get("/SocialMedia/InstagramAuth?" + params);
  }
  GetTriggerLinkStatistics(params) {
    return http.instance.get("/GetTriggerLinkStatistics?" +  params );
  }
  async UpdateMailChimpId(params){
    return http.instance.post("/UpdateMailChimpId?" +  params );
  }
  async GetCampaignStatsPercentage(params){
    return await http.instance.get("/MailChimp/GetCampaignStatsPercentage?" + params);
  }
  async CampaignStatisticsDetail(params){
    return await http.instance.get("/CampaignStatisticsDetail?" + params);
  }
  async GetSocialMediaPagesList(params){
    return await http.instance.get("/GetSocialMediaPagesList?" + params);
  } 
  async GetSocialMediaPost(params){
    return await http.instance.get("/GetSocialMediaPost?" + params);
  }  
  async SavePostComment(params){
    return await http.instance.post("/SavePostComment", {postString: params });
  } 
  async InsertNotification(params) {
    return await http.instanceCommon.post("/Notification/InsertNotification", { postString: params });
  }
  async GetPostCommentData(params){
    return await http.instance.get("/GetPostCommentData?" + params);
  }  
  
  async GetEmailTemplateDetails(params){
    return await http.instance.get("/Form/GetEmailTemplateDetails?" + params);
}
async UploadFile(params) {
    return await http.instanceCommon.post("/Common/UploadFile" , { postString: params });
}
async GetStatusList(){
  return await http.instance.get("/Form/GetStatusList" );
}
async GetTemplateSubCategoryList(params){
  return await http.instance.get("/Form/GetTemplateSubCategoryList?" + params);
}
async GetTemplateCategoryListForCA(){
  return await http.instance.get("/Form/GetTemplateCategoryListForCA");
}
async GetEmailTemplateDetails(params){
    return await http.instance.get("/Form/GetEmailTemplateDetails?" + params);
}
async AddAdvancedEmailTemplate(params){
  return await http.instance.post("/Form/Add?", params);
}
async GetBase64String(params){
  return await http.instance.get("/SocialMedia/GetBase64String?" + params);
} 
async GetScheduledCampaign(params){
  return await http.instance.get("/GetScheduledCampaign?" + params);
}
async SaveTriggerLinkCount(params){
  return await http.instance.post("/CRM/SaveTriggerLinkCount?" + params);
}
async GetCampaignData(params){
  return await http.instance.get("/GetCampaignData?" + params);
}
async GetPostListing(params){
  return await http.instance.get("/GetPostListing?" + params);
}
async GetCountryData(){
  return await http.instanceCommon.get("HelpAndGuide/GetCountryList");
}
async AddLead(params){
  return await http.instance.post("/AddLead", { postString: params });
}
async GetAllModule(params){
  return await http.instanceCommon.get("/Configuration/GetAllModule");
}
async GetConfigurationData(params) {
  return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
}
async ManageDocUserStorageSettingData(params) {
  return http.instanceCommon.post("/Common/ManageDocUserStorageSettingData", params);
}
async SaveConfigData(params){  
  return await http.instance.post("/SaveConfigData", { postString: params });
}
async IsExtraMapFeature() {  
  return await http.instance.get("/IsExtraMapFeature");
}
async GetStorageSetupStatusDetail(params) {  
  return await http.instance.get("/GetStorageSetupStatusDetail?" + params);
}
GetBlobUrl(params){  
  return http.instanceCommon.get("/Common/GetBlobUrl?"+params );
}
async GetTimeZonee(params){  
  return await http.instance.get("/GetTimeZone",params );
}
GetCurrency(params){
  return http.instanceCommon.get("/Common/GetCurrency?"+params );
}
UploadFile2(params){
  return http.instance.post("/UploadFile2",params );
}
ManageCRMSettingData(params) {  
  return  http.instance.post("/ManageCRMSettingData",params);
}
async GetRead(params){
  return await http.instance.get("/CRM/_Read?" + params);
}
async GetClicked(params){
  return await http.instance.get("/CRM/Clicked?" + params);
}
async GetSubscribed(params){
  return await http.instance.get("/CRM/Subscribed?" + params);
}
GetConfigDataValue(params){
  return http.instance.get("/GetConfigDataValue?"+params);
}
async GetConfigNumberJsonData(params){  
  return await http.instance.get("/Form/GetConfigNumberJsonData?"+params);
}
async SaveConfiguringJsonData(params){    
  return await http.instance.post("/Form/SaveConfiguringJsonData", { postString: params });
}
async GetProgresSetupStatusDetail() { 
  return await http.instance.get("/GetProgresSetupStatusDetail");
}
async GetEnableDisableFeature(params) {
  return await http.instanceCommon.get(
    "/AdvanceFeatur/GetEnableDisableFeature?" + params
  );
}
async SaveEnableDisableFeature(params) {
  return http.instanceCommon.post(
    "/AdvanceFeatur/SaveEnableDisableFeature",
    params
  );
}
async TemplateListForSalesOrder() {
  return http.instanceCommon.get("/Configuration/TemplateListForSalesOrder");
}
}
export default new DataService();
