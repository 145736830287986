<template>  
  <Field :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" class="w-100" :rules="{'required':fieldInfo.is_required}"  
  v-slot="{errors}">
        <vue-tel-input  mode="international" class="w-100" v-model="fieldInfo.value"  :validCharactersOnly="true" @input="onInput"></vue-tel-input> 
        <span  v-if="errors[0]" :name="fieldInfo.name" class="invalid-feedback " >{{errors[0]}}</span>
        <span v-if="phoneError"  class="invalid-feedback d-block" >Enter valid number.</span>
    </Field>  
</template>
<script>
export default {
  props: {
    fieldInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      phoneError:false
    };
  },
  methods: {
    onInput(phone, phoneObject) {
      if(phone){
     if(phoneObject && phoneObject?.formatted.length >0 && (!phoneObject?.valid || phoneObject?.valid==undefined)){
        this.phoneError=true;
     }else
      if (phoneObject?.formatted && phoneObject?.valid) {
        phone=phoneObject.formatted;
        this.fieldInfo.value = phoneObject.formatted;
        this.phoneError=false;
      }
    }
    },
  },
};
</script>

<style>
.vue-tel-input, .vti__input{border-radius:0px;}
.vti__input { font-size: 14px;  font-weight: 300;}
</style>