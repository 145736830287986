<template>
    <div>
        <loader :is-visible="isLoading"></loader>
        <div class="container-fluid p-0">
                            <div class="wizards-steps">
                                <ul>
                                    <li class="wizard-items wiz-active userw">
                                        <a href="javascript:;" class="no_decor">
                                            <span class="wizard-icon"><i class="fa fa-check"></i></span>
                                            <span class="wizard-name">{{ $t('UserType') }}</span>
                                        </a>
                                    </li>
                                    <li class="wizard-items Gen">
                                        <a href="javascript:;" class="no_decor">
                                            <span class="wizard-icon"><i class="fa fa-check"></i></span>
                                            <span class="wizard-name">{{ $t('GeneralSettings') }}</span>
                                        </a>
                                    </li>
                                    <li class="wizard-items Advanced">
                                        <a href="javascript:;">
                                            <span class="wizard-icon"><i class="fa fa-list-alt"></i></span>
                                            <span class="wizard-name">{{ $t('AdvanceSettings') }}</span>
                                        </a>
                                    </li>
                                    <li class="wizard-items Associate">
                                        <a href="javascript:;">
                                            <span class="wizard-icon"><i class="fa fa-address-card-o"></i></span>
                                            <span class="wizard-name">{{ $t('Associate') }} {{ $t('License') }}</span>
                                        </a>
                                    </li>
                                    <li class="wizard-items Configuaration">
                                        <a href="javascript:;">
                                            <span class="wizard-icon"><i class="fa fa-gears"></i></span>
                                            <span class="wizard-name">{{ $t('Configuration') }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="form_outter px-3 mt-3 float-left w-100"> 
                                
                                <Form ref="userForm1" encType="multipart/form-data" tag="form" v-slot="{ valid, errors }">
                                    <section class="main-content">
                                        <div class="container-fluid padding-t_10">                                           
                                            <div
                                                :class="{ 'right-content right-content-expand': true, 'right-content-expand': clientdetail == null }">
                                                <div class="col-md-12 p-0">
                                                    <div class="col-12 p-0" id="myTabContent">
                                                        <div class="col-sm-12 text-center mt-5 px-2" id="UserTypeWizard">
                                                            <h3 class="NU">NORMAL USER</h3>
                                                            <p class="NU">The user type has no access to any configurations
                                                                or setup. And is,
                                                                essentially, the end user working on the assigned tasks.</p>
                                                            <h3 class="PM d-none">PROJECT MANAGER</h3>
                                                            <p class="PM d-none">The user type is a fit for managers who are
                                                                handling their own
                                                                team. He/She can oversee projects, team members, approve
                                                                leaves & timesheet etc.
                                                            </p>
                                                            <h3 class="CA d-none">COMPANY ADMIN</h3>
                                                            <p class="CA d-none">The user type has the full administrator
                                                                access to the entire
                                                                application. He/She can do all the configurations and setup
                                                                the application for
                                                                their company. And can even add more users in the system.
                                                            </p>
                                                            <div class="user_type_choice mt-5">
                                                                <div class="btn-group btn-group-toggle"
                                                                    data-toggle="buttons">
                                                                    <label v-for="(user, userIndex) in ManageUsers" :key="userIndex"
                                                                        :class="{ 'btn btn_cstm active': user.user_type_id == 3, 'btn btn_cstm': user.user_type_id != 3 }">
                                                                        <input v-bind:id="clientdetail.user_type_id"
                                                                            class="dynamic form-check-input custom-control-input cls_usertype"
                                                                            v-model="clientdetail.user_type_id"
                                                                            v-bind:name="clientdetail.user_type_id"
                                                                            v-bind:value="user.user_type_id" type="radio"
                                                                            v-on:change="SetUserType(clientdetail.user_type_id)"
                                                                            checked="checked" />
                                                                        <span class="d-block"><i class="fa fa-user"></i></span>{{ user.user_type_desc }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--LIST TOGGLE FULL SLIDE BUTTON-->
                                                        <!-- <div class="expand-list"
                                                            v-if="clientdetail != null && clientdetail.user_id > 0">
                                                            <a href="javascript:;"
                                                                class="expand-list-btn justify-content-center align-items-center">
                                                                <i class="icon-solid arrow-solid-left"
                                                                    style="font-size:9pt"></i>
                                                            </a>
                                                        </div> -->
                                                        <div class="tab-pane call-detail fade activity-tab show active border-0 p-0 generalsetting d-none"
                                                            id="general-setting" role="tabpanel" v-if="clientdetail"
                                                            aria-labelledby="General-tab">
                                                            <div class="user-guide-custom d-none" id="userGuideSetting">
                                                                {{ clientdetail.user_id == null ? $t('User_Guide_User_add') : $t('Resources.User_Guide_User_edit') }}
                                                            </div>
                                                            <div class="container-fluid pt-2">                                                               
                                                                <div class="row" id="divAttForm">
                                                                    <Form class="row" id="divGenForm" ref="divGenForm" tag="div">                                                                       
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group">
                                                                                <label for="txtFirstName">First
                                                                                    Name</label><span
                                                                                    class="text-danger">*</span>
                                                                                <Field name="First Name" class="w-100"
                                                                                    :rules="{ 'required': true, 'max': 100 }"
                                                                                    v-slot="{ errors }">
                                                                                    <input name="txtFirstName" type="text"
                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                        v-model="clientdetail.first_name" />
                                                                                    <span v-if="errors[0]"
                                                                                        class="invalid-feedback">{{
                                                                                            errors[0]
                                                                                        }}</span>
                                                                                </Field>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group">
                                                                                <label for="clientdetail_last_name">Last
                                                                                    Name</label><span
                                                                                    class="text-danger">*</span>
                                                                                <Field name="Last Name" class="w-100"
                                                                                    :rules="{ 'required': true, 'max': 100 }"
                                                                                    v-slot="{ errors }">
                                                                                    <input name="clientdetail_last_name"
                                                                                        type="text"
                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                        v-model="clientdetail.last_name" />
                                                                                    <span v-if="errors[0]"
                                                                                        class="invalid-feedback">{{
                                                                                            errors[0]
                                                                                        }}</span>
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group">
                                                                                <label for="txtEmail">Email</label><span
                                                                                    class="text-danger">*</span>
                                                                                <Field name="Email" class="w-100"
                                                                                    :rules="{ 'required': true, 'max': 200, 'email': true }"
                                                                                    v-slot="{ errors }">
                                                                                    <input name="txtEmail" type="text"
                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                        v-model="clientdetail.email_id" />
                                                                                    <span v-if="errors[0]"
                                                                                        class="invalid-feedback">{{
                                                                                            errors[0]
                                                                                        }}</span>
                                                                                </Field>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4"
                                                                            v-if="clientdetail.user_id == null || clientdetail.user_id == 0">
                                                                            <div class="form-group">
                                                                                <label for="password">Password</label><span
                                                                                    class="text-danger">*</span>
                                                                                <Field name="password" class="w-100"
                                                                                    rules="required|min:6|max:50|password:@confirm"
                                                                                    v-slot="{ errors }">
                                                                                    <input name="password" type="text"
                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                        v-model="clientdetail.password" />
                                                                                    <span v-if="errors[0]"
                                                                                        class="invalid-feedback">{{
                                                                                            errors[0]
                                                                                        }}</span>
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4"
                                                                            v-if="clientdetail.user_id == null || clientdetail.user_id == 0">
                                                                            <div class="form-group">
                                                                                <label for="ConfirmPassword">Confirm
                                                                                    Password</label><span
                                                                                    class="text-danger">*</span>
                                                                                <Field name="Confirm Password" vid="confirm"
                                                                                    class="w-100"
                                                                                    :rules="{ 'required': true, 'min': 6, 'max': 50 }"
                                                                                    v-slot="{ errors }">
                                                                                    <input name="ConfirmPassword"
                                                                                        type="text"
                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                        v-model="clientdetail.ConfirmPassword" />
                                                                                    <span v-if="errors[0]"
                                                                                        class="invalid-feedback">{{
                                                                                            errors[0]
                                                                                        }}</span>
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>                                                                            
                                                                            <div class="col-lg-12"
                                                                                v-if="clientdetail.is_default ? true : false && canManageUserRoles">
                                                                                <div class="form-group">
                                                                                    <label> assoLicenses:</label>
                                                                                    <div class="col-12 float-left p-0"
                                                                                        id="dvAssociatedLicenses">
                                                                                        <div
                                                                                            class="col-12 border p-1 licdetail">
                                                                                            {{ AssociatedLicenses }}</div>
                                                                                        <div class="col-12 p-0 mt-2">
                                                                                            <a class="aAssociateLicenses btn btn-info formbtn"
                                                                                                href="javascript:;">
                                                                                                <i
                                                                                                    class="fa fa-plus pr-2"></i>
                                                                                                Associate
                                                                                                License
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="clientdetail.is_enable_Login">Enable
                                                                                    Login</label>
                                                                                <div class="form-group">
                                                                                    <label class="switch">
                                                                                        <Field name="Enable Login"
                                                                                            :rules="{ 'required': false }"
                                                                                            slim>
                                                                                            <input
                                                                                                class="form-check-input rdoticketfor custom-control-input dynamic"
                                                                                                type="checkbox"
                                                                                                name="is_enable_LoginN"
                                                                                                ref="theCheckbox"
                                                                                                v-on:change="IsEnableLogin()"
                                                                                                v-bind:model="clientdetail.is_enable_Login"
                                                                                                v-bind:checked="clientdetail.is_enable_Login == true">
                                                                                        </Field>
                                                                                        <span class="slider round">
                                                                                            <span
                                                                                                class="slider-yes">Yes</span>
                                                                                            <span
                                                                                                class="slider-no">No</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 py-2 text-center">
                                                                            <div class="wiz-upimg">
                                                                                <div class="circle">
                                                                                    <span
                                                                                        v-if='clientdetail.gender == null || clientdetail.gender == "m"'>                                                                                        
                                                                                        <img id="uImg"
                                                                                            v-if="clientdetail.avatar == 'profile-img.jpg' || !clientdetail.avatar"
                                                                                            src='/Content/images/DefaultImages/avatar-icon.jpg' />
                                                                                        <span v-else>
                                                                                            <img id="uImg"
                                                                                                :src="UserProfileImage"
                                                                                                alt="ProfileImage" />
                                                                                            <span class="margintop7"><a
                                                                                                    href="javascript:;"
                                                                                                    title="RemoveAvatar"
                                                                                                    id="aRemoveAvatar">RemoveAvatar</a></span>
                                                                                        </span>
                                                                                    </span>
                                                                                    <span v-else>                                                                                        
                                                                                        <img id="uImg" v-if="clientdetail.avatar == 'profile-img.jpg' || !clientdetail.avatar"
                                                                                            src='/Content/images/DefaultImages/avatar-icon-girl.jpg' />
                                                                                        <span v-else>                                                                                            
                                                                                            <img id="uImg" src="GetUserProfileImage" alt="ProfileImage" />
                                                                                            <span class="margintop7">
                                                                                                <a href="javascript:;" title="RemoveAvatar" id="aRemoveAvatar">RemoveAvatar</a></span>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="p-image">
                                                                                    <span class="wiz-button"> Choose Picture</span>
                                                                                    <input type="file" class="wizerd-upload" name="flavatar" id="flavatar" accept=".jpeg,.jpg,.jpe,.gif,.bmp,.dib,.ico,.png,.tif,.tiff" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                    <div id="accordion"
                                                                        class="col-12 AdvanceSettings d-none p-0">
                                                                        <div class="card">
                                                                            <div class="card-header DefaultTab d-none">
                                                                                <a class="card-link collapsed"
                                                                                    data-toggle="collapse"
                                                                                    href="#collapseone">
                                                                                    <div
                                                                                        class="conversation-date heading-border">
                                                                                        <span><i class="fa fa-angle-down rotate-icon pr-1"
                                                                                                aria-hidden="true"></i>AdvanceSettings</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <div id="collapseone" class="collapse show"
                                                                                data-parent="#accordion">
                                                                                <div class="card-body">
                                                                                    <div class="row" id="divAttForm">
                                                                                        <div class="col-lg-4">
                                                                                            <div class="form-group"
                                                                                                v-if="IsTimerOn">
                                                                                                TimeZone: <span
                                                                                                    title="AvoidTimeZoneUpdate">
                                                                                                    <img
                                                                                                        src="/Content/images/inner/lock.png" />
                                                                                                </span>
                                                                                                <div
                                                                                                    class="normal  margintop8">
                                                                                                    {{ TimeZoneDispalyName }}
                                                                                                </div>                                                                                                
                                                                                            </div>
                                                                                            <div v-else class="form-group">
                                                                                                <label
                                                                                                    for="timezone_id">Time
                                                                                                    Zone</label>                                                                                               
                                                                                                <select class="form-control"
                                                                                                    v-model="clientdetail.timezone_id"
                                                                                                    name="timezone_id">
                                                                                                    <option
                                                                                                        v-for="(timezone, timezoneIndex) in TimeZones"
                                                                                                        :key="timezoneIndex"
                                                                                                        v-bind:value="timezone.code">
                                                                                                        {{
                                                                                                            timezone.value }}
                                                                                                    </option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="pri_user_emp_id">Employee
                                                                                                    Id</label>
                                                                                                <Field name="Employee Id"
                                                                                                    class="w-100"
                                                                                                    :rules="{ 'required': false, 'max': 25 }"
                                                                                                    v-slot="{ errors }">
                                                                                                    <input
                                                                                                        name="pri_user_emp_id"
                                                                                                        type="text"
                                                                                                        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                                                                                                        v-model="clientdetail.pri_user_emp_id" />
                                                                                                    <span v-if="errors[0]"
                                                                                                        class="invalid-feedback">{{
                                                                                                            errors[0]
                                                                                                        }}</span>
                                                                                                </Field>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="pri_designation_id">Job
                                                                                                    Title</label>

                                                                                                <div class="input-group">
                                                                                                    <!-- <Field id="designation"
                                                                                                        name="designation"
                                                                                                        :rules="{ 'required': true }"
                                                                                                        v-slot="{ errors }"> -->
                                                                                                        <!-- <select :readonly="clientdetail.user_id == null" class="form-control" v-model="clientdetail.pri_designation_id" name="timezone_id"> -->

                                                                                                        <select
                                                                                                            :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                                                                                                            v-model="clientdetail.pri_designation_id"
                                                                                                            name="timezone_id">

                                                                                                            <option
                                                                                                                v-for="(designation, designationIndex) in Designations"
                                                                                                                :key="designationIndex"
                                                                                                                v-bind:value="designation.designation_id">
                                                                                                                {{
                                                                                                                    designation.designation_name
                                                                                                                }}</option>
                                                                                                        </select>
                                                                                                    <!-- </Field> -->
                                                                                                    <div
                                                                                                        class="input-group-append ml-2">
                                                                                                        <a class="aAddMorePopUp btn-dark theme-primary round-icon-small"
                                                                                                            href="javascript:;"
                                                                                                            @click="OpenDesignationPopUp">
                                                                                                            <i
                                                                                                                class="fa fa-plus pt-7"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <label
                                                                                                for="pri_designation_id">Date
                                                                                                of Hire</label>
                                                                                            <div class="form-group">
                                                                                                <v-date-picker
                                                                                                    :minute-increment="5"
                                                                                                    v-model="clientdetail.pri_dateofjoining"
                                                                                                    class="input-group">
                                                                                                    <template
                                                                                                        v-slot="{ inputValue, inputEvents, togglePopover }">
                                                                                                        <input
                                                                                                            :class="{ 'form-control': true }"
                                                                                                            :value="inputValue"
                                                                                                            v-on="inputEvents" />
                                                                                                        <div @click="togglePopover()"
                                                                                                            class="input-group-append">
                                                                                                            <span
                                                                                                                class="input-group-text"><em
                                                                                                                    class="fa fa-calendar"></em></span>
                                                                                                        </div>
                                                                                                    </template>
                                                                                                </v-date-picker>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <label
                                                                                                for="pri_designation_id">Pay
                                                                                                Class</label>
                                                                                                <div class="form-group">
                                                                                                    <select :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                                                                                                        v-model="clientdetail.pay_class" name="pri_designation_id">
                                                                                                        <option v-for="(payclass, payclassIndex) in PayClassList" :key="payclassIndex"
                                                                                                            v-bind:value="payclass.value">
                                                                                                            {{  payclass.text }}
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="shift_id">Shift</label>
                                                                                                <div class="input-group">
                                                                                                    <select
                                                                                                        class="form-control"
                                                                                                        v-model="clientdetail.shift_id"
                                                                                                        name="shift_id">
                                                                                                        <option
                                                                                                            v-for="(shift, shiftIndex) in AllShift"
                                                                                                            :key="shiftIndex"
                                                                                                            v-bind:value="shift.shift_id">
                                                                                                            {{
                                                                                                                shift.shift_name
                                                                                                            }}</option>
                                                                                                    </select>
                                                                                                    <div class="ml-2">
                                                                                                        <a class="aAddMorePopUp submitbtn btn-dark theme-primary round-icon-small"
                                                                                                            href="javascript:;"
                                                                                                            @click="OpenShiftPopUp">
                                                                                                            <i
                                                                                                                class="fa fa-plus pt-7"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">                                                                                           
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="role_id">Role</label>
                                                                                                <select
                                                                                                    v-if="canManageUserRoles"
                                                                                                    class="form-control"
                                                                                                    v-model="clientdetail.role_id"
                                                                                                    name="role_id">
                                                                                                    <option
                                                                                                        v-for="(role, rolesIndex) in AllRoles"
                                                                                                        :key="rolesIndex"
                                                                                                        v-bind:value="role.role_id">
                                                                                                        {{
                                                                                                            role.role_name }}
                                                                                                    </option>
                                                                                                </select>
                                                                                                <span
                                                                                                    v-else-if="UserRoleName == ''"
                                                                                                    title="NA"
                                                                                                    class="cont_5_label minheight30">NA</span>
                                                                                                <span v-else
                                                                                                    :title="UserRoleName"
                                                                                                    class="cont_5_label minheight30">{{
                                                                                                        UserRoleName }}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="pri_user_emp_id">Hourly
                                                                                                    Rate</label>
                                                                                                <input name="hourly_rate"
                                                                                                    v-on:focusout="validateFloatKeyPress($event)"
                                                                                                    type="text"
                                                                                                    v-bind:class="{ 'form-control w-100': true }"
                                                                                                    v-model="clientdetail.hourly_rate" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row flex-row-reverse mb-2">
                                                                    <div class="col-lg-12 float-left p-0">
                                                                        <div class="py-2"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="LicensesLists !=null && AllLicensesList.length > 0" id="divAssociateLicenseDialog">
                                                            <span class="user-guide">
                                                                <a class="ancuserguide" data-toggle="collapse" href="#collapseReply" role="button" aria-expanded="false" aria-controls="collapseExample">User Guide</a>
                                                                <div id="collapseReply" class="divancuserguide collapse">
                                                                    <div id="scrollbarreplyuserguide"
                                                                        class="custom-scrollbar-js">
                                                                        <div class="con">
                                                                            {{ $t('User_Guide_AssociateLicense') }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <div class="col-sm-12 col-md-12 col-lg-12 form-panel mt-0 p-0">
                                                                <div class="form-content">
                                                                    <form>
                                                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left"
                                                                            id="divtickettupelist">
                                                                            <div class="heading-border">
                                                                                <h5 class="h5"><span>All Licenses</span></h5>
                                                                            </div>
                                                                            <div class="managefolder py-3 px-2">
                                                                                <ul class="list-group"
                                                                                    style="max-height: 400px;min-height: 200px;overflow-y: auto;"
                                                                                    v-if="AllLicensesList != null && AllLicensesList.length > 0">
                                                                                    <!-- {{AllLicensesList}} -->
                                                                                    <li class="list-group-item single-action"  v-for="(license, licenseIndex) in AllLicensesList" :key="licenseIndex">
                                                                                        <strong>{{ license.MODULE_NAME }} ({{ license.LICENSE_COUNT }})</strong>
                                                                                        <span v-if="license.EXISTSFORUSER <= 0">
                                                                                            <a v-if="license.LICENSE_COUNT > 0" class="aAddLicense float-right" href="javascript:;" @click="OnAssociateLicence(license)">
                                                                                                <i class="fa fa-plus-circle text-success action-icon" alt="Click to Associate License" title="Click to Associate License"></i>
                                                                                            </a>
                                                                                            <a v-else class="aAddLicense float-right disabled" href="javascript:;">
                                                                                                <i class="fa fa-plus-circle text-success action-icon disabled" alt="License not Available" title="License not Available"></i>
                                                                                            </a>
                                                                                        </span>
                                                                                        <a v-else class="aAddLicense float-right disabled" href="javascript:;">
                                                                                            <i class="fa fa-plus-circle text-success action-icon disabled" alt="Already Associated" title="Already Associated"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul v-else class="list-group"
                                                                                    style="max-height: 400px;min-height: 200px;overflow-y: auto;">
                                                                                    <li
                                                                                        class="list-group-item list_username list_img">
                                                                                        <strong>License Not Available</strong>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left" id="divAssignedLicence">
                                                                            <div class="heading-border">
                                                                                <h5 class="h5"><span>Added Licenses</span>
                                                                                </h5>
                                                                            </div>
                                                                            <div class="managefolder py-3 px-2">
                                                                                <ul class="list-group" style="max-height: 400px;min-height: 200px;overflow-y: auto;"
                                                                                    v-if="AddedLicensesList != null && AddedLicensesList.length > 0">
                                                                                    <li v-for="(AddedLicense, AddedLicenseIndex) in AddedLicensesList" :key="AddedLicenseIndex" class="list-group-item list_username list_img single-action" data-typename="EMER NEW GRID DGIN">
                                                                                        <strong>{{ AddedLicense.MODULE_NAME}}</strong>
                                                                                        <a class="aRemoveLicense float-right" href="javascript:void(0)"  @click="OnDeAssociateLicense(AddedLicense)">
                                                                                            <i class="fa fa-times-circle text-danger action-icon" alt="Click to Deassociate License" title="Click to Deassociate License"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul v-else class="list-group"
                                                                                    style="max-height: 400px;min-height: 200px;overflow-y: auto;">
                                                                                    <li
                                                                                        class="list-group-item list_username list_img">
                                                                                        <strong>License Not Available</strong>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>                                                                            
                                                                        </div>
                                                                        <div
                                                                            class="col-sm-12 col-md-12 col-lg-12 col-xl-12  popup-footer-button pl-0 pr-0">
                                                                            <a href="javascript:;" title="Close" class="closeassoLicense btn btn-danger d-none"><i class="fa fa-close pr-2"></i>Close</a>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                        <div id="user-configurationwizard" :class="{'d-none':!isShowConfigTab}" v-if="Configuarations.length > 0">
                                                            <div id="tab6Datawizard" v-if="Configuarations.length > 0">                                                               
                                                                <div id="ulConfigSettingTabs" class="activity-tab">
                                                                    <div id="configuration-accordion" class="mb-2" v-if="Configuarations != null">
                                                                        <div class="card" v-for="(module, moduleIndex) in Configuarations" :key="moduleIndex">
                                                                            <div class="card-header DefaultTab">
                                                                                <a :id="module.ModuleId"
                                                                                    :data-text="$t(module.ModuleName + 'TitleConfig') ? module.ModuleTitle : $t(module.ModuleName + 'TitleConfig')"
                                                                                    :class="{ 'card-link': moduleIndex == 1, 'card-link collapsed': moduleIndex != 1 }"
                                                                                    :title="module.ModuleTitle" data-toggle="collapse" :href="'#config-' + module.ModuleId">
                                                                                    <div class="conversation-date heading-border with-accordian">
                                                                                        <span class="w-auto"><em class="fa fa-angle-down action-icon pr-2"></em>
                                                                                            {{ $t(module.ModuleName + "TitleConfig") ? module.ModuleTitle : $t(module.ModuleName + "TitleConfig") }}
                                                                                        </span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <div :id="'config-' + module.ModuleId" :class="{ 'divKeysConfig': true, 'collapse show': moduleIndex == 1, 'collapse': moduleIndex != 1 }"
                                                                                data-parent="#configuration-accordion">
                                                                                <div class="card-body">
                                                                                    <ConfigurationDetail :UserId="clientdetail.user_id" :ModuleId="module.ModuleId"></ConfigurationDetail>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else
                                                                class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 text-center text-danger no-record">
                                                                {{$t('NoRecordfound')}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Form>
                            </div>
                            <div class="btn_pos_bottom col-lg-12 text-right mt-4 fixed-bottom">
                                <div class="col-12 p-0 text-right">
                                    <div class="col-6 p-0 float-left text-left btn_prevoius_ad d-none">
                                        <a href="javascript:;" class="btn btn-info btn_prevoius" @click="OnPreviousad"><i
                                                class="fa fa-angle-left pr-2"></i>Previous</a>
                                    </div>
                                    <div class="col-6 p-0 float-left text-left btn_prevoius_gen d-none">
                                        <a href="javascript:;" class="btn btn-info btn_prevoius" @click="OnPreviousGen"><i
                                                class="fa fa-angle-left pr-2"></i>Previous</a>
                                    </div>
                                    <div class="col-6 p-0 float-left text-left btn_prevoius_asso d-none">
                                        <a href="javascript:;" class="btn btn-info btn_prevoius" @click="OnPreviousAsso"><i
                                                class="fa fa-angle-left pr-2"></i>Previous</a>
                                    </div>
                                    <div class="col-6 p-0 float-left text-left btn_prevoius_config d-none">
                                        <a href="javascript:;" class="btn btn-info btn_prevoius" @click="OnPreviousConfig"><i
                                                class="fa fa-angle-left pr-2"></i>Previous</a>
                                    </div>
                                    <div class="float-right col-6 p-0">
                                        <a href="javascript:;" id="btnUserType" @click="OnUserType"
                                            class="btn btn-primary btn_nxt_user">Next <i
                                                class="fa fa-angle-right pr-2"></i></a>
                                        <a href="javascript:;" id="btnSave1" @click="OnUserSave"
                                            class="btn btn-primary formbtn btn_nxt d-none">Next <i
                                                class="fa fa-angle-right pr-2"></i></a>
                                        <a href="javascript:;" class="btn btn-success btn_save_nxt d-none"
                                            @click="OnSaveAndNext">Save and Next
                                            <i class="fa fa-angle-right pr-2"></i></a>
                                        <a href="javascript:;" id="btn_nxt_asso"
                                            class="btn btn-primary btn_nxt_asso d-none" @click="GoToConfiguration">Next <i
                                                class="fa fa-angle-right pr-2"></i></a>
                                        <a href="javascript:;" class="btn btn-secondary Skip btn_nxt1 d-none">Skip <i
                                                class="fa fa-fast-forward pr-2"></i></a>
                                        <a id="hlkSaveConfig" class="btn btn-success formbtn d-none" href="javascript:;"
                                            title="Click here to save the configuration setting." style=""><i
                                                class="fa fa-save pr-2"></i>Save Settings</a>
                                    </div>
                                </div>
                            </div>
                        </div>
        <designationPopUp v-if="OpenDesignation" @ClosedDesignation="ClosedDesignation"></designationPopUp>
        <ShiftPopUp v-if="OpenShift" @ClosedDesignation="ClosedDesignation"></ShiftPopUp>
    </div>
</template>
<script>
import DataService from '../../services/DataService';
import designationPopUp from '../Wizard/Model/modelDesignation.vue';
import ShiftPopUp from '../Wizard/Model/modelShiftPopUp.vue'
import ConfigurationDetail from '../Wizard/UserConfigurationDetail.vue';
export default {
    components:{designationPopUp,ShiftPopUp,ConfigurationDetail},
    data() {
        return {
            userwizard: null,
            UserMiniInfo: '',
            clientdetail: null,
            UserType: null,
            allDesignation: [],
            ExtraFeature: 'No',
            UserLicenseModules: [],
            ManageUsers: [],
            canManageUserRoles: false,
            UserProfileImage: '',
            IsTimerOn: '',
            TimeZoneDispalyName: '',
            TimeZones: [],
            Designations: [],
            PayClassList: [],
            AllShift: [],
            AllRoles: [],
            UserRoleName: '',
            AllLicensesList: [],
            AddedLicensesList: [],
            Configuarations: [],
            AssociatedLicenses: null,
            OpenDesignation:false,
            OpenShift:false,
            LicensesLists:null,
            CreatedUserType:null,
            isShowConfigTab:false,
            isLoading: false,
        }
    },
    created: async function () {
        this.UserType = this.GetUserInfo.UserType;
        await this.GetUserWizardInfo();
        await this.GetUserTypes();
        await this.GetTimeZone();
        await this.GetRoles();
        await this.IsHasPrivilegePermission();
        await this.GetAllDesignation();
        await this.GetAllShift();
        await this.GetPayClassList();
        await this.GetRolesOfUserId();
    },
    methods: {
        validateFloatKeyPress(el) {
            var v = parseFloat(el.value);
            el.value = (isNaN(v)) ? '' : v.toFixed(2);
        },
        OpenDesignationPopUp:function(){  
            this.OpenDesignation=true;
        },
        ClosedDesignation:function(){          
            this.OpenDesignation=false;
            this.OpenShift=false;
        },
        OpenShiftPopUp:function(){
            this.OpenShift=true;
        },
        async IsHasPrivilegePermission() {
            var vm = this;
            var param = 'privilegeName=CanManageUserRoles';
            await DataService.IsHasPrivilegePermission(param).then(response => {
                vm.canManageUserRoles = response.data;
            });
        },
        GetUserWizardInfo: async function () {
            var vm = this;
            vm.isLoading=true;
            await DataService.GetUserWizard().then(response => {
                vm.isLoading=false;
                vm.userwizard = response.data;
                vm.clientdetail = response.data.client.clientdetail;
                vm.clientdetail.user_type_id=3;
            });
        },
        async GetUserMiniInfo() {
            var vm = this;
            await DataService.GetUserMiniInfo().then(response => {
                vm.UserMiniInfo = response.data.UserMiniInfo;
            });
        },
        GetUserTypes: async function () {
            var vm = this;
            vm.isLoading=true;
            var param='filterby=MANAGEUSER';
            await DataService.GetUserTypes(param).then(response => {
                vm.isLoading=false;
                vm.ManageUsers = response.data;
            });
        },
        GetAllShift: async function () {
            var vm = this;
            await DataService.GetAllShift().then(response => {
                vm.AllShift = response.data;
            });
        },
        GetPayClassList: async function () {
            var vm = this;
            await DataService.PayClassList().then(response => {
                vm.PayClassList = response.data;
            });
        },
        GetAllDesignation: async function () {
            var vm = this;
            await DataService.GetAllDesignation().then(response => {
                vm.Designations = response.data;

            });
        },
        GetRoles: async function () {
            var vm = this;
            await DataService.GetAllRoles().then(response => {
                vm.AllRoles = response.data;
            });
        },
        GetRolesOfUserId: async function () {
            var vm = this;
            await DataService.GetRolesOfUserId().then(response => {
                vm.UserRoleName = response.data;
            });
        },
        GetTimeZone: async function () {
            var vm = this;
            await DataService.GetTimeZone().then(response => {
                vm.TimeZones = response.data;
            });
        },
        SetUserType(userTypeId){
            if(userTypeId==1){
            $(".PM").addClass("d-none");
            $(".NU").addClass("d-none");
            $(".CA").removeClass("d-none");
        }
        else if(userTypeId==2){
            $(".CA").addClass("d-none");
            $(".NU").addClass("d-none");
            $(".PM").removeClass("d-none");
        }
        else{
            $(".CA").addClass("d-none");
            $(".PM").addClass("d-none");
            $(".NU").removeClass("d-none");
        }
        },
        OnUserType() {
            $("#UserTypeWizard").addClass("d-none");
            $(".userw").addClass("wiz-complete");
            $(".userw").removeClass("wiz-active");
            $(".Gen").addClass("wiz-active");
            $(".btn_nxt_user").addClass("d-none");
            $("#general-setting").removeClass("d-none");
            $(".btn_nxt").removeClass("d-none");
            $(".btn_prevoius_gen").removeClass("d-none");
        },
        OnPreviousGen() {
            $(".btn_prevoius_gen").addClass("d-none");
            $("#general-setting").addClass("d-none");
            $(".userw").removeClass("wiz-complete")
            $(".userw").addClass("wiz-active");
            $(".Gen").removeClass("wiz-active");
            $("#UserTypeWizard").removeClass("d-none");
            $(".btn_nxt_user").removeClass("d-none");
            $(".btn_nxt").addClass("d-none");
        },
        OnPreviousad() {
            $("#divGenForm").removeClass("d-none");
            $(".AdvanceSettings").removeClass("d-block");
            $(".AdvanceSettings").addClass("d-none");
            $(".Gen").addClass("wiz-active");
            $(".Gen").removeClass("wiz-complete");
            $(".Advanced").removeClass("wiz-active");
            $(".btn_prevoius_ad").addClass("d-none");
            $(".btn_prevoius_ad").removeClass("d-block");
            $(".btn_nxt").removeClass("d-none");
            $(".btn_save_nxt").removeClass("d-block");
            $(".btn_save_nxt").addClass("d-none");
            $(".btn_prevoius_gen").removeClass("d-none");
        },
        OnPreviousConfig(){
            $('#user-configurationwizard').addClass("d-none");
            $('#divAssociateLicenseDialog').removeClass("d-none");
            $(".btn_prevoius_asso").removeClass("d-none");
            $(".Associate").addClass("wiz-active").removeClass("wiz-complete");
            $(".Configuaration").removeClass("wiz-active");
            $('.btn_prevoius_config').addClass("d-none");
            $("#hlkSaveConfig").addClass("d-none");
            $('#btn_nxt_asso').removeClass("d-none");
        },
        OnPreviousAsso(){
            $("#UserTypeWizard").addClass("d-none");
            $(".btn_prevoius_asso").addClass("d-none");
            $('#btn_nxt_asso').addClass("d-none");
            $('#divAssociateLicenseDialog').addClass("d-none")
            $("#general-setting").removeClass("d-none");
            $(".btn_save_nxt").removeClass("d-none");
            $(".btn_prevoius_ad").addClass("d-block");
            $(".btn_prevoius_ad").removeClass("d-none");
            $(".Advanced").removeClass("wiz-complete");
            $(".Advanced").addClass("wiz-active");
            $(".Associate").removeClass("wiz-active");
        },
        OnAssociateLicence(item){
            var vm=this;
            vm.isLoading=true;
            var url= "moduleMasterID="+item.Module_Master_Id+"&userId="+vm.clientdetail.user_id+"&userType="+vm.CreatedUserType+"&actionType=Associate&IsRemovedAll="+false;
            DataService.AssociateDeAssociateLicenses(url).then(function (response) {
                vm.LicensesLists = response.data;
                vm.AllLicensesList=response.data.allLicenses;
                vm.AddedLicensesList=response.data.addedLicenses;
                vm.isLoading=false;
            });
        },
        OnDeAssociateLicense(item){
            var vm=this;
            vm.isLoading=true;
            var url= "moduleMasterID="+item.MODULE_ID+"&userId="+vm.clientdetail.user_id+"&userType="+vm.CreatedUserType+"&actionType=De-associate&IsRemovedAll="+false;
            DataService.AssociateDeAssociateLicenses(url).then(function (response) {
                vm.LicensesLists = response.data;
                vm.AllLicensesList=response.data.allLicenses;
                vm.AddedLicensesList=response.data.addedLicenses;
                vm.isLoading=false;
            });
        },
        OnUserSave() {
            this.$refs.divGenForm.validate().then((valid, errors) => {
                if (valid) {
                    $("#divGenForm").addClass("d-none");
                    $(".AdvanceSettings").addClass("d-block");
                    $(".Gen").addClass("wiz-complete");
                    $(".Gen").removeClass("wiz-active");
                    $(".Advanced").addClass("wiz-active");
                    $(".btn_prevoius_ad").removeClass("d-none");
                    $(".btn_prevoius_ad").addClass("d-block");
                    $(".btn_nxt").removeClass("d-block");
                    $(".btn_nxt").addClass("d-none");
                    $(".btn_save_nxt").removeClass("d-none");
                    $(".btn_prevoius_gen").addClass("d-none");
                }
            })
        },
        GoToConfiguration(){
            var vm=this;
            vm.GetUserConfiguration(vm.clientdetail.user_id);
            $('#divAssociateLicenseDialog').addClass("d-none");
            $(".btn_prevoius_asso").addClass("d-none");
            $(".Associate").removeClass("wiz-active").addClass("wiz-complete");
            $(".Configuaration").addClass("wiz-active");
            $('.btn_prevoius_config').removeClass("d-none");
            $("#hlkSaveConfig").removeClass("d-none");
            $('#btn_nxt_asso').addClass("d-none");
            $('#user-configurationwizard').removeClass("d-none");
            vm.isShowConfigTab=true;
            
        },
        async GetAllAddedLicenses(userType,userId){
            var vm = this;
            var url = "userType="+userType +"&createdUserId="+userId;
           await DataService.GetAllAddedLicenses(url).then(response => {
                if(response.data!=null){
                    vm.isLoading=false;
                    vm.LicensesLists=response.data;
                    vm.AllLicensesList=response.data.allLicenses;
                    vm.AddedLicensesList=response.data.addedLicenses;
                }
                else{
                    vm.isLoading=false;
                }
            });
        },
       async GetUserConfiguration(userId){
            var vm = this;
            vm.isLoading=true;
            var url = "userId="+userId;
            await DataService.GetUserConfiguration(url).then(response => {
                if(response.data.Result!=null){
                    vm.Configuarations=response.data.Result;
                    vm.isLoading=false;
                }
            });
        },
        IsEnableLogin(){
            var vm = this;
            vm.clientdetail.is_enable_Login=vm.$refs.theCheckbox.checked;
        },
        OnSaveAndNext() {
            var vm = this;
            this.$refs.userForm1.validate().then((result) => {
            if (result) {
                vm.isLoading=true;
            var postJSON = {
                //UserProfile:{
                    Clientdetail: vm.clientdetail
                //}
                
            };
            var objectPostString = JSON.stringify(postJSON);
             DataService.AddUserWizard(objectPostString).then(response => {
                vm.isLoading = false;
                var data = response.data;
                if (data.status == "failure") {
                    vm.isLoading = false;
                    if (data.statusMessage == "Email already exists") {
                        vm.OnPreviousad();
                        vm.isLoading=false;
                    }
                    return false;
                }
                else if (data.status == "success" && (data.statusMessage == 0 || data.statusMessage == "0")) {
                    vm.isLoading = false;
                    vm.ShowAlert(vm.$t("Something_Wrong"), "failure", true, vm.$t("Alert"));
                    vm.isLoading=false;
                    return false;
                }else{                    
                    vm.clientdetail.user_id=data.statusMessage;
                    if(vm.clientdetail.user_type_id==1){
                        vm.CreatedUserType='CA';
                    }
                    else if(vm.clientdetail.user_type_id==2){
                        vm.CreatedUserType='PM';
                    }else{
                        vm.CreatedUserType='NU';
                    }
                    vm.GetAllAddedLicenses(vm.CreatedUserType,vm.clientdetail.user_id);
                        $(".closeassoLicense").addClass("d-none");
                        $("#UserTypeWizard").addClass("d-none");
                        $('#divAssociateLicenseDialog').removeClass("d-none")
                        $("#general-setting").addClass("d-none");
                        $(".btn_save_nxt").addClass("d-none");
                        $(".btn_prevoius_ad").removeClass("d-block");
                        $(".btn_prevoius_ad").addClass("d-none");
                        $(".Advanced").removeClass("wiz-active");
                        $(".Advanced").addClass("wiz-complete");
                        $(".Associate").addClass("wiz-active");
                        $('.btn_prevoius_asso').removeClass("d-none");
                        $('#btn_nxt_asso').removeClass("d-none");
                }
                
       })
           
          }
           
        })

    }
}
}
</script>


<style>.wiz-active .wizard-icon i {
    border: 3px solid #24a5ff;
    color: #fff;
    background: #24a5ff;
}

.wizard-icon i {
    width: 55px;
    height: 55px;
    line-height: 50px;
    border: 3px solid #e1e1e1;
    border-radius: 50%;
    font-size: 16px;
    color: #a0a0a0;
    z-index: 1;
    position: relative;
    background: #fff;
}
</style>