<template>
  <Field slim :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" :rules="{ 'required': fieldInfo.is_required }" v-slot="{ errors }">
                                  <ckeditor class="w-100" :editor="editor" v-model="fieldInfo.value" ></ckeditor>
                                  <!-- <span  v-if="errors[0]" class="invalid-feedback d-block">{{ errors[0] }}</span> -->
                              </Field>
</template>
<script>
export default {
  props: {
    fieldInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      editor: this.CLASSIC_EDITOR,
      content: "<h1>Some initial content</h1>",
    };
  }
};
</script>