<template>
    <section class="main-content">
        <div class="modal my-popups fade show" role="dialog" style="display: block;">
            <div class="modal-dialog" role="document" style="max-width: 1270px;">
                <div class="modal-content">
                    <div class="modal-header mheader-user">
                        <button type="button" class="close" @click="$emit('closepopup')"><i class="fa fa-times"
                                aria-hidden="true"></i></button>
                        <h4 id="myModalLabel" class="modal-title">{{ $t("AddTemplate") }}</h4>
                    </div>
                    <div class="modal-body">
                        <loader :is-visible="isLoading"></loader>
                        <div class="container-fluid border-0 m-0 p-0">
                            <div class="w-100">
                                <div class="col-md-12 p-0">
                                
                                </div>
                            </div>
                        </div>
    
                        <div class="border p-3">
                            <dynamic-form :schema="FormSchema" ref="AddEditEmail" :buttons="buttons" v-on:OnSubmit="onSubmit">
                                <template v-slot:tgslot-emailEditor="{ data }">
                                    <div id="app">
                                        <div class="container m-0 p-0">
                                            <EmailEditor ref="emailEditor" style="overflow-x: auto;"  name="emailEditor" :appearance="appearance" :min-height="minHeight" 
                                            :project-id="Template_id" :locale="locale" :tools="tools" :options="options" v-on:load="editorLoaded">
                                            </EmailEditor>
                                        </div>
                                        <span  name="emailEditor" class="text-danger ml-4">{{data.error}}</span>
                                    </div>
                                </template>
                            </dynamic-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    
    </section>
    </template>
   <script>
   import {EmailEditor} from 'vue-email-editor';
   import DataService from '@/services/DataService';
   import html2canvas from 'html2canvas';
   export default {
       name: 'app',
       components: {
           EmailEditor,
       },
       data() {
           return {
               isLoading: false,
               triggerUrl:'',
               FormSchema: [{
                       layoutType: "triple",
                       Data: [{
                               astype: "TextField",
                               label: this.$t('TemplateName'),
                               name: "TemplateName",
                               value: "",
                               placeholder: "Template Name",
                               validationRules: {
                                   "required": true
                               },
                               disabled: false,
                           },
                           {
                               astype: "TextField",
                               label: this.$t('Subject'),
                               name: "Subject",
                               value: "",
                               placeholder: "Subject",
                               validationRules: {
                                   "required": true
                               },
                           },
                           {
                               astype: "SelectField",
                               label: this.$t('Category'),
                               name: "Category",
                               value: "",
                               placeholder: "Category",
                               config: {
                                   options: [],
                                   onChange: this.GetSubCategoryDDL
                               },
                               validationRules: {
                                   "required": true
                               },
                           },
                           {
                               astype: "SelectField",
                               label: this.$t('SubCategory'),
                               name: "SubCategory",
                               value: "",
                               placeholder: "Sub Category",
                               config: {
                                   options: [],
                               },
   
                           },
   
                           {
                               astype: "SelectField",
                               label: this.$t('Status'),
                               name: "Status",
                               value: "",
                               placeholder: "Status",
                               config: {
                                   options: [],
                               },
                           },
                           {
                            astype: "SelectField",
                            label: this.$t('TriggerLink'),
                            name: "TiggerData",
                            value: "",
                            placeholder: "",
                            config: {
                                options: [],
                                onChange: this.onChangeCustomTrigger
                            },
                            validationRules: { "required": false }
                        }
                       ]
                   },
                   {
                       layoutType: "single",
                       Data: [{
                           astype: "SlotField",
                           name: "emailEditor",
                           value: "",
                           placeholder: "Create Template",
                           validationRules: {"required": false},
                           config: {
                               options: [],
                               onChange: this.PaymentGatewayAdditionalDetail
                           },
   
                       }, ]
                   }
               ],
               buttons: [
                   {
                       type: "submit",
                       class: "btn btn-success",
                       text: "<i class='fa fa-save pr-2'></i>Save",
                   },
                   {
                       type: "button",
                       class: "btn btn-danger",
                       text: "<i class='fa fa-close pr-2'></i>Cancel",
                       onClick: this.BackButton,
                   },
               ],
               minHeight: '100px',
               locale: 'en',            
               tools: {
                   // disable image tool
                   image: {
                       enabled: true,
                   },
               },
               options: {},
               appearance: {
                   theme: 'dark',
                   panels: {
                       tools: {
                           dock: 'left',
                       },
                   },
               },
               CatData: [],
               TempContent: '',
               isDataLoaded: false,
               flag: false,
               SubCatData: [],
               fileData: [],
               UserId:'',
               CompanyId:'',
               ContainerName: '',
               GeneratethumnailHtmlContent:'',
               Template_id: 0,
           }
       },
       async created() {
        var vm = this;
        vm.UserId=vm.GetUserInfo.ID,
        vm.ContainerName = vm.GetUserInfo.ContainerName;
        vm.CompanyId=vm.GetUserInfo.CompanyId,                    
        await vm.GetTemplateCategoryListForCA()
        await vm.GetStatusList();
        await vm.GetTriggerFieldValue();
       },
       updated() {
        var vm = this;
        setTimeout(function(){
           try {
            vm.$refs.emailEditor.editor.registerCallback('image', function (file, done) {
                let files = file.attachments[0]
                let reader = new FileReader();
                let imageBase64;             
                reader.onloadend = function () {
                    var Prefixlength=reader.result.lastIndexOf(',');
                    imageBase64 = reader.result.substring(Prefixlength + 1, reader.result.length);
                    var n = files.name.lastIndexOf('.');
                    var extensionName = files.name.substring(n + 1, files.name.length);                   
                    var attachemnetMime = '{"ContentType":"' + files.type + '","Size":"' + files.size + '","Extension":"' + extensionName + '"}';
                    var fileObj = {
                        attachement_mime: attachemnetMime,
                        fileName: files.name,
                        base64String: imageBase64,
                        fileSize: files.size,
                        contentType: files.type,
                        Extension: extensionName,
                        subModuleCode: 'EmailTemplate',
                        moduleName: 'EmailTemplate',
                        containerName: vm.ContainerName,
                        userId:vm.UserId,
                        companyId:vm.CompanyId,
                        id:0
                    };
                    vm.fileData.push(fileObj);
                    var PostString = JSON.stringify(vm.fileData);
                DataService.UploadFile(PostString).then((response) => {  
                if(response.data.length>0) {
                    if(response.data[0].Status=='Success'){
                        done({ progress: 100, url: response.data[0].Name });
                        vm.isLoading = false;
                       }else{
                           vm.isLoading = false;
                       } 
                    }else{
                        vm.isLoading = false;
                    }
                });
                }

                if (file) {
                    reader.readAsDataURL(files);
                }                        
            });
        } catch (ex) {}
        }, 4000);
        
       },
       methods: {
                editorLoaded() {
                console.log('editorLoaded');
                // Pass the template JSON here
                // this.$refs.emailEditor.editor.loadDesign({});
            },
            // called when the editor has finished loading
            editorReady() {
                console.log('editorReady');
            },
            handleImageUpload(image) {
                console.log('Uploaded Image:', image);
            // Handle image upload logic here
            },
            saveDesign() {
                this.$refs.emailEditor.editor.saveDesign((design) => {
                console.log('saveDesign', design);
                });
            },
            exportHtml() {
                this.$refs.emailEditor.editor.exportHtml((data) => {
                console.log('exportHtml', data);
                });
            },
            async GetTriggerFieldValue() {
                var vm = this;
                var url = 'TYPE=CRM_TRIGGER&MODULE_NAME=null';
                await DataService.GetTriggerFieldValue(url).then(function (response) {
                    vm.TiggerData = response.data;
                    response.data.forEach(item=>{
                        vm.FormSchema[0].Data[5].config.options.push({
                            name: item.NAME,
                            value: item.LINK
                        })
                    })
                    vm.$refs.AddEditEmail.UpdateKeyValue();
                })
            },
            onChangeCustomTrigger(e) {
                var vm = this;
                if(e.target.value != ''){
                    vm.triggerUrl = e.target.value;
                }
            },
           async UploadFile(id) {
               var vm = this;
               vm.isLoading = true;           
               vm.fileData.forEach((item)=>{
                   item.id=id;
               })
               var PostString = JSON.stringify(vm.fileData);
               await DataService.UploadFile(PostString).then((response) => {  
                if(response.data.length>0) {
                    if(response.data[0].Status=='Success'){
                           response.data[0].Name;
                           vm.isLoading = false;
                       }else{
                           vm.isLoading = false;
                       } 
                    }else{
                        vm.isLoading = false;
                    }
            });
               vm.isLoading=false;
           },
   
           async BindTemplateDetails(Template_id) {
               var vm = this;
               if(Template_id > 0){
                   vm.isLoading = true;
                   var url = `id=${Template_id}`
                   await DataService.GetEmailTemplateDetails(url).then((response) => {
                       var data = response.data.templateContent;
                       var parsedJSON = JSON.parse(response.data.templateJson);
                       var striJSON = response.data.templateJson;
                       //vm.editorLoaded(data, parsedJSON, striJSON)
                       // vm.$refs.emailEditor.editor.loadDesign({striJSON});
                       // vm.$refs.emailEditor.editor.exportHtml((data) => {});
                       //vm.$refs.emailEditor.loadDesign(vm.TempContent);
                       vm.isDataLoaded = true;
                   })                
               }else{
                   vm.isLoading = false;
               }
               
           },
           //get ststus list ddl
           async GetStatusList() {
               var vm = this;
               vm.isLoading = true;
               await DataService.GetStatusList().then((response) => {
                   if (JSON.parse(response.data) != null) {
                       var SubCatData = [];
                       SubCatData = JSON.parse(response.data);
                       SubCatData.forEach((itm, index) => {
                           if (itm.STATUS != 'Deleted') {
                               (vm.FormSchema)[0].Data[4].config.options.push({
                                   name: itm.STATUS,
                                   value: itm.STATUS_ID
                               })
                           }
                       })
                       vm.isLoading = false;
                   }
                   vm.isLoading = false;
               });
           },
           //get sub category ddl list by module id
           async GetSubCategoryDDL(evt) {
               var vm = this;
               vm.isLoading = true;
               if (evt.target == undefined) {
                   var url = `ModuleID=${evt}`
               } else if (!evt.target.value == '') {
                   var url = `ModuleID=${evt.target.value}`
               }
               await DataService.GetTemplateSubCategoryList(url).then((response) => {
                   if (JSON.parse(response.data) != null) {
                       vm.SubCatData = [];
                       vm.SubCatData = JSON.parse(response.data);
                       (vm.FormSchema)[0].Data[3].config.options = [];
                       vm.SubCatData.forEach((itm, index) => {
                           (vm.FormSchema)[0].Data[3].config.options.push({
                               name: itm.NAME,
                               value: itm.SUB_MODULE_ID
                           })
                       })
                       vm.$refs.AddEditEmail.UpdateKeyValue();
                       vm.isLoading = false;
                   } else {
                       vm.isLoading = false;
                   }
               });
           },
           //get category ddl list
           async GetTemplateCategoryListForCA() {
               var vm = this;
               vm.isLoading = true;
               await DataService.GetTemplateCategoryListForCA().then((response) => {
                   if (JSON.parse(response.data) != null) {
                       vm.CatData = [];
                       vm.CatData = JSON.parse(response.data);
                       vm.CatData.forEach((itm, index) => {
                           (vm.FormSchema)[0].Data[2].config.options.push({
                               name: itm.template_category_name,
                               value: itm.module_id,
                           })
                       })
                       vm.CatData.forEach((itm, index) => {
                          if(itm.template_category_name=='CRM'){
                            (vm.FormSchema)[0].Data[2].value= itm.module_id;
                            vm.GetSubCategoryDDL(itm.module_id)
                          }
                       })
                       vm.$refs.AddEditEmail.UpdateKeyValue();
                       vm.isLoading = false;
                   }
                   vm.isLoading = false;
               });
           },
   
           //get additional columns show/hide on payment gateway code change
           async PaymentGatewayAdditionalDetail() {
               var vm = this;
               vm.isLoading = true;
               var url = `ModuleID=${evt.target.value}`
               await DataService.GetTemplateSubCategoryList(url).then((response) => {
                   if (JSON.parse(response.data) != null) {
                       vm.SubCatData = [];
                       vm.SubCatData = JSON.parse(response.data);
                       vm.SubCatData.forEach((itm, index) => {
                           (vm.FormSchema)[0].Data[3].config.options.push({
                               name: itm.NAME,
                               value: itm.SUB_MODULE_ID
                           })
                       })
                       vm.isLoading = false;
                   }
                   vm.isLoading = false;
               });
           },
           // editorReady(data) {
               
           // },
           // called when the editor is created
           async editorLoaded() {
               var vm = this;
               vm.isLoading = true; 
               if(vm.Template_id > 0){
                   var url = `id=${vm.Template_id}`
                   await DataService.GetEmailTemplateDetails(url).then((response) => {
                       vm.TemplateData = response.data;
                       if(response.data !=null){
                           var parsedJSON = JSON.parse(response.data.templateJson);
                           vm.$refs.emailEditor.editor.loadDesign(parsedJSON);
                           vm.FormSchema[0].Data[0].disabled = true;
                           vm.FormSchema[0].Data[0].value = vm.TemplateData.templateName;
                           vm.FormSchema[0].Data[1].value = vm.TemplateData.templateSubject;
                           vm.FormSchema[0].Data[3].value = vm.TemplateData.templateSubCategoryId;
                           vm.FormSchema[0].Data[4].value = vm.TemplateData.statusId;
                           // Pass the template JSON here
                           var foundModuleObj = vm.CatData.find(x => x.template_category_id == vm.TemplateData.templateCategoryId);
                           if (foundModuleObj) {
                               vm.FormSchema[0].Data[2].value = foundModuleObj.module_id;
                               vm.GetSubCategoryDDL(vm.FormSchema[0].Data[2].value)
                           }                                                    
                           vm.isLoading = false;
                       }                   
                   })
               } else{
                   vm.isLoading = false;
               }                     
                               
           },
           // submit the forma data and email template data
            onSubmit(e) {
               var vm = this;          
               vm.isLoading=true;
               var Template_Name = vm.FormSchema[0].Data[0].value;
               var Template_Subject = vm.FormSchema[0].Data[1].value;
               var moduleId = vm.FormSchema[0].Data[2].value;
               var Template_SubCategory_Id = vm.FormSchema[0].Data[3].value;
               var Status_Id = vm.FormSchema[0].Data[4].value;
               var foundObject = vm.CatData.find(x => x.module_id == moduleId);
               if (foundObject) {
                   var Template_Category_Id = foundObject.template_category_id;
               }
                vm.$refs.emailEditor.editor.exportHtml((data) => {
                   var json = data.design; //get the content of form design.
                   //var html = data.html+ '<a href="https://qatestnewapi.talygen.com/api/crm/CRM/_tlc?_id=@id">Click Me to Test trigger Count</a>'; //get the html of template which is created.
                   var html = data.html;
                   vm.GeneratethumnailHtmlContent=html;
                   var content = JSON.stringify(json);
                   vm.flag = false;
                   (vm.FormSchema)[1].Data[0].validationRules.required = false;
                   var strippedHtml = vm.stripHtmlTags(html);
                   if (strippedHtml.trim().length >= 2) {
                       vm.flag = true;
                       (vm.FormSchema)[1].Data[0].validationRules.required = false;
                   } else {
                       vm.flag = false;
                       (vm.FormSchema)[1].Data[0].validationRules.required = true;
                   }
                   var objData = {
                       TemplateId: 0,
                       TemplateName: Template_Name,
                       TemplateContent: html,
                       StatusId: Status_Id,
                       TemplateCategoryId: Template_Category_Id,
                       TemplateSubCategoryId: Template_SubCategory_Id,
                       TemplateSubject: Template_Subject,
                       TemplateJson: content,
                       TemplateType: false
                   }
                   var formData = new FormData();
                   var postString = {"postString": objData}
                   formData.append("postString", JSON.stringify(objData));
                   var param = `objTemp=${JSON.stringify(objData)}`;
                   if (vm.flag) {
                       DataService.AddAdvancedEmailTemplate(formData).then(async(response) => {
                            if (response.data.status == '' && response.data.msg == "TemplateNameExists") {
                                   vm.ShowAlert(vm.$t("TemplateNameExists"), "warning", vm.$t('Alert')); 
                                   vm.isLoading=false;                               
                               }
                               else {
                                   const Data=JSON.parse(response.data); 
                                   if (Data.status_id==1 && Data.status_msg == 'add') {
                                      await vm.UploadFile(Data.template_id);
                                       vm.Generatethumnail(Data.template_id);
                                       vm.ShowAlert(vm.$t("AddEmailSuccess"), "success", vm.$t('Alert'));
                                       vm.isLoading=false;
                                       setTimeout(() => {
                                            vm.$parent.emailTemplateData();
                                            vm.$emit('closepopup');
                                       }, 2000);
                                    }
                                   else if (Data.status_id==2 && Data.status_msg == 'updated') {
                                       await vm.UploadFile(vm.EditItme.template_id);
                                       vm.ShowAlert(vm.$t("UpdateEmail"), "success", vm.$t('Alert'));
                                       vm.isLoading=false;
                                       window.location.reload();
                                   }
                                   else {
                                       vm.ShowAlert(vm.$t("UnknownError"), "failure", vm.$t('Alert'));
                                       vm.isLoading=false;
                                   }
                               }               
                          
                       })
                   }
   
               });
               
           },
           Generatethumnail:function(id){
                var vm=this;
                var divElement = document.createElement('div');
                divElement.innerHTML = vm.GeneratethumnailHtmlContent;
                divElement.setAttribute('id', 'htmlContent');
                document.body.appendChild(divElement);
                html2canvas(document.querySelector('#htmlContent')).then(function(canvas) {
                    // Convert canvas to image
                    var baseString= canvas.toDataURL("image/png");
                    var base64String = baseString.split(',')[1];
                    var fileSize = baseString.length;
                    var Template_Name = vm.FormSchema[0].Data[0].value;
                    var attachemnetMime = '{"ContentType":"image/png","Size":"' + fileSize + '","Extension":"png"}';
                    var fileObj = {
                        attachement_mime: attachemnetMime,
                        fileName: Template_Name,
                        base64String: base64String,
                        fileSize: fileSize,
                        contentType: 'image/png',
                        Extension: 'png',
                        subModuleCode: 'CRM_CAMPAIGN_EMAIL_TEMPLATE',
                        moduleName: 'EmailTemplate',
                        containerName: vm.ContainerName,
                        userId:vm.UserId,
                        companyId:vm.CompanyId,
                        id:id
                    };
                    vm.fileData.push(fileObj);
                    var PostString = JSON.stringify(vm.fileData);
                    DataService.UploadFile(PostString).then((response) => {  
                    if(response.data.length>0) {
                        if(response.data[0].Status=='Success'){
                            done(response.data[0].Name);
                            vm.isLoading = false;
                        }else{
                            vm.isLoading = false;
                        } 
                        }else{
                            vm.isLoading = false;
                        }
                    });
                    divElement.classList.add('d-none');
                })
                
           },
           stripHtmlTags: function (html) {
               if (!html) return '';
               // Remove CSS styles
               var strippedHtml = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
               // Remove HTML tags and newline characters
               strippedHtml = strippedHtml.replace(/<[^>]*>?/gm, '').replace(/\n/g, '');
               return strippedHtml;
           },
           BackButton() {
              var vm=this;
              vm.$emit('closepopup')
           },
       },
   };
   </script>
   
   <style>
   .unlayer-editor {
       position: relative;
       height: 650px;
       width: 1200px;
   }
   .unlayer-editor iframe {
       position: absolute;
   }
   /* #emailEditor label .text-editor {
       float: right !important;
   } */
   </style>