<template>
    <section>
        <div class="w-100 float-left">

            <div class="w-100 float-left">
                <div class="heading-border">
                    <h5 class="h5">
                        <span>Start Your Campaign With:</span>
                    </h5>
                </div>
            </div>

            <div class="listing col-12 p-0 mt-3">
                <div class="row custom-box-width default_templates">

                    <a class="col-sm-12 col-md-6 col-lg-4 col-xl-3 default-tag-vissible" href="javascript:;"
                        v-on:click="loginByFacebook()">
                        <div class="box positionrelative float-left w-100">
                            <div class="img-ico text-center p-2 ticket-attachments_small">
                                <em class="fa fa-file-text-o font-pic-size-28 mr-3"></em> <span
                                    class="font-weight-medium">Facebook</span>
                            </div>
                        </div>
                    </a>
                    <a class="col-sm-12 col-md-6 col-lg-4 col-xl-3 default-tag-vissible" href="javascript:;"
                        v-on:click="loginByInstagram()">
                        <div class="box positionrelative float-left w-100">
                            <div class="img-ico text-center p-2 ticket-attachments_small">
                                <em class="fa fa-file-text-o font-pic-size-28 mr-3"></em> <span
                                    class="font-weight-medium">Instagram</span>
                            </div>
                        </div>
                    </a>

                    <a class="col-sm-12 col-md-6 col-lg-4 col-xl-3 default-tag-vissible" href="javascript:;">
                        <div class="box positionrelative float-left w-100">
                            <div class="img-ico text-center p-2 ticket-attachments_small">
                                <em class="fa fa-file-text-o font-pic-size-28 mr-3"></em> <span
                                    class="font-weight-medium">Google</span>
                            </div>
                        </div>
                    </a>

                    <a class="col-sm-12 col-md-6 col-lg-4 col-xl-3 default-tag-vissible" href="javascript:;">
                        <div class="box positionrelative float-left w-100">
                            <div class="img-ico text-center p-2 ticket-attachments_small">
                                <em class="fa fa-file-text-o font-pic-size-28 mr-3"></em> <span
                                    class="font-weight-medium">Bing</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <template>
                <div class="row">
                    <div class="col-lg-12 mt-2">
                        <div class="heading-border mb-2">
                            <h5 class="h5">
                                <span>Connected Account & Pages</span>
                                <small class="float-right mt-2">
                                    <a id="addNewPost" v-on:click="AddNewPost()" title="Add New Post">
                                        <em class="fa fa-plus pr-2"></em> Add New Post
                                    </a>
                                </small>
                            </h5>
                        </div>
                    </div>
                </div>
                <div>
                    <tg-list :IsShowAction="true" ModuleName="CRM" SubModuleCode="CRM_CAMPAIGN"
                        :RenderRowActionMethod="renderActions" v-on:ActionButtonClick="actionButtonClick"
                        IdentityColumn="REF_PAGE_ID" :HeaderText="$t('Campaign')" :listType="['List']"
                        :ListData="AllPages" :HeaderData="Headers" :TotalRecords="TotalRecords"
                        :ListDataCallBackFunction="GetPageList" :showCheckBox="false" :SortExp="SortExp"
                        :SortBy="SortBy" :NorecordfoundText="$t('NoRecordfound')"
                        v-on:LeftsearchButtonClick="leftCommonSearch" v-on:PagerButtonClick="pagerMethod"
                        v-on:SortdataButtonClick="sortdata">
                        <template v-slot:slotdata="{ data }">
                            <template v-if="data.column.COLUMN_NAME == 'CAMPAIGN_TYPE_NAME'">
                                <a href="javascript:;" v-on:click="ShowPageUploadedPost(data.row.REF_PAGE_ID)"
                                    :title="data.row[data.column.COLUMN_NAME]">
                                    {{ data.row[data.column.COLUMN_NAME] }}
                                </a>
                            </template>
                        </template>
                    </tg-list>
                </div>
            </template>
        </div>
        <ShowUploadedPost v-if="ShowUploadedPost" :PageId="PageId"></ShowUploadedPost>
    </section>
</template>
<script>
import DataService from '../../../services/DataService';
import ShowUploadedPost from '../../../views/CRM/Campaign/SocialMediaCampaign/PostList.vue';
export default {
    components: { ShowUploadedPost },
    data() {
        return {
            FacebookAppId: "249422784832754", //Uncomment For QA Build
            FacebookConfigurationId: "438344051971795", //Uncomment For QA Build
            FacebookAuthCallBackUrl: "https://qatestnewui.talygen.com/CRM/SMCampaignListing", //Uncomment For QA Build
            // FacebookAppId: "948421660018778", //Uncomment For Local
            // FacebookConfigurationId: "1475731086341101", //Uncomment For Local
            // FacebookAuthCallBackUrl: "https://c84d-202-164-57-195.ngrok-free.app/CRM/SMCampaignListing", //Uncomment For Local
            InstagramAppId:'1077814836780901',
            InstagramSecretId:'51c922eb195662ed65fa212864cb6e55',
            AccountPageData: [],
            TotalRecords: 0,
            PageNumber: 1,
            PageSize: 10,
            PageId:0,
            ShowUploadedPost: false,
            SortBy: '',
            SortExp: '',
            AllPages: [],
            CampaignType: 'FACEBOOK',
            LoginStatus: '',
            status: '',
            Headers: [
                {
                    "COLUMN_NAME": "CAMPAIGN_TYPE_NAME",
                    "DATA_TYPE": "text",
                    "SORTABLE": true,
                    "VISIBLE": true,
                    "DISPLAY_NAME": "Account",
                    "DISPLAY_ORDER": 1
                },
                {
                    "COLUMN_NAME": "REF_PAGE_TITLE",
                    "DATA_TYPE": "text",
                    "SORTABLE": true,
                    "VISIBLE": true,
                    "DISPLAY_NAME": "Page Name",
                    "DISPLAY_ORDER": 2
                }
            ],
            listActions:
                [
                    {
                        title: 'Delete',
                        href: 'javascript:void(0)',
                        icon: 'fa fa-trash',
                        callbackfunction: 'DeleteCampaign',
                        additionalSpan: ''
                    }
                ]
        }
    },
    async created() {
        var vm = this;
        await vm.GetPageList();
        vm.getLoginData();
    },
    methods: {
        loginByFacebook() {
            var vm = this;
             vm.$emit('GetListing');

            // window.location.href = "https://graph.facebook.com/oauth/authorize?client_id=" + 948421660018778 +
            //     "&config_id=" + 438344051971795 +
            //     "&redirect_uri=" + 'https://c84d-202-164-57-195.ngrok-free.app/CRM/SMCampaignListing' +
            //     "&scope=manage_pages,publish_pages";
        },
        getLoginData() {
            var vm = this;
            const data = localStorage.getItem('facebook_login_data');
            if (data) {
                vm.LoginStatus = 'Facebook';
            } else {
                vm.LoginStatus = '';
            }
            return null;
        },
        async GetPageList() {
            var vm = this;
            var url = `sortBy=${vm.SortBy}&sortExp=${vm.SortExp}&pageSize=${vm.PageSize}&pageNumber=${vm.PageNumber}&CompaignType=${vm.CampaignType}&status=${vm.status}`;
            DataService.GetSocialMediaPagesList(url).then(function (response) {
                vm.Headers.forEach((column) => {
                    column.settings = null;
                    if (column.COLUMN_NAME == "CAMPAIGN_TYPE_NAME") {
                        column.settings = {
                            isInSlot: true,
                        };
                    }
                });
                vm.AllPages = response.data;
            })
        },
        ShowPageUploadedPost: function (PageId) {
            var vm=this;
            if (PageId == undefined)
            PageId = 0;
            vm.PageId = PageId;
            this.ShowUploadedPost = !this.ShowUploadedPost;
        },
        async loginByInstagram() {
            var vm = this;
            debugger
            //window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${vm.InstagramAppId}&redirect_uri=${encodeURIComponent(vm.FacebookAuthCallBackUrl)}&state=instagram&scope=user_profile,user_media&response_type=code`;
        },
        renderActions: function (row) {
            var self = this;
            let rtnActionArray = [];
            self.listActions.forEach(actionItem => {
                let action = { ...actionItem };
                switch (action.title) {
                    case 'Edit':
                        action.isVisible = true;
                        break;
                    case 'Delete':
                        action.isVisible = true;
                        break;
                    default: {
                        action.isVisible = true;
                    }
                }
                rtnActionArray.push(action);
            });
            return rtnActionArray;
        },
        actionButtonClick(callbackfunction, item, index, event, isChild) {
            switch (callbackfunction) {
                case 'openQuickViewpopup':
                    //this.DeleteIncidentRow(item.ID);
                    break;
                case 'DeleteCampaign':
                    this.DeleteSocialMediaPages(item.ID);
                    break;
            }
        },
        leftCommonSearch() {

        },
        AddNewPost: function () {
            this.$router.push({ name: "AddSocialMediaPost", params: { id: 0 } });
        },
        DeleteSocialMediaPages(Page_id) {
            var vm = this;
            var postJSON = {
                ids: Page_id,
                refCode: 'CRM_SOCIAL_PAGES'
            };
            var objectPostString = JSON.stringify(postJSON);
            DataService.CommonDeleteData(objectPostString).then(function (response) {
                var box = "";
                if (response.status == 200) {
                    box = vm.ShowAlert(vm.$t('DeletedSuccessfully', [vm.$t('Page')]), "success", true, vm.$t("Alert"));
                    vm.GetPageList();
                }
                else {
                    box = vm.ShowAlert(vm.$t('DeletedError', [vm.$t('Page')]), "failure", true, vm.$t("Alert"));
                }
                setTimeout(function () {
                    box.modal('hide');
                }, 2000);
            })
        },
        pagerMethod: function (value) {
            this.PageSize = value.PageSize;
            this.PageNumber = value.PageNumber;
            this.FetchData();
        },
        sortdata: function (s) {
            var SortDir = "sort tb_headerSortDown";
            this.SortBy = s;
            if (s === this.SortBy) {
                if (this.SortExp == "") {
                    this.SortExp = "ASC";
                    SortDir = "sort tb_headerSortDown";
                }
                else if (this.SortExp == "ASC") {
                    this.SortExp = "DESC";
                    SortDir = "sort tb_headerSortUp";
                }
                else {
                    this.SortExp = "ASC";
                    SortDir = "sort tb_headerSortDown";
                }
            }
            this.FetchData();
            $("#" + s + '  span:first-child').attr("class", SortDir);
        }
    }
}
</script>