<script>
    export default {
        props: {
            fieldInfo: {
                type: Object,
                required: true
                }              
        },
        data: function () {
            return {       
                 checkOptions: [],         
            }
        },
        methods: { 
          AssignToggleValues:function(event, item, array){
            this.$emit('AssignToggleValues',event,item,array);
          },
          showDependentItem:function(item, value){           
            this.$emit('showDependentItem',item,value);
          },
            isChekedToggle(){
              if(this.fieldInfo.name=='gender'){
                if(this.fieldInfo.value=='M' || this.fieldInfo.value=='Male'){
                  return true;
                }
              }else if(this.fieldInfo.name=='gin_inward_type'){
                if(this.fieldInfo.value=='Asset'){
                  return true;
                }
              }
              else if(this.fieldInfo.name=='is_existing_client'){
                  if(this.fieldInfo.value=='1'){
                    return true;
                  }
              }
            }        
        },
        
    }
</script>
<template>
 <div class="form-group" v-if="fieldInfo.dt_code=='radio' && MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code).length==2">
  <label class="switch">
    <Field :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" :rules="{'required':fieldInfo.is_required}" v-slot="{errors}" slim>
      <input  class="form-check-input rdoticketfor custom-control-input dynamic" type="checkbox" v-bind:name="fieldInfo.name" 
      v-bind:value="fieldInfo.value" v-on:change="AssignToggleValues($event,fieldInfo,MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code))"
       v-bind:checked="isChekedToggle()" v-bind:disabled="fieldInfo.is_Disabled" tabindex="5">       
      </Field>
      <span class="slider round">
          <span class="slider-yes">{{MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code)[0].name}}</span>
          <span class="slider-no">{{MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code)[1].name}}</span>
      </span>
  </label>
</div>

<div class="form-control pl-0 border-0 bg-transparent" v-else-if="fieldInfo.dt_code=='radio' && MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code).length>2">
    <div class="form-check form-check-inline" v-for="option, in MakeArray(fieldInfo.picklist_options,fieldInfo.dt_code)">                                                  
        <div class="custom-control custom-radio custom-control-inline" v-bind:key="option.name">
          <Field :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" :rules="{'required':fieldInfo.is_required}"  v-slot="{errors}">
            <input  v-bind:id="'rdo_'+ fieldInfo.custom_field_id+ '_' + option.value" class="dynamic form-check-input custom-control-input" 
            v-model="fieldInfo.value" v-bind:name="fieldInfo.name" v-on:change="showDependentItem(fieldInfo,option.value)"
              v-bind:data-resource="option.name" v-bind:value="option.value" type="radio" v-bind:checked="option.value == fieldInfo.value" 
              v-bind:disabled="fieldInfo.is_Disabled" />
              <label class="custom-control-label universalradio-custom-control-label dynamic" 
            v-bind:for="'rdo_'+ fieldInfo.custom_field_id+ '_' + option.value">{{option.name}}</label>
              <span   :name="fieldInfo.name" class="invalid-feedback">{{errors[0]}}</span>
            </Field>
            
        </div>
    </div>
</div>  
</template>