<script>
import LeadDataService from "../../../services/DataService";
import $ from "jquery";
export default {
  props:{
    callFrom:null
  },
  data() {
    return {
      industryname: "",
      industrydesc: "",
    };
  },

  methods: {
    SaveIndustry: function ( valid,errors) {
      var vm = this;
      // vm.$refs.industryForm.setFieldTouched('Name', true);
      this.$refs.industryForm.validate().then((result) => {
        if (result) {
           var jsonobj = {
              industryname: vm.industryname,
              industrydesc: vm.industrydesc,
            };
            var objectPostString = JSON.stringify(jsonobj)
            LeadDataService.SaveIndustryType(objectPostString).then(function (response) {           
            if (response.data.Status == "SUCCESS") {            
              vm.ShowAlert(vm.$t("Industry has been saved successfully."));           
              var param = "id=&moduleName=CRM&type=INDUSRTY_TYPE";               
              LeadDataService.getDDLData(param).then(function (response1) {
                var json = [];
                if (response1.data != null) {
                  json = response1.data.DATA;
                }
                 vm.$emit('updatedata',vm.callFrom=='lead'?'industry_type_id':'industry_name', json);
                   vm.$emit("close");
                  
              });
            } else if (response1.data.Status == "EXIST") {
              vm.ShowAlert(vm.$t("Industry name already exist."),"failure",true,vm.$t('Alert'));
            }
             
          });
        }
      });
    },
  },
};
</script>
<template>
  <transition name="modal">
    <div class="my-popups">
      <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered" style="max-width:900px !important">
          <div class="modal-content">
            <div class="modal-header theme-primary partition-full">
              <h4 class="modal-title">{{$t('Add')}} {{$t('Industry')}}</h4>
              <button type="button" class="close" v-on:click="$emit('close')">
                <em class="fa fa-times" aria-hidden="true"></em>
              </button>
            </div>

            <div class="modal-body">
              <Form ref="industryForm" v-slot="{ valid,errors}">
                <div class="col-md-12 float-left px-0">
                  <div class="row" style="max-height: 450px; overflow-y: auto;">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          {{$t('Industry')}}:
                          <span class="text-danger">*</span>
                        </label>
                        <Field
                          name="Name"
                          :rules="{'required':true}"
                          label="Industry"
                          v-slot="{errors,touched,invalid}"
                        >
                          <input
                            name="Name"
                            id="Name"
                            type="text"
                            v-bind:class="{ 'form-control': true, 'is-invalid': touched && invalid }"
                            v-model="industryname"
                          />
                          <span
                             
                            name="Name"
                            class="invalid-feedback d-block"
                          >{{errors[0]}}</span>
                        </Field>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>{{$t('Description')}}:</label>
                        <Field
                          name="Description"                          
                          label="Description"  
                          v-slot="errors"                        
                        >
                          <textarea
                            style="max-height:300px!important;min-height:100px!important"
                            maxlength="1000"
                            id="description"
                            name="Description"
                            v-bind:class="{'form-control' : true}"
                            v-model="industrydesc"
                          />
                          <span                          
                            name="Description"
                            class="invalid-feedback"
                          >{{errors[0]}}</span>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div class="modal-footer">
              <div class="col-md-12 p-0">
                <div class="row flex-row-reverse">
                  <div class="col-lg-6 text-right">
                    <a
                      href="javascript:;"
                      v-on:click="SaveIndustry( valid,errors);"
                      title="Save"
                      class="btn btn-success save-btn mr-1"
                    >
                      <em class="fa fa-save pr-2"></em>
                      {{$t('Save')}}
                    </a>
                    <a
                      href="javascript:;"
                      v-on:click="$emit('close')"
                      class="btn btn-danger  delete-btn"
                    >
                      <em class="fa fa-times"></em>
                      {{$t('Cancel')}}
                    </a>
                  </div>
                  <div class="col-lg-6">
                    <div class="py-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>