<template>
    <div class="modal-open my-popups">
       <div class="modal d-block">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header theme-primary partition-full">
                        <h4 class="modal-title">Add {{PopupName}}</h4>
                        <button type="button" class="close" v-on:click="CloseAddChannelTag($event)">
                             <em class="fa fa-times" aria-hidden="true"></em>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span class="user-guide">
                            <a class="ancuserguide" data-toggle="collapse" href="#collapseReply1" role="button" aria-expanded="false" aria-controls="collapseExample">User Guide</a>
                            <div id="collapseReply1" class="divancuserguide collapse">
                                    <div id="scrollbarreplyuserguide" class="custom-scrollbar-js">
                                        <div class="con" >{{userGuide}}</div>
                                    </div>
                            </div>
                        </span>
                        <div class="heading-border mb-3">
	                       <h5 class="h5">
		                      <span>{{PopupName}}</span>
		                      <div class="float-right ml-4">
		                      </div>
	                       </h5>
                        </div>
                        <Form ref="form" v-slot="{ valid,errors}"  v-if="PopupName == 'Channel'">
                            <div class="d-flex flex-wrap">
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Channel Name<span class="text-danger">*</span></label>
                                                <Field name="ChannelName" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                                    <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="ChannelName" type="text"  />                                           
                                                    <span v-if="errors[0]" name="ChannelName" class="invalid-feedback">{{errors[0]}}</span>                                
                                                </Field>                            
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Status:<span class="text-danger">*</span></label>                                      
                                                <Field :rules="{ 'required': true }" class="w-100" name="StatusId" v-slot="{ errors }">      
                                                    <select v-model="StatusId" v-bind:class="{ 'form-control w-100': true }">                                               
                                                        <option value="1001">Active</option>
                                                        <option value="1002">InActive</option>
                                                    </select>
                                                    <span v-if="errors[0]" name="StatusId" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                </Field>  
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Shift Description:</label>
                                                <Field :rules="{'required':false}" name="ChannelDescription">
                                                    <textarea  class="form-control"
                                                            v-model="ChannelDescription" style="resize:none" cols="10" rows="5">
                                                    </textarea>
                                                </Field>                           
                                            </div>
                                        </div>                            
                                    </div>
                                </div>
                            </div>                           
                        </Form>
                        <Form ref="form" v-slot="{valid, errors}" v-if="PopupName == 'Priority'">
                            <div class="d-flex flex-wrap">
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Priority Name<span class="text-danger">*</span></label>
                                                <Field name="PriorityName" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                                    <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="PriorityName" type="text"  />                                           
                                                    <span v-if="errors[0]" name="PriorityName" class="invalid-feedback">{{errors[0]}}</span>                                
                                                </Field>                            
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Status:<span class="text-danger">*</span></label>                                      
                                                <Field :rules="{ 'required': true }" class="w-100" name="StatusId" v-slot="{ errors }">      
                                                    <select v-model="StatusId" v-bind:class="{ 'form-control w-100': true }">                                               
                                                        <option value="1001">Active</option>
                                                        <option value="1002">InActive</option>
                                                    </select>
                                                    <span v-if="errors[0]" name="StatusId" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                </Field>  
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Display Order:<span class="text-danger">*</span></label>                                      
                                                <Field :rules="{ 'required': true }" class="w-100" name="DisplayOrder" v-slot="{ errors }">      
                                                    <select v-model="DisplayOrder" v-bind:class="{ 'form-control w-100': true }">                                               
                                                        <option v-for="(item, itemIndex) in PriorityDisplayOrder" :key="itemIndex" :value="item.value">{{ item.name }}</option>
                                                    </select>
                                                    <span v-if="errors[0]" name="DisplayOrder" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                </Field>  
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Priority Description:</label>
                                                <Field :rules="{'required':false}" name="PriorityDescription">
                                                    <textarea  class="form-control"
                                                            v-model="PriorityDescription" style="resize:none" cols="10" rows="5">
                                                    </textarea>
                                                </Field>                           
                                            </div>
                                        </div>                            
                                    </div>
                                </div>
                            </div> 
                        </Form>
                        <Form ref="form" v-slot="{valid, errors}" v-if="PopupName == 'Tag'">
                            <div class="d-flex flex-wrap">
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Tag Name<span class="text-danger">*</span></label>
                                                <Field name="TagName" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                                    <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="TagName" type="text"  />                                           
                                                    <span v-if="errors[0]" name="TagName" class="invalid-feedback">{{errors[0]}}</span>                                
                                                </Field>                            
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 float-left">
                                            <div class="form-group">
                                                <label>Status:<span class="text-danger">*</span></label>                                      
                                                <Field :rules="{ 'required': true }" class="w-100" name="StatusId" v-slot="{ errors }">      
                                                    <select v-model="StatusId" v-bind:class="{ 'form-control w-100': true }">                                               
                                                        <option value="1001">Active</option>
                                                        <option value="1002">InActive</option>
                                                    </select>
                                                    <span v-if="errors[0]" name="StatusId" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                                </Field>  
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Tag Description:</label>
                                                <Field :rules="{'required':false}" name="TagDescription">
                                                    <textarea  class="form-control"
                                                            v-model="TagDescription" style="resize:none" cols="10" rows="5">
                                                    </textarea>
                                                </Field>                           
                                            </div>
                                        </div>                            
                                    </div>
                                </div>
                            </div>   
                        </Form>
                        <div class="col-md-12 p-0">
                           <div class="row flex-row-reverse">
                               <div class="col-lg-6 text-right">
                                   <a href="javascript:;" v-on:click="SavedddChannelTag(valid,errors);" title="Save" class="btn btn-success save-btn mr-1">
                                       <em class="fa fa-save pr-2"></em>{{$t('Save')}}
                                   </a>
                                   <a href="javascript:;" v-on:click="CloseAddChannelTag($event)" class="btn btn-danger  delete-btn">
                                       <em class="fa fa-times"></em> {{$t('Cancel')}}
                                   </a>
                               </div>
                               <div class="col-lg-6">
                                       <div class="py-2"><small class="text-danger">{{$t('MandatoryString')}}</small></div>
                               </div>
                           </div>
                       </div> 
                    </div>                     
                </div>
            </div>
        </div>
    </div>        
</template>
<script>
import DataService from '../../../services/DataService';
export default{    
    data(){
        return{
            PopupName: '',
            StatusId: 1001,
            ChannelDescription: '',
            PriorityDescription: '',
            TagDescription: '',
            ChannelName: '',
            TagName: '',
            PriorityName: '',
            DisplayOrder: '',
            userGuide: '',
            PriorityDisplayOrder: [],
            Items: '',
        }
    },
    created:function(){        
        var vm =this;
        vm.Items = vm.$parent.modalChannelPhaseTag;
        if (vm.$parent.modalChannelPhaseTag.name == 'channel_id') {
            vm.PopupName = 'Channel';
            vm.userGuide = $t("Channel_UG");
        }if (vm.$parent.modalChannelPhaseTag.name == 'priority_id') {
            vm.PopupName = 'Priority';
            vm.GetPriorityDisplayOrder();
            vm.userGuide = $t("Priority_UG");
        }if (vm.$parent.modalChannelPhaseTag.name == 'tag_id') {
            vm.PopupName = 'Tag';
            vm.userGuide = vm.$t("Tag_UG");
        }         
    },
    methods:{
        GetPriorityDisplayOrder:function(){
            var vm = this;
            var url = `id=&moduleName=PROJECT&type=DISPLAY_ORDER_PRIORITY`;
            DataService.GetDDLData(url).then(response=>{                
                if(response.data !=null){
                    vm.PriorityDisplayOrder=response.data.DATA;
                }
            })

        },
        CloseAddChannelTag:function(item){
            this.$emit('CloseAddChannelTag',item);
        },
        SavedddChannelTag:function(){                                    
            var vm=this;
            if(vm.PopupName=='Channel'){
                    var params=`name=${vm.ChannelName}&type=Channel&id=0&refId=`
                    vm.$refs.form.validate().then(result=>{
                        if(result){
                            DataService.CheckDuplicate(params).then(response=>{                                
                                if(response.data=="0"){
                                    vm.SaveChannel();
                                }else{
                                    vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('Channel')]), "failure", true,  vm.$t("Alert"));
                                }
                            });
                        }
                });
            }
            if(vm.PopupName=='Priority'){
                var params=`name=${vm.PriorityName}&type=PRIORITY&id=0&refId=`;
                vm.$refs.form.validate().then(result=>{
                    if(result){
                        DataService.CheckDuplicate(params).then(response=>{
                                if(response.data=="0"){
                                    vm.SavePriority();
                                }else{
                                    vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('Priority')]), "failure", true,  vm.$t("Alert"));
                                }
                            });
                        }
                });
            }
            if(vm.PopupName=='Tag'){                
                //var params=`name=${vm.TagName}&type=TAG&id=0&refId=`;
                var params=`name=${vm.TagName}&type=TAG&id=0&refId=`;
                vm.$refs.form.validate().then(result=>{                    
                    if(result){
                        DataService.CheckDuplicate(params).then(response=>{                                
                                if(response.data=="0"){
                                    vm.SaveTag();
                                }else{
                                    vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('TAG')]), "failure", true,  vm.$t("Alert"));
                                }
                            });
                        }
                });
            }
        },
        SaveChannel:function(){
            var vm=this;
            var params=`ChannelName=${this.ChannelName}&ChannelDescription=${this.ChannelDescription}&StatusId=${this.StatusId}`;
            DataService.SaveChannel(params).then(response=>{                
                if(response.data=='1'){
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Channel')]), "success", true,  vm.$t("Alert")); 
                }
            });
        },
        SavePriority:function(){
            var vm=this;
            var params=`PriorityName=${this.PriorityName}&PriorityDescription=${this.PriorityDescription}&StatusId=${this.StatusId}&DisplayOrder=${this.DisplayOrder}`;
            DataService.SavePriority(params).then(response=>{                
                if(response.data=='1'){
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Priority')]), "success", true,  vm.$t("Alert"));
                }
            });

        },
        SaveTag:function(){
            var vm=this;
            let obj={
                TagId:0,
                tagName:vm.TagName,
                tagDesc:vm.TagDescription,
                tagStatusId:vm.StatusId,
                moduleName:'Project'
            }
            var url=JSON.stringify(obj);
            DataService.SaveTag(url).then(response=>{                                
                if(response.data[0].Status=='Success'){
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('TAG')]), "success", true,  vm.$t("Alert"));
                }
            });
        }
    }
}
</script>