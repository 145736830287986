<template>
    <div class="tab-content configdetail" style="min-height:auto !important;">
        <div class="active config_list" id="ulKeysContainer">
                <div class="row" v-if="Configuration != null">
                    <template v-for="(key, keyIndex) in Configuration">
                        <div v-if='ExtraFeature != "Yes" && key.CORE_CONFIG_KEY != "IsBreakPaidByDefault"'>
                            <div :class="{ 'col-12': keyIndex == 1, 'col-lg-6': keyIndex != 1 }">
                                <div class="form-group projectConfig">
                                    <label>
                                        <div v-if='key.CORE_CONFIG_KEY != "Shift"'>
                                            <span>{{ $t(key.CORE_CONFIG_KEY) }}</span>
                                            <!-- <span v-if='(key.CORE_CONFIG_DATA_TYPE ?? "").indexOf("notrequired") == -1'>
                                                {{$t('MandatoryString')}}
                                            </span> -->
                                            <a href="javascript:;" data-toggle="popoveruserguide"
                                                :data-content="$t(key.CORE_CONFIG_KEY + '_desc') ? $t(key.CORE_CONFIG_KEY + '_desc') : key.CORE_CONFIG_DESC">
                                                <i class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></i>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <span v-if="AllShift.length > 0">
                                                <span>{{ $t(key.CORE_CONFIG_KEY) }}</span>
                                                <a href="javascript:;" data-toggle="popoveruserguide" :data-content="(key.CORE_CONFIG_DESC)">
                                                    <i class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></i>
                                                </a>
                                            </span>
                                        </div>
                                    </label>
                                    <div class="pl-0 bg-transparent border-0" v-switch="key.CORE_CONFIG_TYPE">
                                        <div v-case="'text'">
                                            <input :type='(key.CORE_CONFIG_DATA_TYPE == "Password") ? "password" : "text"'
                                                :maxlength='(key.CORE_CONFIG_DATA_TYPE == "Int" || key.CORE_CONFIG_DATA_TYPE == "Decimal") ? 8 : 10000'
                                                :name="key.CORE_CONFIG_KEY"
                                                :class="'form-control' + ' ' + key.CORE_CONFIG_DATA_TYPE"
                                                :value="key.CORE_CONFIG_VALUE" 
                                                :keyid="key.CORE_CONFIG_ID"/>
                                            <span data-valmsg-replace="true" :data-valmsg-for="key.CORE_CONFIG_KEY"></span>
                                        </div>
                                        <div v-case="'dropdown'">
                                            <select v-if="key.CORE_CONFIG_KEY == 'shift'" id="Shift" name="Shift"
                                                class="form-control" :keyid="key.CORE_CONFIG_ID">
                                                <option value="">{{ $t('Select') }}</option>
                                                <option v-for="shift in AllShift" :value="shift.shift_id">{{ shift.shift_name }}
                                                </option>
                                            </select>
                                            <select v-else-if="key.CORE_CONFIG_KEY == 'TimeZone'" id="TimeZone" name="TimeZone"
                                                class="form-control" :keyid="key.CORE_CONFIG_ID">
                                                <option value="">{{ $t('Select') }}</option>
                                                <option v-for="zone in timezone" :value="zone.shift_id">{{ zone.Value }}</option>
                                            </select>
                                            <select v-else-if="key.CORE_CONFIG_KEY == 'FSMRegionAccess'" name="FSMRegionAccess"
                                                class="form-control" :keyid="key.CORE_CONFIG_ID">
                                                <option value="">{{ $t('Select') }}</option>
                                                <option v-for="region in regionAcessList" :value="region.Code">{{ region.Value }}
                                                </option>
                                            </select>
                                            <select v-else-if="key.CORE_CONFIG_KEY == 'ExchangeTimeZone'" :keyid="key.CORE_CONFIG_ID"
                                                v-for="tzinfo in TimeZoneInfo" name="ExchangeTimeZone" id="ExchangeTimeZone">
                                                <option value="">{{ $t('Select') }}</option>
                                                <option v-if="tzinfo.Id == key.CORE_CONFIG_VALUE" :value="tzinfo.Id"
                                                    selected="selected"> {{ tzinfo.DisplayName }}</option>
                                                <option :value="tzinfo.Id"> {{ tzinfo.DisplayName }}</option>
                                            </select>

                                            <select v-else-if="key.CORE_CONFIG_KEY == 'ExchangeTimeZone'" :keyid="key.CORE_CONFIG_ID"
                                                v-for="tzinfo in TimeZoneInfo" name="ExchangeTimeZone" id="ExchangeTimeZone">
                                                <option value="">{{ $t('Select') }}</option>
                                                <option v-if="tzinfo.Id == key.CORE_CONFIG_VALUE" :value="tzinfo.Id"
                                                    selected="selected"> {{ tzinfo.DisplayName }}</option>
                                                <option :value="tzinfo.Id"> {{ tzinfo.DisplayName }}</option>
                                            </select>
                                            <select v-else class="form-control" :name="key.CORE_CONFIG_KEY" :keyid="key.CORE_CONFIG_ID"
                                                :data-selectedval="key.CORE_CONFIG_VALUE"></select>
                                            <select class="form-control" :name="key.CORE_CONFIG_KEY" :keyid="key.CORE_CONFIG_ID"
                                                :data-selectedval="key.CORE_CONFIG_VALUE">
                                                <option v-for="configOption in key.CORE_CONFIG_OPTIONS.split('^')"
                                                    :selected="configOption.trim() == key.CORE_CONFIG_VALUE.trim()">{{
                                                        configOption }}</option>
                                            </select>
                                            <span data-valmsg-replace="true" :data-valmsg-for="key.CORE_CONFIG_KEY"></span>
                                        </div>
                                        <div v-case="'checkbox'">
                                            <div class="rad_value" :type="key.CORE_CONFIG_TYPE" :keyid="key.CORE_CONFIG_ID">
                                                <label v-for="chkOptions in key.CORE_CONFIG_OPTIONS.split('^')"
                                                    class="pconfig form-check form-check-inline">
                                                    <input type="checkbox" :name="key.CORE_CONFIG_KEY"
                                                        :checked="key.CORE_CONFIG_VALUE.indexOf(chkOptions) > -1"
                                                        :value="chkOptions" />{{ chkOptions }}
                                                </label>
                                            </div>
                                        </div>
                                        <div v-case="'radiobutton'">
                                            <div class="rad_value" :type="key.CORE_CONFIG_TYPE" :keyid="key.CORE_CONFIG_ID">
                                              <div v-for="item in key.CORE_CONFIG_OPTIONS.split('^')">                                              
                                             <div v-if="item.trim() == key.CORE_CONFIG_VALUE.trim()">
                                                <label class="pconfig form-check form-check-inline disabled" v-if="key.CORE_CONFIG_KEY.toLowerCase() == 'viewalllocationproduct' && key.USER_TYPE_ID == '1'">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY"
                                                            :id="'rdo_4_'+ key.CORE_CONFIG_ID" class="custom-control-input dynamic">
                                                        <label class="custom-control-label"
                                                            :for="'rdo_4_' +key.CORE_CONFIG_ID">No</label>
                                                    </div>
                                                </label>
                                                <label v-else class="pconfig form-check form-check-inline">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY" :value="item"
                                                            checked="checked" :id="'rdo_3_'+key.CORE_CONFIG_ID"
                                                            class="custom-control-input dynamic">
                                                        <label class="custom-control-label" :for="'rdo_3_'+key.CORE_CONFIG_ID">
                                                           {{ GetResouceKeyValue(item) }}
                                                        </label>
                                                    </div>
                                                </label>
                                            </div>
                                            <div v-else>
                                                 <label class="pconfig form-check form-check-inline disabled" v-if="key.CORE_CONFIG_KEY.toLowerCase() == 'viewalllocationproduct' && key.USER_TYPE_ID == '1'">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY"
                                                            :id="'rdo_3_'+key.CORE_CONFIG_ID" class="custom-control-input dynamic"
                                                            checked="checked" value="yes">
                                                        <label class="custom-control-label"
                                                            :for="'rdo_4_'+key.CORE_CONFIG_ID">Yes </label>
                                                    </div>
                                                </label>
                                                <label v-else class="pconfig form-check form-check-inline">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY" :value="item"
                                                            :id="'rdo_4_'+key.CORE_CONFIG_ID" class="custom-control-input dynamic">
                                                        <label class="custom-control-label" :for="'rdo_4_'+key.CORE_CONFIG_ID">
                                                            {{ GetResouceKeyValue(item) }}
                                                        </label>
                                                    </div>
                                                </label>
                                            </div>
                                                <input type="hidden" name="hdnishod" value="" id="hdnishod" v-if="key.CORE_CONFIG_KEY.toLowerCase() == 'ishod'"/>
                                                <input type="hidden" name="hdncanresolvetickets" value=""
                                                    id="hdncanresolvetickets" v-else-if="key.CORE_CONFIG_KEY.toLowerCase() == 'canresolvetickets'"/>
                                                <input type="hidden" name="hdnviewalldepartmentticket" value=""
                                                    id="hdnviewalldepartmentticket" v-else-if="key.CORE_CONFIG_KEY.toLowerCase() == 'viewalldepartmentticket'"/>                                                
                                            </div>
                                            </div>
                                        </div>
                                        <div v-case="'textarea'">
                                            <textarea :name="key.CORE_CONFIG_KEY" class="textfield" :keyid="key.CORE_CONFIG_ID"
                                                cols="" rows="5">{{ key.CORE_CONFIG_VALUE }}</textarea>
                                        </div>
                                        <div v-case="'image'"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="{ 'col-12': keyIndex == 1, 'col-lg-6': keyIndex != 1 }" v-else="ExtraFeature == 'Yes'">
                            <div class="form-group projectConfig">
                                <label>{{ $t(key.CORE_CONFIG_KEY) }}</label>
                                <!-- <span v-if='(key.CORE_CONFIG_DATA_TYPE ?? "").indexOf("notrequired") == -1'> {{$t('MandatoryString')}} </span> -->
                                <a href="javascript:;" data-toggle="popoveruserguide" :data-content="key.CORE_CONFIG_DESC">
                                    <i class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></i>
                                </a>
                                <div v-switch="key.CORE_CONFIG_TYPE">
                                    <div v-case="'text'">
                                        <input :type='(key.CORE_CONFIG_DATA_TYPE == "Password") ? "password" : "text"'
                                            :maxlength='(key.CORE_CONFIG_DATA_TYPE == "Int" || key.CORE_CONFIG_DATA_TYPE == "Decimal") ? 8 : 10000'
                                            :name="key.CORE_CONFIG_KEY"
                                            :class="'form-control' + ' ' + key.CORE_CONFIG_DATA_TYPE"
                                            :value="key.CORE_CONFIG_VALUE" 
                                            :keyid="key.CORE_CONFIG_ID"/>
                                    </div>
                                    <div v-case="'dropdown'">
                                        <select v-if="key.CORE_CONFIG_KEY == 'shift'" id="Shift" name="Shift" class="form-control" :keyid="key.CORE_CONFIG_ID">
                                            <option value="">{{ $t('Select') }}</option>
                                            <span v-if="AllShift.length > 0">
                                                <option v-for="shift in AllShift" :value="shift.shift_id">{{ shift.shift_name }}
                                                </option>
                                            </span>
                                        </select>
                                        <select v-else-if="key.CORE_CONFIG_KEY == 'TimeZone'" id="TimeZone" name="TimeZone"
                                            class="form-control" :keyid="key.CORE_CONFIG_ID">
                                            <option value="">{{ $t('Select') }}</option>
                                            <option v-for="zone in timezone" :value="zone.shift_id">{{ zone.Value }}</option>
                                        </select>
                                        <select v-else-if="key.CORE_CONFIG_KEY == 'FSMRegionAccess'" name="FSMRegionAccess"
                                            class="form-control" :keyid="key.CORE_CONFIG_ID">
                                            <option value="">{{ $t('Select') }}</option>
                                            <option v-for="region in regionAcessList" :value="region.Code">{{ region.Value }}
                                            </option>
                                        </select>
                                        <select v-else-if="key.CORE_CONFIG_KEY == 'ExchangeTimeZone'" :keyid="key.CORE_CONFIG_ID"
                                            v-for="tzinfo in TimeZoneInfo" name="ExchangeTimeZone" id="ExchangeTimeZone">
                                            <option value="">{{ $t('Select') }}</option>
                                            <option v-if="tzinfo.Id == key.CORE_CONFIG_VALUE" :value="tzinfo.Id"
                                                selected="selected"> {{ tzinfo.DisplayName }}</option>
                                            <option :value="tzinfo.Id"> {{ tzinfo.DisplayName }}</option>
                                        </select>
                                        <select v-else class="form-control" :name="key.CORE_CONFIG_KEY" :keyid="key.CORE_CONFIG_ID">
                                            <template v-for="item in key.CORE_CONFIG_OPTIONS.split('^')">
                                                <template v-if="item.trim() == key.CORE_CONFIG_VALUE.trim()">
                                                    <option v-if="item == 'DSNY'" :value="item" selected="selected">{{item + "(Up State)"}}</option>
                                                    <option v-else-if="item == 'NYC'" :value="item" selected="selected">{{item + "(Down State)"}}</option>
                                                    <option v-else :value="item" selected="selected">@item</option>
                                                </template>
                                                <template v-else>
                                                    <option v-if="item == 'DSNY'" :value="item">{{item + "(Up State)"}}</option>
                                                    <option v-else-if="item == 'NYC'" :value="item">{{item + "(Down State)"}}</option>
                                                    <option v-else :value="item">{{item}}</option>
                                                </template>
                                            </template>
                                        </select>
                                    </div>
                                    <div v-case="'checkbox'">
                                        <div class="rad_value" :type="key.CORE_CONFIG_TYPE" :keyid="key.CORE_CONFIG_ID">
                                            <label v-for="chkOptions in key.CORE_CONFIG_OPTIONS.split('^')"
                                                class="pconfig form-check form-check-inline">
                                                <input type="checkbox" :name="key.CORE_CONFIG_KEY"
                                                    :checked="key.CORE_CONFIG_VALUE.indexOf(chkOptions) > -1"
                                                    :value="chkOptions" />{{ chkOptions }}
                                            </label>
                                        </div>
                                    </div>
                                    <div v-case="'radiobutton'">
                                        <div class="rad_value" :type="key.CORE_CONFIG_TYPE" :keyid="key.CORE_CONFIG_ID">
                                            <div v-for="item in key.CORE_CONFIG_OPTIONS.split('^')">
                                                <label v-if="item.trim() == key.CORE_CONFIG_VALUE.trim()" class="pconfig form-check form-check-inline">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY" :value="item"
                                                            checked="checked" :id="'rdo_1_'+key.CORE_CONFIG_ID"
                                                            class="custom-control-input dynamic">
                                                        <label class="custom-control-label" :for="'rdo_1_'+key.CORE_CONFIG_ID">
                                                            {{ GetResouceKeyValue(item) }}
                                                        </label>
                                                    </div>
                                                </label>
                                                <label v-else class="pconfig form-check form-check-inline">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" :name="key.CORE_CONFIG_KEY" :value="item"
                                                            :id="'rdo_2_'+key.CORE_CONFIG_ID" class="custom-control-input dynamic">
                                                        <label class="custom-control-label" :for="'rdo_2_'+key.CORE_CONFIG_ID">
                                                            {{ GetResouceKeyValue(item) }}
                                                        </label>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-case="'textarea'">
                                <textarea :name="key.CORE_CONFIG_KEY"  class="form-control" cols="" :keyid="key.CORE_CONFIG_ID"
                                    rows="5">{{ key.CORE_CONFIG_VALUE }}</textarea>
                            </div>
                            <div v-case="'image'"></div>
                        </div>
                    </template>
                </div>
                 <div v-if=" ShareTicketToAllUser == '1' && ModuleId == '80'" id="msgShareTicketToAllUser"
                    style="white-space: normal;" class="px-3 mandatory text-info pb-2">
                    {{$t('TicketingUserConfigurationNotificationMessage')}}
                </div>
            </div>
            <div class="row flex-row-reverse">
                <div class="col-lg-6 text-right">
                    <a id="hlkSave" class="btn btn-success formbtn" href="javascript:;"
                        :title="$t('SaveConfigSetting')" v-on:click="SaveConfigSettings()"><i class="fa fa-save pr-2"></i>{{$t('Save')}}
                        {{$t('Settings')}} </a>
                </div>
                <div class="col-lg-6">
                    <div class="py-2 text-danger">{{$t('MandatoryString')}}</div>
                </div>
            </div>
        </div>
</template>
<script>
import DataService from '../../services/DataService'
export default {
    props:{
        UserId:null,
        ModuleId:null
    },
    data: function () {
        return {
            Configuration: [],
            AllShift: [],
            Key: [],
            timezone: [],
            ExtraFeature: '',
            regionAcessList: [],
            TimeZoneInfo: [],
            ShareTicketToAllUser:'',
            totalRecords:0
            
        }
    },
    created: async function () {
        var vm= this;
        await vm.GetAllShift();
        await vm.GetModuleKeys();
        
    },
    methods: {
        GetResouceKeyValue(item){
            var value=item;
        if(item=="no"){
            value='No';
        }else if(item=="yes"){
            value='Yes';
        }
        this.$te(value)?this.$t(value):item;
        },
         GetModuleKeys: async function () {
            var vm = this;
            var url=`moduleId=${vm.ModuleId}&projectuserId=${vm.UserId}`;
            await DataService.GetModuleKeys(url).then(response=>{
                vm.ExtraFeature=response.data.ExtraFeature;
                vm.ShareTicketToAllUser=response.data.ShareTicketToAllUser;
                vm.Configuration=response.data.Result;
                vm.totalRecords=vm.Configuration.length;      
            });
        },
        GetAllShift: async function () {
            var vm = this;
            await DataService.GetAllShift().then(response => {
                console.log(response);
                vm.AllShift = response.data;
            });
        },
        GetTimeZoneList: function () {
            var vm = this;
            var url = "id=&moduleName=CRM&type=TIMEZONE&search=&refId="
            DataService.getDDLData(url).then(function (response) {
                vm.timezone = response.data.DATA;
            });
        },
        SaveConfigSettings(){
            var vm =this;
            $("div#ulKeysContainer div.rad_value").removeClass('is-invalid');
            $('div#ulKeysContainer input:text,div#ulKeysContainer select,div#ulKeysContainer textarea,div#ulKeysContainer input:password').removeClass('invalid-feedback').next("span").html("");
            var error = $('.is-invalid');
            if ($(error).length > 0) {
                vm.ShowAlert(vm.$t("PleaseCorrectHighlightedErrors"), "failure",vm.$t('Alert'));
                return;
            }
            $('.invalid-feedback').removeClass('invalid-feedback');
            var textValues = $('div#ulKeysContainer input:text,div#ulKeysContainer select,div#ulKeysContainer textarea,div#ulKeysContainer input:password'); //get all textboxes,textarea and drop down list from config list
            var checkBoxValues = $('div#ulKeysContainer div[type="radiobutton"]'); //get all div contains checkboxes from config list
            var imageControl = $('div#ulKeysContainer img');
            var Keyvalues = '';

            //get key value pair from textbox,textarea and dropdowns and save in to variable
            for(var i in textValues)
            {
                var textValue = textValues[i].value==undefined ? '' : textValues[i].value.trim();
                if(textValue!=''){
                    if(textValues[i].value.trim() == ''){
                        if(textValues[i].attributes.class.value.indexOf('notrequired')==-1){
                            //textValues[i].addClass('is-invalid').next('span').addClass("is-invalid text-danger").html(String.Format($t('Required')), $t(textValues[i].attributes.name.value));
                        }
                        else{
                            textValue='!~~!';
                        }
                    }
                    Keyvalues=Keyvalues + textValues[i].attributes.keyid.value + '||' + textValue + '::';
                }
            }

            //get key value pair from checkboxes and radio button and save in to variable

            for(var i in checkBoxValues){
                    var getSelectedvalues = '';
                    // if radio button only one item will be selected so its fetching checked value
                    if(checkBoxValues[i].attributes.type.value == 'radiobutton'){
                        //checkBoxValues[i].find('input:radio:checked').val();
                        var getSelectedvalues=checkBoxValues[i].filter(x=>x.checked==true)
                    }
                }

        }
    }
}
</script> 