<script>
import NumericField from '../Common/NumericField.vue'
import SelectField from '../Common/SelectField.vue'
import TextField from '../Common/TextField.vue'
import DateField from '../Common/DateField.vue'
import CheckBoxField from '../Common/CheckBoxField.vue'
import RadioField from '../Common/RadioField.vue'
import CKEditorField from '../Common/CKEditorField.vue'
import MultiSelectField from '../Common/MultiSelectField.vue'
import ToggleField from '../Common/ToggleField.vue'
import PhoneField from '../Common/PhoneField.vue'
import CustomSelectField from '../Common/CustomSelectField.vue'
import TextAreaField from '../Common/TextAreaField.vue'
import ColorField from '../Common/ColorField.vue'
export default {
    name: 'DynamicForm',
    components: {
        NumericField,
        SelectField,
        TextField,
        DateField,
        CheckBoxField,
        RadioField,
        CKEditorField,
        MultiSelectField,
        ToggleField,
        PhoneField,
        CustomSelectField,
        TextAreaField,
        ColorField
    },
    props: {
        schema: {
            type: Array,
            required: true,
        },
        dateformat:{
            type:String,
            required:false,
        },
         UserType:null,
        selectedQuotationType:null,
        odnumber:null,
        subModuleCode:null,
        DependentOption:null
    },
    
    methods: {
        onBlur(item){
                this.$emit('onBlur',item);
            },
    GetGroupName:function(group_display_name,display_name){
            if(group_display_name){
                return this.$t(group_display_name);
            }else{
                return this.$t(display_name);
            }
       },
       openaddIndustrypopup: function (item) {          
        this.$emit('openaddIndustrypopup',item);
        },
        openaddRoleTitlepopup:function(item){
             this.$emit('openaddRoleTitlepopup',item);
        },
        openaddLeadSourcepopup:function(item){
             this.$emit('openaddLeadSourcepopup',item);
        },
        onchangechannel:function(item,groupData){
            this.$emit('onchangechannel',item,groupData);
        },
        AssignToggleValues:function(event, item, array){
            this.$emit('AssignToggleValues',event,item,array);
          },
          setSelectedValue:function(item, val, isFor, type){
            this.$emit('setSelectedValue',item, val, isFor, type);
          },
          showDependentItem:function(item, val){
            this.$emit('showDependentItem',item, val);
          },  
          setDependentDate: function (item) {
               this.$emit('setDependentDate', item);
            }, 
            FillDropdown(item){
                this.$emit('FillDropdown', item);
            } ,
            onchangeForNormalDDL(item){
                this.$emit('onchangeForNormalDDL', item);
            },
            onChange(item){
                this.$emit('onChange', item);
            },
            onSearchDDL: function(search, objItem, isFor){
            this.$emit('onSearchDDL',search, objItem, isFor);
          }, 
          onChangeOpenAddChannelTag:function(item){                
            this.$emit('onChangeOpenAddChannelTag',item);
          }
           
    },
    updated(){
        //$('[data-toggle="popover"]').popover()
    },      
};
</script>
<template>
    <div>
        <div class="row" v-for="(group,parentIndex) in schema" :key="parentIndex">
                        <div class="clearfix"></div>
                        <div class="col-lg-12 mb-3">
                            <div class="heading-border mt-2">                               
                                <h5 class="h5"><span>{{GetGroupName(group.group_display_name,group.group_name)}}</span></h5>
                            </div>                       
                        </div> 
    <div v-for="(field,fieldIndex) in group.Data" :key="fieldIndex"
    :class="{'col-sm-12 col-md-12 float-left': true, 'd-none' : field.form_field_visibility == 'NO' || (subModuleCode=='CRM_SALES_ORDERS' ||subModuleCode=='CRM_INVOICE'?false:subModuleCode=='CRM_PURCHASE_ORDERS'?false:field.name == 'template_id'), 'referredby':field.name == 'referred_by',
    'campaignname':field.name == 'campaign_id','col-lg-12 col-xl-12': field.layout_type =='single', 'col-lg-6 col-xl-6': field.layout_type =='double'  || field.name=='quotation_type',
     'col-lg-4 col-xl-4': field.layout_type =='triple', 'col-lg-3 col-xl-3': field.layout_type =='four'  && field.name!='quotation_type' }" v-bind:id="field.name" 
     v-show="subModuleCode=='CRM_QUOTES'?((field.dependent_show_type == 1 && field.name != 'deal_id') || (field.dependent_show_type == 1 && field.name == 'deal_id' && selectedQuotationType == 2)):(field.dependent_show_type == 1)"
    >
    
            <div class="form-group" v-if="field.form_field_visibility == 'YES'">
                <label v-if="selectedQuotationType==2 || field.name != 'deal_id' || field.dependent_show_type == 1">{{GetFormSchemaFieldName(field.name,field.display_name)}}:
                    <span v-if="field.is_required" v-bind:class="{ 'text-danger': true }">*</span></label> 
                <a href="javascript:;" v-bind:tabindex="-1"  v-popover:tooltip="$t(field.user_guide)" 
                 v-if="!isBlank(field.user_guide) && (selectedQuotationType==2 || field.name != 'deal_id')">
                    <em  class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></em>
                </a>
                <div class="row">
                    <div class="col-12 float-left">
                        <div :class="{'input-group':field.astype!='CKEditorField'}">
                            <component 
                            :fieldInfo="field" 
                            :is="field.astype"
                            :odnumber="odnumber"
                            :DependentOption="DependentOption"
                            :subModuleCode="subModuleCode"
                            :UserType="UserType"
                            :selectedQuotationType="selectedQuotationType"
                            :dateformat="dateformat"
                             v-on:FillDropdown="FillDropdown"
                             v-on:openaddIndustrypopup="openaddIndustrypopup"
                             v-on:openaddRoleTitlepopup="openaddRoleTitlepopup" 
                             v-on:openaddLeadSourcepopup="openaddLeadSourcepopup"
                             v-on:onchangechannel="onchangechannel(field,group.Data)"
                             v-on:AssignToggleValues="AssignToggleValues"
                             v-on:setSelectedValue="setSelectedValue"
                             v-on:showDependentItem="showDependentItem"
                             v-on:setDependentDate="setDependentDate"
                             v-on:onchangeForNormalDDL="onchangeForNormalDDL"
                             v-on:onChange="onChange"
                             v-on:onSearchDDL="onSearchDDL"
                             v-on:onBlur="onBlur"
                             v-on:onChangeOpenAddChannelTag="onChangeOpenAddChannelTag">                            
                            </component>  
                                                     
                        </div>
                    </div>
                </div>
            </div>
      </div>
 </div>
    </div>

</template>
<style>
[data-popover='tooltip'] { font-size: 13px;  line-height: 1;  margin: 5px;color:#000000; border:1px solid #ccc;padding: 10px; width: auto!important; }

  </style>
