var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal d-block my-popups dialog"},[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_vm._t("headerForModal",function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t('Bookmark')))])]}),_c('button',{staticClass:"close",attrs:{"type":"button","href":"javascript:;"},on:{"click":function($event){return _vm.$emit('closebookmark')}}},[_c('em',{staticClass:"fa fa-times"})])],2),_c('div',{staticClass:"modal-body"},[_vm._m(0),_c('div',{staticClass:"form-panel mt-0 p-0"},[_c('div',{staticClass:"form-content"},[_c('Form',{ref:"form",attrs:{"id":"frmSaveBookmark"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Url'))+":"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('Field',{staticClass:"w-100",attrs:{"name":"Url","rules":{ 'required': false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.url),expression:"url"}],class:{ 'form-control w-100': true, 'is-invalid': errors[0] },attrs:{"name":"Url","type":"text","disabled":""},domProps:{"value":(_vm.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.url=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback",attrs:{"name":"Url"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('Title'))+":"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('Field',{staticClass:"w-100",attrs:{"name":"Title","rules":{ 'required': true, 'max': 200 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],class:{ 'form-control w-100': true, 'is-invalid': errors[0] },attrs:{"name":"Title","type":"text"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback",attrs:{"name":"Title"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]}}])})],1),_c('div',{staticClass:"row flex-row-reverse mb-3"},[_c('div',{staticClass:"col-lg-6 text-right"},[_c('a',{staticClass:"btn btn-success formbtn mr-1",attrs:{"title":_vm.$t('Save'),"href":"javascript:;","id":"aSave"},on:{"click":function($event){return _vm.SaveBookMark(_vm.valid,_vm.errors)}}},[_c('i',{staticClass:"fa fa-save pr-2"}),_vm._v(_vm._s(_vm.$t('Save')))]),_c('a',{staticClass:"btn btn-danger formbtn",attrs:{"title":"$t('Cancel')","href":"javascript:;","id":"aBMCancel"},on:{"click":function($event){return _vm.vm.$emit('closebookmark')}}},[_c('i',{staticClass:"fa fa-close pr-2"}),_vm._v(_vm._s(_vm.$t('Cancel')))])]),_vm._m(1)])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row form-head",staticStyle:{"display":"none"}},[_c('span',{staticClass:"user-guide"},[_c('a',{staticClass:"ancuserguide",attrs:{"data-toggle":"collapse","href":"#collapseReply","role":"button","aria-expanded":"false","aria-controls":"collapseExample"}},[_vm._v("@Resources.UserGuide")]),_c('div',{staticClass:"divancuserguide collapse",attrs:{"id":"collapseReply"}},[_c('div',{staticClass:"custom-scrollbar-js",attrs:{"id":"scrollbarreplyuserguide"}},[_c('div',{staticClass:"con"})])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"py-2"})])}]

export { render, staticRenderFns }