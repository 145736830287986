<template>
  <transition name="modal">
    <div class="my-popups">
      <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered" style="max-width:900px !important">
          <div class="modal-content">
            <div class="modal-header theme-primary partition-full">
              <h4 class="modal-title">{{$t('Add')}} {{$t('Role')}} {{$t('Title')}}</h4>
              <button type="button" class="close" v-on:click="$emit('close')">
                <em class="fa fa-times" aria-hidden="true"></em>
              </button>
            </div>
            <div class="modal-body">
              <Form ref="roleForm">
                <div class="col-md-12 float-left px-0">
                  <div class="row" style="max-height: 450px; overflow-y: auto;">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          {{$t('Role')}} {{$t('Title')}} :
                          <span class="text-danger">*</span>
                        </label>
                        <Field
                          name="Title"
                          :rules="{'required':true}"
                          label="Title"
                          v-slot="{errors}"
                        >
                          <input
                            v-bind:class="{'form-control':true, 'is-invalid':errors[0]}"
                            name="Title"
                            maxlength="100"
                            v-model="RoleName"
                            type="text"                           
                          />
                          <span
                            name="Name"
                            class="invalid-feedback"
                          >{{errors[0]}}</span>
                        </Field>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>{{$t('Description')}} :</label>
                        <Field
                          name="Description"
                          :rules="{'required':false}"
                          label="Description"
                        >
                          <textarea
                            v-bind:class="{'form-control' : true}"
                            maxlength="1000"
                            name="Description"               
                            v-model="RoleDesc"
                            style="max-height:300px!important;min-height:100px!important"
                          />
                            <!-- <span
                              
                              name="Description"
                              class="invalid-feedback"
                            >{{errors[0]}}</span> -->
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div class="modal-footer">
              <div class="col-md-12 p-0">
                <div class="row flex-row-reverse">
                  <div class="col-lg-6 text-right">
                    <a
                      href="javascript:;"
                      v-on:click="SaveRole(valid,errors);"
                      title="Save"
                      class="btn btn-success save-btn mr-1"
                    >
                      <em class="fa fa-save pr-2"></em>
                      {{$t('Save')}}
                    </a>
                    <a
                      href="javascript:;"
                      v-on:click="$emit('close')"
                      class="btn btn-danger  delete-btn"
                    >
                      <em class="fa fa-times"></em>
                      {{$t('Cancel')}}
                    </a>
                  </div>
                  <div class="col-lg-6">
                    <div class="py-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import LeadDataService from "../../../services/DataService";
export default {
  data() {
    return {
      RoleName: "",
      RoleDesc: "",
    };
  },
 
  methods: {
    SaveRole: function (valid,errors) {
      var vm = this;
      var jsonobj = {
        RoleName: vm.RoleName,
        RoleDesc: vm.RoleDesc,
      };
      // vm.$refs.roleForm.setFieldTouched("Title", true);
      var jobj = JSON.stringify(jsonobj);
      
      this.$refs.roleForm.validate().then((result) =>{
        
         if (result) {
          LeadDataService.SaveContactRoleTitle(jobj).then((response)=>{     
            
              if (response.data.Status == "SUCCESS") {
                vm.ShowAlert(vm.$t("Role title has been saved successfully."));
                       
                var url = "id=&moduleName=CRM&type=CONTACT_JOB_TITLE";
                 
                LeadDataService.getLeaOwnerBySearch(url).then((response1)=> {
                  var json = [];
                  if (response1.data != null) {
                    json = response1.data.DATA;
                  }
                  vm.$emit('updatedata','role_title_id', json);    
                  vm.$emit("close");
                   
                });
              } else if (response.data.Status == "EXIST") {
               
                vm.ShowAlert(vm.$t("Role title already exist."));
              }
               
            });
        }
        // else{
        //   vm.ShowAlert(vm.$t("please correct the highlights"));
        // }
      });
    },
  },
};
</script>