<script>
export default {
  name: "text-input",
  props: {
    fieldInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>
<template>
  <Field class="w-100"
    :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)"
    :rules="{'required':fieldInfo.is_required}"
    v-slot="{errors}"
  >
    <textarea cols="5" v-model="fieldInfo.value"
    v-bind:class="{'form-control w-100' : true,'is-invalid': errors[0]}"
    :placeholder="GetFieldPlaceHolder(fieldInfo.name,fieldInfo.display_name)" 
    />
    <span
      v-if="errors[0]" 
      :name="fieldInfo.name"
      class="invalid-feedback"
    >{{errors[0]}}</span>
  </Field>
</template>