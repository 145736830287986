
<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
    import Addwizard from "./components/Wizard/addwizard.vue";
import SaveBookMark from "./components/BookMark/SaveBookMark.vue";
import ViewAllNotification from "./components/Notification/ViewAllNotification.vue";
export default {
  components: { Addwizard, SaveBookMark,ViewAllNotification },
  data(){
    return{
       showAddWizard:false,
       showAddBookMark:false,
       showNofication:false,
       wizardType:'',
       controllerName:null,
       actionName:null,
       isShowMenuHeader:true
       
    }
  },
  created(){
    var self = this;
    var pathName = window.location.pathname.split("/");
    self.controllerName= pathName[1].toLowerCase();
    self.actionName= pathName[2].toLowerCase();
    if((self.actionName=='fullmenu') || ((self.controllerName=='user' || self.controllerName=='account') && (self.actionName=='dashboard' || self.actionName=='home'))){
      self.isShowMenuHeader=false;
    }
    window.addEventListener('event_addwizard', (customEvent) => {
      const {detail} = customEvent || {}
      self.wizardType=detail.wizardType;
      self.showAddWizard=true;
     })
    window.addEventListener('event_addbookmark', (customEvent) => {
      self.showAddBookMark = true;
    })
    window.addEventListener('event_viewnotification', (customEvent) => {
      self.showNofication = true;
    })
  },
  updated(){
    var self = this;
    var pathName = window.location.pathname.split("/");
    self.controllerName= pathName[1].toLowerCase();
    self.actionName= pathName[2].toLowerCase();
    if((self.actionName=='fullmenu') || ((self.controllerName=='user' || self.controllerName=='account') && (self.actionName=='dashboard' || self.actionName=='home'))){
      self.isShowMenuHeader=false;
    }
    else{
      self.isShowMenuHeader=true;
    }
  },
    setup() {
        const store = useloggedUserInfo();
        const modulestore = moduleStore();
        const { GetUserInfo } = storeToRefs(store);
        const { GetModuleId } = storeToRefs(modulestore);
        return { GetUserInfo, GetModuleId };
    },
}
</script>
<template>
  <section>
    <ModuleHeader v-if="(GetUserInfo != null && isShowMenuHeader)" :modulemasterid="String(GetModuleId)"></ModuleHeader>   
    <section id="main-content" class="main-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 mt-2">
                      <router-view/>
                    </div>
                </div>
            </div>
            <tooltip  :width="250"/>
            <SaveBookMark v-if="showAddBookMark" v-on:closebookmark="showAddBookMark=false"></SaveBookMark>
            <Addwizard v-if="showAddWizard"  :wizardType="wizardType" v-on:close="showAddWizard=false"></Addwizard>
            <ViewAllNotification  v-if="showNofication" v-on:closenotification="showNofication=false"></ViewAllNotification>
        </section>
  </section>       
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}

</style>
