<template lang="en">
<section>
    <loader :is-visible="isLoading"></loader>
    <div class="modal my-popups right fade show" role="dialog" style="display: block;">
        <div class="modal-dialog mw-1100" role="document">
            <div class="modal-content">
                <div class="modal-header mheader-user">
                    <button type="button" class="close" @click="$emit('close')"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <h4 class="modal-title" id="myModalLabel">{{ $t('ManageSchedule') }}</h4>
                </div>
                <div class="modal-body">
                    <dynamic-form lang="en" ref="AddForm" :buttons="buttons" :schema="FormSchema" v-on:OnSubmit="onSubmit">
                        <template v-slot:tgslot-SendType="{}">
                            <ul id="ManageCampaignTypes" class="shortcuts-menu pb-3 man_camp man_view">
                               <li :class="SendType == 'SendNow' ? 'position-relative active' : 'position-relative'">
                                   <a href="javascript:" data-toggle="tooltip" title="" data-placement="bottom" @click="onClickSendOrSchedule('SendNow')" class="short-menu-item" data-toggle-tooltip="tooltip">
                                       <span class="left-icon-m">
                                           <i class="fa fa-send-o"></i>
                                       </span>
                                       <span class="short-item-name">
                                           <span class="w-100 float-left">Send Now</span>
                                           <span class="font-12 float-left">Send the email campaign immediately</span>
                                       </span>
                                   </a>
                                </li>
                                <li :class="SendType == 'Schedule' ? 'position-relative active' : 'position-relative'">
                                    <a href="javascript:" data-toggle="tooltip" title="" data-placement="bottom" @click="onClickSendOrSchedule('Schedule')" class="short-menu-item" data-toggle-tooltip="tooltip">
                                        <span class="left-icon-m">
                                            <i class="fa fa-calendar-o"></i>
                                        </span>
                                        <span class="short-item-name">
                                            <span class="w-100 float-left">Schedule</span>
                                            <span class="font-12 float-left">Pick a date and time</span>
                                        </span>
                                    </a>
                                </li>
                                <li :class="SendType == 'BatchSchedule' ? 'position-relative active' : 'position-relative'">
                                    <a href="javascript:" data-toggle="tooltip" title="" data-placement="bottom" @click="onClickSendOrSchedule('BatchSchedule')" class="short-menu-item" data-toggle-tooltip="tooltip">
                                        <span class="left-icon-m">
                                            <i class="fa fa-users"></i>
                                        </span>
                                        <span class="short-item-name">
                                            <span class="w-100 float-left">Batch Schedule</span>
                                            <span class="font-12 float-left">Schedule to large groups of contacts in drip mode</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </dynamic-form>
                </div>
            </div>
        </div>
    </div>
    <modaladdsmtp v-if="isShowSMTPModal" :SendThrough="SendThrough" :ProfileId="ProfileId"  @close="isShowSMTPModal=false"></modaladdsmtp>
    <modalSMSIntegration v-if="ShowmodalSMSIntegration" :SendThrough="SendThrough" :ProfileId="ProfileId" @close="ShowmodalSMSIntegration=false"></modalSMSIntegration>
</section>
</template>

<script>
import CampaignDataServices from "../../../services/DataService";
import modaladdsmtp from './modaladdsmtp.vue';
import modalSMSIntegration from '../../Common/Modal/modalSMSIntegration.vue';
export default {
    components: {modaladdsmtp, modalSMSIntegration},
    props: { 
        campaignId:  {
            type: Number,
            required: true
        },
        campaignName:{
            type: String,
            required: true
        },
        mailChimpTemplateId:  {
            type: Number,
            required: true
        },
        campaignType: {
            type: String,
            required: true
        },
        reqFrom: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            Contactlist:[],
            isLoading: false,
            timezone:[],
            isShowSMTPModal:false,
            Grouplist:[],
            FormSchema: [{
                    layoutType: "single",
                    Data: [{
                        astype: "SlotField",
                        name: "SendType",
                        value: "",
                        placeholder: "",
                    },
                ]
                },
                {
                    layoutType: "double",
                    Data: [{astype: "SlotField",
                        name: "Schedule",
                        value: "",
                        placeholder: "", 
                        visibility: false,
                    },]
                },
                {
                    layoutType: "single",
                    Data: [{astype: "SlotField",
                        name: "Batch",
                        value: "",
                        placeholder: "", 
                        visibility: false,
                    },]
                },
                {
                    layoutType: "double",
                    group_name:this.$t('ScheduleAt'),
                    group_display_name: this.$t('ScheduleAt'),
                    Data: [{
                            astype: "DateField",
                            label: "Schedule Date",
                            name: "ScheduleAtDate",
                            value: "",
                            placeholder: "Enter Start Date",
                            config: {
                                format: "M/D/YYYY",
                                minDate:new Date(),
                                onChange: this.onChangeDate
                            },
                            validationRules: {"required": true},
                            visibility: false,
                        },
                        {
                            astype: "DateField",
                            label: this.$t('ScheduleTime'),
                            name: "ScheduleAtTime",
                            value: "",
                            placeholder: "",
                            config: {
                                format: "M/D/YYYY",
                                mode: 'Time',
                                minDate:new Date()
                            },
                            validationRules: {"required": true},
                            visibility: false,
                        }
                     ]
                },
                {
                    layoutType: "triple",
                    group_name: this.$t('NoofRecipients'),
                    group_display_name: this.$t('NoofRecipients'),
                    Data: [{
                            astype: "DateField",
                            label: this.$t("EventStartDate"),
                            name: "BatchStartDate",
                            value: "",
                            placeholder: "Enter Start Date",
                            config: {
                                format: "M/D/YYYY",
                                minDate:new Date(),
                                onChange: this.onChangeDate
                            },
                            validationRules: {"required": true},
                            visibility: false,
                        },
                        {
                            astype: "DateField",
                            label: this.$t('Specifystartandendtime'),
                            name: "BatchStartTime",
                            value: "",
                            placeholder: "",
                            config: {
                                format: "M/D/YYYY",
                                mode: 'Time',
                                minDate:new Date()
                            },
                            validationRules: {"required": false},
                            visibility: false,
                        },
                        {
                            astype: "DateField",
                            name: "BatchEndTime",
                            value: "",
                            placeholder: "",
                            config: {
                                format: "M/D/YYYY",
                                mode: 'Time',
                                minDate:new Date()
                            },
                            validationRules: {"required": false},
                            visibility: false,
                        }
                    ]
                },
                {
                    layoutType: "four",
                    Data: [
                        {
                            astype: "NumericField",
                            label: this.$t('BatchQuantity'),  
                            name: "BatchQuantity",
                            value: "",
                            placeholder: "Batch Quantity",
                            config: {
                                options: [],
                            },
                            validationRules: {"required": false},
                            visibility: false,
                        },
                        {
                            astype: "SelectField",
                            label: '',
                            name: "BatchType",
                            value: "",
                            placeholder: "",
                            config: {
                                options: [{value: "Days",name: "Days"}, {value: "Hours", name: "Hours"}, {value: "Minutes", name: "Minutes"}, {value: "Seconds", name: "Seconds"}],
                            },
                            validationRules: {"required": false},  
                            visibility: false,
                        },
                        {
                            astype: "NumericField",
                            label: this.$t('RepeatAfter'),  
                            name: "RepeatAfter",
                            value: "",
                            placeholder: "Repeat After",
                            config: {
                                options: [],
                            },
                            validationRules: {"required": false},
                            visibility: false,
                        },
                    ]
                },
                {
                    layoutType: "single",
                    Data: [{
                            astype: "CheckBoxField",
                            label: this.$t('SelectDay'),
                            name: "BatchDays",
                            value: ['1','2','3','4','5','6','7'],
                            config: {
                                options: [{value: "1",name: "Mon"}, {value: "2", name: "Tue"}, {value: "3", name: "Wed"}, {value: "4", name: "Thu"}, {value: "5", name: "Fri"}, {value: "6", name: "Sat"}, {value: "7", name: "Sun"}]
                            },
                            validationRules: { "required": false },
                            visibility: false,
                        }
                    ]
                },
                {
                    group_name: 'Sender Details',
                    group_display_name: this.$t('Sender Details'),
                    layoutType: "double",
                    Data: [{
                            astype: "SelectField",
                            label: this.$t('SendThrough'),
                            name: "SendThrough",
                            value: "",
                            placeholder: "Select",
                            config: {
                                options: [{name:'MailChimp',value:'MailChimp'},{name:'SendGrid',value:'SendGrid'},{name:'SMTP',value:'SMTP'}],
                                onChange: this.onChangeSendThrough
                            },
                            validationRules: {"required": true},
                        },
                        {
                            astype: "SelectField",
                            label: this.$t('SendProfile'),
                            name: "SendProfile",
                            value: "",
                            placeholder: "Select",
                            config: {
                                options: [],
                                onChange: this.onChangeProfile,
                                showAddIcon: true,
                                onAddButtonClick: this.AddSMTPCredentials,
                            },
                            validationRules: { "required": true },
                        },
                    ]
                },
                {
                    layoutType: "single",
                    Data: [{
                            astype: "CheckBoxField",
                            label: this.$t('TrackEmail'),
                            name: "TrackEmail",
                            value: "",
                            config: {
                                options: [{value: "true",name: ""}]
                            },
                            validationRules: { "required": false },
                        }
                    ]
                },
                {
                    group_name: 'Enter Email Details',
                    group_display_name: this.$t('Enter Email Details'),
                    layoutType: "double",
                    Data: [{
                            astype: "TextField",
                            label: this.$t('SenderEmail'),
                            name: "SenderEmail",
                            value: "",
                            placeholder: "",
                            config: {},
                            validationRules: { "required": true },
                        },
                        {
                            astype: "TextField",
                            label: this.$t('SenderName'),
                            name: "SenderName",
                            value: "",
                            placeholder: "",
                            config: {},
                            validationRules: { "required": true },
                        },
                    ]
                },
                {
                    layoutType: "double",
                    Data: [{
                            astype: "TextField",
                            label: this.$t('Subjectline'),
                            name: "Subjectline",
                            value: "",
                            placeholder: "Subject line",
                            config: {},
                            validationRules: { "required": true },
                        },
                        {
                            astype: "TextField",
                            label: this.$t('PreviewText'),
                            name: "PreviewText",
                            value: "",
                            placeholder: "(Optional)",
                            config: {},
                            validationRules: { "required": false },
                            visibility: false,

                        },
                    ]
                },
                {
                    layoutType: "single",
                    Data: [{
                            astype: "RadioField",
                            label: this.$t(''),
                            name: "Recipient",
                            value: "1",
                            placeholder: "",
                            config: {
                                options: [{value: "1",name: "Choose Contacts"}, {value: "2", name: "Send to Smart List"}, {value: "3", name: "Send to Leads"}],
                                onChange: this.onclickContactorGroup
                            },
                            visibility: true,
                            validationRules: { "required": false},
                        },
                    ]
                },
                {
                    layoutType: "double",
                    Data: [
                    {
                        astype: "MultiSelectField",
                        label: this.$t('Contact'),
                        name: "Contact",
                        value: "",
                        mode: "tag",
                        config: {
                            options: []
                        },
                        visibility:true,
                        validationRules: {"required": true },
                    },
                    {
                        astype: "MultiSelectField",
                        label: this.$t('ContactGroup'),
                        name: "ContactGroup",
                        value: "",
                        mode: "tag",
                        config: {
                            options: []
                        },
                        visibility:false,
                        validationRules: {"required": true },
                    },
                    {
                        astype: "MultiSelectField",
                        label: this.$t('LeadContacts'),
                        name: "LeadContacts",
                        value: "",
                        mode: "tag",
                        config: {
                            options: []
                        },
                        visibility:false,
                        validationRules: {"required": true },
                    }
                    ]
                },               
            ],
            buttons: [
                {
                    type: "submit",
                    class: "btn btn-primary",
                    text: "<i class='fa fa-send pr-2'></i>Send",
                },
                {
                    type: "submit",
                    class: "btn btn-success",
                    text: "<i class='fa fa-envelope pr-2'></i>Save as Draft",
                },
                {
                    type: "button",
                    class: "btn btn-danger",
                    text: "<i class='fa fa-close pr-2'></i>Cancel",
                    onClick: this.closepopup,
                    isVisible: true,
                },
            ],
            SendThrough:'',
            ProfileId:0,
            SendType:'SendNow',
            ScheduleAtTime:null,
            BatchStartTime:null,
            BatchEndTime:null,
            ShowmodalSMSIntegration: false,            
        }
    },
    created: async function () {
        var vm=this;
        vm.FormSchema[3].group_display_name='';
        vm.FormSchema[4].group_display_name='';
        vm.FormSchema[3].group_name='';
        vm.FormSchema[4].group_name='';
        await vm.GetContacts();
        if(vm.campaignId > 0){
            await vm.GetScheduledCampaign(); 
        }
        vm.$refs.AddForm.UpdateKeyValue();
    },
    methods: {
        closepopup() {
            var vm = this;
            if(vm.reqFrom=='wizard'){
                vm.$emit('close');
            }
            else{
                vm.$parent.closeManageCampaign();
            }
        },
        GetContacts: async function () {
            var vm = this;
            var url = `id=&moduleName=CRM&type=CRM_CONTACTS&search=&refId=`;
            await CampaignDataServices.getDDLData(url).then(function (response) {
                vm.Contactlist=[];
                vm.FormSchema[12].Data[0].config.options=[];
                vm.Contactlist = response.data;
                vm.Contactlist.forEach((item, index) =>{
                vm.FormSchema[12].Data[0].config.options.push({
                        name: item.name,
                        value: item.value
                    })
                })
            });
        },
        async onChangeSendThrough(event){
            var vm =this;
            vm.ProfileId=0;
            if(typeof(event)=='object'){
                vm.SendThrough=event.target.value;
            }
            else{
                vm.SendThrough=event;
            }
            vm.FormSchema[7].Data[1].value='';
           await vm.FillProfileDropdown(vm.SendThrough);
           vm.$refs.AddForm.UpdateKeyValue();
        },
        async FillProfileDropdown(type){
            var vm =this;
            vm.FormSchema[7].Data[1].config.options=[];
            var url = `id=&moduleName=CRM&type=${type}`;
           await CampaignDataServices.getDDLData(url+ "&search=").then(response => {
                if(response.data.DATA != undefined){
                    var options = response.data.DATA;
                    options.forEach(item=>{
                    vm.FormSchema[7].Data[1].config.options.push({name:item.name, value:item.value})
                })
                }
            });
        },
        onChangeProfile(event){
            var vm=this;
            // vm.ProfileId=event.target.value;
        },
        AddSMTPCredentials(){
            var vm =this;
            if(vm.FormSchema[7].Data[0].value==''){
                vm.ShowAlert('Please select atleast one value in send through dropdown.', "warning", true, vm.$t("Alert"));
                return false;
            }
            vm.isShowSMTPModal=true;
            if(vm.SendThrough == 'MailChimp' || vm.SendThrough == 'SendGrid'){
                vm.ShowmodalSMSIntegration=true;
                vm.isShowSMTPModal=false;
            }
        },
        onClickSendOrSchedule(type){
            var vm = this;
            if (vm.campaignId <= 0) {
                vm.ResetProperties();
            }
            vm.SendType=type;
            if(type=='SendNow'){
                vm.FormSchema[3].group_display_name='';
                vm.FormSchema[4].group_display_name='';
                vm.FormSchema[4].group_name='';
                vm.FormSchema[3].group_name='';
                vm.FormSchema[3].Data[0].visibility=false;
                vm.FormSchema[3].Data[1].visibility=false;
                vm.FormSchema[4].Data[0].visibility=false;
                vm.FormSchema[4].Data[1].visibility=false;
                vm.FormSchema[4].Data[2].visibility=false;
                vm.FormSchema[5].Data[0].visibility=false;
                vm.FormSchema[5].Data[1].visibility=false;
                vm.FormSchema[5].Data[2].visibility=false;
                vm.FormSchema[6].Data[0].visibility=false;
            }else if(type=='Schedule'){
                vm.FormSchema[4].group_display_name='';
                vm.FormSchema[4].group_name='';
                vm.FormSchema[3].group_display_name=vm.$t('ScheduleAt');
                vm.FormSchema[3].group_name=vm.$t('ScheduleAt');
                vm.FormSchema[3].Data[0].visibility=true;
                vm.FormSchema[3].Data[1].visibility=true;
                vm.FormSchema[4].Data[0].visibility=false;
                vm.FormSchema[4].Data[1].visibility=false;
                vm.FormSchema[4].Data[2].visibility=false;
                vm.FormSchema[5].Data[0].visibility=false;
                vm.FormSchema[5].Data[1].visibility=false;
                vm.FormSchema[5].Data[2].visibility=false;
                vm.FormSchema[6].Data[0].visibility=false;
            }else{
                vm.FormSchema[4].group_display_name=vm.$t('NoofRecipients');
                vm.FormSchema[4].group_name=vm.$t('NoofRecipients');
                vm.FormSchema[3].group_display_name='';
                vm.FormSchema[3].group_name='';
                vm.FormSchema[3].Data[0].visibility=false;
                vm.FormSchema[3].Data[1].visibility=false;
                vm.FormSchema[4].Data[0].visibility=true;
                vm.FormSchema[4].Data[1].visibility=true;
                vm.FormSchema[4].Data[2].visibility=true;
                vm.FormSchema[5].Data[0].visibility=true;
                vm.FormSchema[5].Data[1].visibility=true;
                vm.FormSchema[5].Data[2].visibility=true;
                vm.FormSchema[6].Data[0].visibility=true;
            }
        },
        GetLeadContacts: async function () {
            var vm = this;
            var url = `id=&moduleName=CRM&type=CRM_LEAD_EMAILS&search=&refId=`;
            await CampaignDataServices.getDDLData(url).then(function (response) {
                vm.Leadlist=[];
                vm.FormSchema[12].Data[2].config.options=[];
                vm.Leadlist = response.data;
                vm.Leadlist.forEach((item, index) =>{
                vm.FormSchema[12].Data[2].config.options.push({
                        name: item.name,
                        value: item.value
                    })
                })
            });
        },
        GetContactGroup: async function (){
            var vm = this;
            var url = 'id=&moduleName=CRM&type=CRM_GROUP&search=&refId=';
            await CampaignDataServices.getDDLData(url).then(function (response){
                vm.ContactGrouplist=[];
                vm.FormSchema[12].Data[1].config.options=[];
                vm.ContactGrouplist = response.data;
                vm.ContactGrouplist.forEach((item, index) =>{
                vm.FormSchema[12].Data[1].config.options.push({
                        name: item.name,
                        value: item.value
                    })
                })
            }) 
        },
        onclickContactorGroup: async function(event){
            var vm = this;
            var value = event.target.getAttribute('data-value');
            vm.FormSchema[12].Data[1].value='';
            vm.FormSchema[12].Data[0].value='';
            vm.FormSchema[12].Data[2].value='';
            if(value=='1'){
                await vm.GetContacts();
                vm.FormSchema[12].Data[1].visibility=false;
                vm.FormSchema[12].Data[0].visibility=true;
                vm.FormSchema[12].Data[2].visibility=false;
            }
            else if(value=='2'){
                await vm.GetContactGroup();
                vm.FormSchema[12].Data[0].visibility=false;
                vm.FormSchema[12].Data[1].visibility=true;
                vm.FormSchema[12].Data[2].visibility=false;
            }else{
                await vm.GetLeadContacts();
                vm.FormSchema[12].Data[0].visibility=false;
                vm.FormSchema[12].Data[1].visibility=false;
                vm.FormSchema[12].Data[2].visibility=true;
            }
        },
        ResetProperties(){
            var vm=this;
            vm.BatchStartTime=null;
            vm.BatchEndTime=null;
            vm.ScheduleAtTime=null;
            vm.FormSchema[3].Data[0].value='';
            vm.FormSchema[4].Data[0].value='';
            vm.FormSchema[5].Data[0].value='';
            vm.FormSchema[5].Data[1].value='';
            vm.FormSchema[5].Data[2].value='';
            vm.FormSchema[6].Data[0].value=['1','2','3','4','5','6','7'];
        },
        onSubmit: async function (formFeilds, values) {
            var btnType='';
            if(values !=''){
                var btnValueArr= values.split('</i>');
                btnType=btnValueArr[1];
            }
            var vm = this;
            vm.isLoading = true;
            var schedule ={
                schedule_date         : formFeilds.ScheduleAtDate,//vm.$options.filters.formatDateTime(formFeilds.ScheduleAtDate),
                schedule_time         : formFeilds.ScheduleAtTime,//vm.$options.filters.formatDateTime(formFeilds.ScheduleAtTime),
                schedule_timezone     : null
            }
            var batch_schedule ={
                batch_start_date      : formFeilds.BatchStartDate,
                batch_quantity        : formFeilds.BatchQuantity,
                batch_type            : formFeilds.BatchType,
                repeat_after          : formFeilds.RepeatAfter,
                batch_days            : formFeilds.BatchDays ? formFeilds.BatchDays.join() : formFeilds.BatchDays,
                batch_start_time      : formFeilds.BatchStartTime, //vm.$options.filters.formatDateTime(formFeilds.BatchStartTime),
                batch_end_time        : formFeilds.BatchEndTime //vm.$options.filters.formatDateTime(formFeilds.BatchEndTime)
            }
            var jsonObj ={
                campaign_id           : vm.campaignId,
                sub_module_code       : 'CRM_CAMPAIGNS',
                send_type             : vm.SendType,
                send_through          : formFeilds.SendThrough,
                profile_id            : formFeilds.SendProfile,
                track_email           : formFeilds.TrackEmail=='' ? false : true,
                campaign_type_name    :'EMAIL',
                recipient             : formFeilds.Recipient,
                from_email            : formFeilds.SenderEmail,
                from_name             : formFeilds.SenderName,
                contact               : formFeilds.Contact,
                contact_group         : formFeilds.ContactGroup,
                lead_contacts         : formFeilds.LeadContacts,
                subject               : formFeilds.Subjectline,
                status_code           : btnType=='Save as Draft' ? 'CRM_DRAFT' : 'SEND',
                //preview_text          : formFeilds.PreviewText,
                schedule              : schedule,
                batch_schedule        : batch_schedule
            }
            var url = JSON.stringify(jsonObj);
            await CampaignDataServices.SaveScheduleCampaign(url).then(async response => {
            if(response.data[0].MSG && (response.data[0].MSG.toLowerCase() == 'draft sent' || response.data[0].MSG.toLowerCase() == 'draft scheduled' || response.data[0].MSG.toLowerCase() == 'draft batch_scheduled')){
                    vm.ShowAlert(vm.$t('CampaignSave'),"success", true, vm.$t("Alert"));
                    vm.closepopup();
                }else if(response.data[0].MSG && (response.data[0].MSG.toLowerCase() == 'sent')){
                    vm.ShowAlert(vm.$t('CampaignSent'),"success", true, vm.$t("Alert"));
                    vm.closepopup();
                }else if(response.data[0].MSG && (response.data[0].MSG.toLowerCase() == 'scheduled' || response.data[0].MSG.toLowerCase() == 'batch_scheduled')){
                    vm.ShowAlert(vm.$t('CampaignScheduled'),"success", true, vm.$t("Alert"));
                    vm.closepopup();
                }else if(response.data[0].MSG && (response.data[0].MSG.toLowerCase() == 'failed')){
                    vm.ShowAlert(vm.$t('CampaignFalied'),"danger", true, vm.$t("Alert"));
                    vm.closepopup();
                }else{
                    vm.ShowAlert("Something went wrong.","danger", true, vm.$t("Alert"));
                }
                vm.$parent.FetchData();
                vm.isLoading = false;
            });
            if(formFeilds.SendThrough=='MailChimp'){
                var params = `profileId=${formFeilds.SendProfile}`;
                await CampaignDataServices.GetEmailProfile(params).then(response => {
                    if(response.data && response.data.length > 0){
                        jsonObj.mailchimp_api_key = response.data[0].key;
                        jsonObj.audience_id = response.data[0].SecretKey;
                    }
                })
               await vm.GetContactListData(jsonObj);
            }
        },
        async GetContactListData(item){
            var vm = this;
            let type="";
            let ids = "";
            if(item.recipient=="1"){
                type="contacts";
                var contacts = JSON.stringify(item.contact);
                var contactArr =JSON.parse(contacts);
                contactArr.forEach((x,i) => {
                    if(i==0){
                        ids =  x.value;
                    }
                    else{
                        ids +=  "," + x.value;
                    }
                    
                })
            }
            else{
                type= "groups";
                var group = JSON.stringify(item.contact_group);
                var contactGrpArr =JSON.parse(group);
                contactGrpArr.forEach((x,i)=>{
                    if(i==0){
                        ids =  x.value;
                    }
                    else{
                        ids +=  "," + x.value;
                    }
                })
            }
            var jsonObj={
                type: type,
                ids:ids
            }
            var url = JSON.stringify(jsonObj);
            await CampaignDataServices.GetContacts(url).then(async response => {
                var result = response.data.RESULT;
                if(response.data.STATUS=='SUCCESS' && result !=null){
                    var jsonContacts = {
                        contacts : result,
                        mailchimpApiKey: item.mailchimp_api_key,
                        audienceId: item.audience_id
                    }
                    var urlContacts = JSON.stringify(jsonContacts);
                await vm.AddMemberToMailChimp(urlContacts);
                await vm.SaveSendMaliChimpCampaign(item);
                }
            });
            
        },
        async AddMemberToMailChimp(jsonObj){
            await CampaignDataServices.AddMemberToMailChimp(jsonObj).then(response => {
            });
            
        },
        async SaveSendMaliChimpCampaign(item){
            var vm=this;
            var repeatAfter='';
            let batchType = item.batch_schedule.batch_type;
            const time = parseInt(item.batch_schedule.repeat_after);
            if(batchType=='Seconds'){
                repeatAfter = Math.floor(time / 60);  //Convert seconds to minutes
            }
            else if(batchType=='Hours'){
                repeatAfter = Math.floor(time * 60);  //Convert hours to minutes
            }
            else if(batchType=='Days'){
                repeatAfter = Math.floor(time * 24 * 60);  //Convert days to minutes
            }else{
                repeatAfter =time; //The delay, in minutes, between batches.
            }
            var jsonObj ={
                templateId:vm.mailChimpTemplateId,
                fromName:item.from_name,
                subjectLine:item.subject,
                issueTitle:vm.campaignName,
                //previewText:item.preview_text,
                fromEmail:item.from_email,
                sendType: item.send_type,
                scheduleDate:item.schedule.date,
                scheduleTime:item.schedule.time,
                batchStartDate:item.batch_schedule.start_date,
                batchStartTime:item.batch_schedule.start_time,
                batchQuantity:item.batch_schedule.batch_quantity,
                repeatAfter:repeatAfter,
                mailchimpApiKey: item.mailchimp_api_key,
                audienceId: item.audience_id
            }
            var url = JSON.stringify(jsonObj);
            CampaignDataServices.AddCampaignToMailChimp(url).then(response => {
                   this.UpdateMailChimpId(response.data);
            });
        },
        UpdateMailChimpId:async function(id){
            var vm=this;
            var params=`mailChimpid=${id}&campaignId=${this.campaignId}`
            await CampaignDataServices.UpdateMailChimpId(params).then(response => {

            })
        },
        GetScheduledCampaign: async function(){
            var vm = this;
            vm.isLoading = true;
            var url = `campaignId=${vm.campaignId}`;
            await CampaignDataServices.GetScheduledCampaign(url).then(async response => {
                if(response.data.SCHEDULE_DATA){
                    var ScheduleData = response.data.SCHEDULE_DATA[0];
                    vm.FormSchema[3].Data[0].value = ScheduleData.SCHEDULE_DATE;
                    vm.FormSchema[3].Data[1].value = ScheduleData.SCHEDULE_TIME;
                    vm.FormSchema[4].Data[0].value = ScheduleData.BATCH_START_DATE; //vm.$options.filters.formatDateTime(ScheduleData.BATCH_START_DATE);
                    vm.FormSchema[4].Data[1].value = ScheduleData.BATCH_START_TIME;
                    vm.FormSchema[4].Data[2].value = ScheduleData.BATCH_END_TIME;
                    vm.FormSchema[5].Data[0].value = ScheduleData.BATCH_QUANTITY;
                    vm.FormSchema[5].Data[1].value = ScheduleData.BATCH_TYPE;
                    vm.FormSchema[5].Data[2].value = ScheduleData.REPEAT_AFTER;
                    if(ScheduleData.BATCH_DAYS){
                        vm.FormSchema[6].Data[0].value = ScheduleData.BATCH_DAYS.split(',');
                    }else{
                        vm.FormSchema[6].Data[0].value = '';
                    }  
                    if(ScheduleData.SOURCETYPE_NAME){
                        var sourceTypeValue = vm.FormSchema[7].Data[0].config.options.filter(x=>x.value.toLowerCase()==ScheduleData.SOURCETYPE_NAME.toLowerCase())[0].value;
                        vm.FormSchema[7].Data[0].value = sourceTypeValue;
                        await vm.onChangeSendThrough(sourceTypeValue);
                    }
                    vm.FormSchema[7].Data[1].value = ScheduleData.SMTP_DETAIL_ID;
                    vm.ProfileId = ScheduleData.SMTP_DETAIL_ID;
                    vm.FormSchema[8].Data[0].value = ScheduleData.TRACK_EMAIL;
                    vm.FormSchema[9].Data[0].value = ScheduleData.SENDER_EMAIL;
                    vm.FormSchema[9].Data[1].value = ScheduleData.SENDER_NAME;
                    vm.FormSchema[10].Data[0].value = ScheduleData.SUBJECT;
                    //vm.FormSchema[10].Data[1].value = ScheduleData.PreviewText;
                    vm.FormSchema[11].Data[0].value = ScheduleData.RECIPIENT;
                    if(ScheduleData.RECIPIENT=='1'){
                        vm.FormSchema[12].Data[2].visibility=false;
                        vm.FormSchema[12].Data[1].visibility=false;
                        vm.FormSchema[12].Data[0].visibility=true;
                        var contacts =[];
                        var selectedContacts = ScheduleData.CONTACTS.split(',');
                        selectedContacts.forEach(x=>{
                            vm.FormSchema[12].Data[0].config.options.forEach(y=>{                           
                                if(x==y.value){
                                    var obj = {label:y.name,value:y.value};
                                    contacts.push(obj);
                                }
                            })
                        })
                        vm.FormSchema[12].Data[0].value = contacts;
                    }
                    else if(ScheduleData.RECIPIENT=='2'){
                        vm.FormSchema[12].Data[0].visibility=false;
                        vm.FormSchema[12].Data[1].visibility=true;
                        vm.FormSchema[12].Data[2].visibility=false;
                        
                        await vm.GetContactGroup();
                        var contacts =[];
                        var selectedContacts = ScheduleData.CONTACTS.split(',');
                        selectedContacts.forEach(x=>{
                            vm.FormSchema[12].Data[1].config.options.forEach(y=>{                           
                                if(x==y.value){
                                    var obj = {label:y.name,value:y.value};
                                    contacts.push(obj);
                                }
                            })
                        })
                        vm.FormSchema[12].Data[1].value = contacts;
                    }
                    else{
                        vm.FormSchema[12].Data[0].visibility=false;
                        vm.FormSchema[12].Data[1].visibility=false;        
                        vm.FormSchema[12].Data[2].visibility=true;
                        
                        await vm.GetLeadContacts();
                        var contacts =[];
                        var selectedContacts = ScheduleData.CONTACTS.split(',');
                        selectedContacts.forEach(x=>{
                            vm.FormSchema[12].Data[2].config.options.forEach(y=>{                           
                                if(x==y.value){
                                    var obj = {label:y.name,value:y.value};
                                    contacts.push(obj);
                                }
                            })
                        })
                        vm.FormSchema[12].Data[2].value = contacts;
                    }
                    vm.SendType = ScheduleData.SEND_TYPE;
                    vm.campaignType = ScheduleData.CAMPAIGN_TYPE_NAME;
                    vm.onClickSendOrSchedule(vm.SendType);
                }      
                vm.isLoading = false;
            })
        },
        onChangeDate(selectedDate, item, x){
            var vm = this;
            if(item.name=='ScheduleAtDate'){
                vm.FormSchema[3].Data[1].config.minDate=selectedDate;
                vm.FormSchema[3].Data[1].value=selectedDate;
            }
            else if(item.name=='BatchStartDate'){
                vm.FormSchema[4].Data[1].config.minDate=selectedDate;
                //vm.FormSchema[4].Data[2].config.minDate=selectedDate;
                vm.FormSchema[4].Data[1].value=selectedDate;
                vm.FormSchema[4].Data[2].config.minDate=selectedDate;
                vm.FormSchema[4].Data[2].value=selectedDate;
            }
        }
    }
}
</script>
<style>
.shortcuts-menu.man_camp li {
    max-width: 33%;
    padding: 10px;
}
.shortcuts-menu.man_camp li.active a.short-menu-item {
    background: #00b298;
    color: #fff;
    border-color: #00b298;
}
.shortcuts-menu.man_camp li.active a span.left-icon-m {
    background-color: #00a68e;
}
.shortcuts-menu.man_camp li.active a span.left-icon-m i {
    color: #fff;
}
.shortcuts-menu.man_camp li:hover a.short-menu-item {
    background: #00b298 !important;
    color: #fff !important;
    border-color: #00b298 !important;
}
.shortcuts-menu li:hover .short-item-name {
    color: #fff !important;
}
.shortcuts-menu.man_camp li:hover a span.left-icon-m {
    background-color: #00a68e;
}
.shortcuts-menu.man_camp li:hover a span.left-icon-m i {
    color: #fff;
}
.shortcuts-menu.man_camp li a span.left-icon-m i {
    color: #00b298;
    font-size: 32px;
}
.btn_template {
    background: #ebfffc;
    color: #78b9af;
    border: 1px solid #99e6da;
    font-size: 20px;
}
.btn_template:hover {
    background: #00b298;
    color: #fff;
    border: 1px solid #99e6da;
}
.defaultpic_control .btn-select {
    background: #00b298;
    color: #fff;
}
.defaultpic_control .btn-view {
    background: #30b3f1;
    color: #fff;
}
.default_templates .default-tags {
    background: rgba(255, 255, 255, 0.9);
}
.default_templates .default-tags:hover {
    background: rgba(255, 255, 255, 0.9);
}
.default_templates .img-ico.ticket-attachments {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
}
.shortcuts-menu.man_camp.man_view li.active a.short-menu-item {
    background: #e5fcff;
    color: #17a2b8;
    border-color: #17a2b8;
}
.shortcuts-menu.man_camp.man_view li.active a span.left-icon-m {
    background-color: #e5fcff;
}
.shortcuts-menu.man_camp.man_view li.active a span.left-icon-m i {
    color: #17a2b8;
}
.shortcuts-menu.man_camp.man_view li:hover a.short-menu-item {
    background: #e5fcff !important;
    color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}
.shortcuts-menu.man_camp.man_view li:hover a span.left-icon-m {
    background-color: #e5fcff;
}
.shortcuts-menu.man_camp.man_view li:hover a span.left-icon-m i {
    color: #17a2b8;
}
.shortcuts-menu.man_camp.man_view li a span.left-icon-m i {
    color: #737373;
    font-size: 32px;
}
.shortcuts-menu.man_camp.man_view li a span.left-icon-m {
    background-color: #fff;
 }
.shortcuts-menu.man_camp.man_view li:hover .short-item-name {
    color: #17a2b8 !important;
}
.shortcuts-menu.man_camp.man_view li a.short-menu-item {
    background-color: #fff;
}
</style>  
