<template>
  
    <div class="modal-open my-popups" style="z-index:9999998">
        <loader :is-visible="isLoading"></loader>
 <div class="modal d-block">
           <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title">
                      {{$t('MessageDetail')}}
                       </h5>
                   <button  v-on:click="closePopup()" href="javascript:;" class="close"><em class="fa fa-times" aria-hidden="true"></em></button>
               </div>
               <div id="popDetails" class="modal-body" v-if="itemDetail">
         <div class="row">
    <div class="col-md-6 col-lg-4">
        <div class="form-group">
            <label>
                <b>Subject:</b>
                <span v-if='itemDetail.Type== "LOCATION_LOW_INVENTORY_ALERT" || itemDetail.Type == "MIN_STOCK_ALERT" || itemDetail.Type == "JOB_APPLY" || itemDetail.Type == "INTERVIEW_ACCEPTED" || itemDetail.Type == "INTERVIEW_REJECTED" ||itemDetail.Type == "INTERVIEW_RESCHEDULE_REQUESTED"'>
                {{ itemDetail.Title }}
                </span>                    
               <span v-else>
                {{itemDetail.Title + " By" +  " " + itemDetail.PostedBy}}
               </span>
            </label>
        </div>
    </div>
    <div class="col-md-6 col-lg-4">
        <div class="form-group">
            <label>
                <b>Posted By:</b>
                <span v-if='UserType != "CL" && itemDetail.Type != "ASSET_OVERDUE_ASSIGNER" && itemDetail.Type != "ASSET_OVERDUE_USER"'>
    <span v-if='itemDetail.Type == "LOCATION_LOW_INVENTORY_ALERT" || itemDetail.Type == "MIN_STOCK_ALERT" || itemDetail.Type == "JOB_APPLY" || itemDetail.Type == "INTERVIEW_ACCEPTED" || itemDetail.Type == "INTERVIEW_REJECTED" || itemDetail.Type == "INTERVIEW_RESCHEDULE_REQUESTED"'>
{{ $t('AutoAlertBySystem') }}
    </span>
    <span v-else>
{{itemDetail.PostedBy}}
    </span>
                    </span>
            </label>
        </div>
    </div>
    <template v-if='itemDetail.Type == "CRM"'>
        <div class="col-md-6 col-lg-4" v-if='itemDetail.RepliedTo != null'>
            <div class="form-group">
                <label><b>{{$t('LeadOwner')}}:</b> {{itemDetail.RepliedTo}}</label>
            </div>
        </div>
        <div class="col-md-6 col-lg-4" v-else-if="itemDetail.AssignFrom != null">
            <div class="form-group">
                <label><b>{{$t('ContactPerson')}}:</b> {{itemDetail.AssignFrom}}</label>
            </div>
        </div>
    </template>       
    <div class="col-md-6 col-lg-4">
        <div class="form-group">
            <label>
                <b>{{$t('DateAndTime')}}:</b> {{ $options.filters.formatDate(itemDetail.PostedDate) }} at {{ $options.filters.formatTime(itemDetail.PostedDate) }}
            </label>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="form-group">
                <label v-if='itemDetail.Type == "FILE_ADDED" || itemDetail.Type == "FILE_UPDATED" || itemDetail.Type == "FILE_DELETED"'><b>Message:</b> 
                   <span>{{ itemDetail.Message }} {{$t('And')}} {{$t('Visable')}} {{$t('To')}} {{itemDetail.RepliedTo}}</span></label>
                <label v-else><b>Message:</b> <span>{{ itemDetail.Message }}</span></label>
        </div>
    </div>
    <div class="col-12 text-right mt-1">
        <span>
        </span>
    </div>
</div>
</div>
                    
               </div>
            </div>
        </div> 
        </div>
  
</template>
<script>
import DataService from '../../services/DataService'
export default {
props:{
    id:null,
    type:null 
},
data: function () {
    return {
        isLoading:false,
        itemDetail:null,
        UserType:null
    }
},
created: function () {
    this.UserType = this.GetUserInfo.UserType;
    this.GetitemDettail();
},
methods: {
    GetitemDettail:function(){
        var vm = this;
        vm.isLoading=true;
     var url = `id=${vm.id}&type=${vm.type}`
        DataService.GetitemDetail(url).then(response => {
            vm.isLoading=false;
            console.log(response);
         vm.itemDetail= response.data;
       if(response.data==null){
        vm.$emit('close');
       }
        });
    },      
    closePopup: function () {
        this.$emit('close');
    },
}


}


</script>