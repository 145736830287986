<template>
  <div>
    <Multiselect
    v-model="value"
    :options="options"
    class="form-control"    
   @input="onChange"
   @click="onClick"
    @close="onTouch"
    @select="onSelect"
    mode="tags"  
    noOptionsText="Type To Search"
  :close-on-select="false"
  :searchable="true"
  :create-option="true"
   ref="multiselect"
 > 
 </Multiselect>
 <span v-show="isInvalid">Must have at least one value</span>
  </div>
</template>
<script>
export default {
  props: {
    fieldInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isTouched: false,
      value: [],
      selectedHtml:'',
      options:
        this.fieldInfo.select_options == "" || this.fieldInfo.select_options == null
          ? []
          : JSON.parse(this.fieldInfo.select_options).map((item) => {
              const container = {};
              container.label = item.name;
              container.value = item.value;
              return container;
            }),
    };
  },
  computed: {
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
  },
  methods: {
    onChange(value) {       
      this.value = value;
      if (value.indexOf("Reset me!") !== -1) this.value = [];
    },
    onSelect(option) {
      
     if (option === "Disable me!") this.isDisabled = true;
    },
    onClick(){    
      if(this.selectedHtml!=''){
        this.$refs.multiselect.$el.getElementsByClassName("multiselect-tags")[0].innerHTML = "";
       this.$refs.multiselect.$el.getElementsByClassName("multiselect-tags")[0].innerHTML = this.selectedHtml;
      }
    },
    onTouch() {
      this.isTouched = true;
      this.selectedHtml=this.$refs.multiselect.$el.getElementsByClassName("multiselect-tags")[0].innerHTML;
      var len=this.value.length;
      this.$refs.multiselect.$el.getElementsByClassName("multiselect-tags")[0].innerHTML='';
      this.$refs.multiselect.$el.getElementsByClassName("multiselect-tags")[0].innerHTML= `<div class="multiselect-multiple-label">${len} selected</div>`;
    },    
  },
};
</script>
<style>
.multiselect {
  min-width: 200px; border-radius: 0px!important;color: #303030!important;
    border-color: #abb5bf!important; min-height: 34px;font-size: 14px;
}
</style>
