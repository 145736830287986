

<template>
   <div>
    <section class="main-content">
        <loader :is-visible="isLoading"></loader>
      <div id="divMainLeadData" v-cloak>

<div class="row" v-if="callFrom!='Wizard'">
          <div class="col-md-12 padding-t_8">
            <div class="theme-primary partition-full">
              <span class="p-name text-white">Manage Lead</span>
              <span class="float-right">
                <a href="javascript:;" class="p-action-btn text-white" title="Page Tour" @click="setTourGuideSteps(0)">
                  <em class="fa fa-road" aria-hidden="true"></em>
                  <br />Page Tour
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="border p-3">
          <Form  v-slot="{handleSubmit}" ref="form"> 
            <form  @submit.prevent="handleSubmit(onSubmit)">          
            <DynamicForm  :schema="GroupBy" v-on:openaddIndustrypopup="openaddIndustrypopup" :dateformat="dateFormat" :UserType="UserType"
            v-on:openaddRoleTitlepopup="openaddRoleTitlepopup" v-on:openaddLeadSourcepopup="openaddLeadSourcepopup" v-on:FillDropdown="FillDropdown"
            :subModuleCode="subModuleCode" :DependentOption="DependentOption" v-on:setDependentDate="DependentDateObject"  
            v-on:onSearchDDL="onSearch"  v-on:setSelectedValue="setSelectedValue" v-on:onchangechannel="onchangechannel" v-on:onBlur="onBlur" v-on:AssignToggleValues="AssignToggleValues" /> 
            <div class="row flex-row-reverse">
              <div class="col-lg-6  text-right">
                <button type="submit"
                 v-bind:class="{ disabled: false }"                  
                  class="btn btn-success AddLeadDetail_a mr-1"
                  title="Save">
                  <em class="fa fa-save pr-2"></em>Save
              </button>
                <a href="javascript:void(0)" class="btn btn-danger formbtn widthhalf" title="Cancel" @click="onCancel">
                  <em class="fa fa-close pr-2"></em>Cancel
                </a>                
              </div>
              <div class="col-lg-6">
                <div class="py-2">
                 <small class="text-danger">{{$t('MandatoryString')}}</small>
                </div>
              </div>
            </div>
            </form>
            <modaladdindustry v-if="showaddindustryModal" callFrom='lead' @close="showaddindustryModal=false" @updatedata="UpdateSelectJson"></modaladdindustry>
            <modaladdroletitle v-if="showaddroletitleModal" @close="showaddroletitleModal=false" @updatedata="UpdateSelectJson"></modaladdroletitle>  
           <modaladdleadsource v-if="showaddleadsourceModal" callFrom="lead" @close="showaddleadsourceModal=false" @updatedata="UpdateSelectJson"></modaladdleadsource>                  
          </Form>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import LeadDataService from "../../../services/DataService";
import DynamicForm from "../../../components/Common/DynamicForm.vue";
import modaladdindustry from '../../../components/Common/Modal/modaladdindustry.vue'
import modaladdroletitle from '../../../components/Common/Modal/modaladdroletitle.vue'
import modaladdleadsource from '../../../components/Common/Modal/modaladdleadsource.vue'
export default {
    props:{
        callFrom:null
    },
  components: {
    DynamicForm,
    modaladdindustry,
    modaladdroletitle,
    modaladdleadsource
  },
  data() {
    return {
        isLoading:false,
        value: "",
        LeadData: null,   
        GroupBy:[],
        leadId:this.$route.params.id,
        subModuleCode:this.$route.params.subModuleCode,
        moduleName:this.$route.params.moduleName,
        CountryData: null,
        showaddindustryModal: false,
        IndustryItemObject: null,
        RoleTitleItemObject: null,
        showaddroletitleModal: false,
        showaddleadsourceModal: false,
        DependentOption: [{
                useCurrent: false,
                showClear: true,
                showClose: true,
                allowInputToggle: true,
                minDate: new Date(Date.now())
            }
            ],
        LeadSourceItemObject:null,
        userid: null,
        companyId: null,
        username: null,
        dateFormat:null,
        UserType:null
    };
  },
  mounted: function () {},
  created: function () {
    if(this.callFrom=='Wizard'){
    this.leadId=0,
    this.moduleName='CRM',
    this.subModuleCode='CRM_LEADS'
    }
    this.userid = this.GetUserInfo.ID;
    this.companyId = this.GetUserInfo.CompanyId;
    this.username = this.GetUserInfo.Name;
    this.dateFormat=this.GetUserInfo.DateFormat;
    this.UserType=this.GetUserInfo.UserType;
    this.FetchData();    
  },
  methods: {
    DependentDateObject: function (item, date) {
            if (item.name == 'lead_date') {
                var checkDate = new Date();
                if ((typeof date !== 'undefined') && date != '') {
                    checkDate = new Date(Date.parse(date));
                }
                else {
                    checkDate = item.value;
                }
                this.DependentOption[0] = {                   
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    allowInputToggle: true,
                    minDate: checkDate
                }
                $.each(this.GroupBy, function (filedIndex, fieldItem) {
                    $.each(fieldItem.Data, function (index, element) {
                        if (element.name == "expected_close_date") {
                            element.value = '';
                        }
                    });
                });
            }
        },
    FetchData: function () {
      var vm = this;
      vm.isLoading=true;
      const params = `id=${vm.leadId}&moduleName=CRM&subModuleCode=CRM_LEADS&langCode=en`;
        LeadDataService.getFormLayout(params) .then(function (response) {
            vm.isLoading=false;
          if (response.data != "" && response.data != null) {
                        var Compphone = '';
                        var homephone = '';
                        var businessphone = '';
                        var Phones = [];
                        vm.LeadData = response.data.data;
                        if (vm.LeadData != null && vm.LeadData.length > 0) {
                        var groupId = vm.LeadData[0].group_id;
                        vm.LeadData.forEach(item => {
                            groupId = item.group_id; 
                            if (item.name == 'industry_type_id' || item.name == "channel_id" || item.name == "role_title_id") {
                                item.isPlusIcon = true;
                            }
                            if (item.name == 'lead_country' && vm.leadId > 0) {
                                vm.FillDropdown(item, 'Edit');
                            }   
                            if (item.name == 'lead_state' && vm.leadId > 0) {
                                vm.FillDropdown(item, 'Edit');
                            }
                            if (item.name == 'lead_state' || item.name == "lead_city") {
                                item.select_options = null;
                            }
                            if (item.name == 'lead_country') {
                                vm.CountryData = item;
                            }
                            if (item.name == 'external_source_id' && vm.leadId > 0) {
                                vm.FillDropdown(item, 'Account');
                            }
                            if (item.name == 'channel_id' && vm.leadId > 0) {
                                if (item.value != '') {
                                    setTimeout(function () {
                                        vm.onchangechannel(item, null);
                                    }, 500)
                                }
                            }                                                        
                            if (item.name == 'status_id' && item.value == '') {
                                item.value = '1001';
                            }       
                            if (item.name == 'lead_owner_id') {
                                    if (item.value == '') {
                                        item.select_value = vm.username;
                                        item.value ={name: item.select_value, value:  vm.userid, first_value: null, second_value: null};
                                        item.select_options=[{name: item.select_value, value:  vm.userid, first_value: null, second_value: null}]
                                    }
                                    else {
                                       item.select_options =vm.MakeNormalArray(item.select_options);
                                    }
                                };
                            if (item.name == 'lead_date' && item.value == '') {
                                item.value = new Date();
                            }                         
                            if (item.name == "contact_number") {
                                Compphone = item.value;
                                if (Compphone == '' || Compphone == null || Compphone.indexOf("+") == -1)
                                    Phones.push(item.name);
                            }
                            if (item.name == "home_phone") {
                                homephone = item.value;
                                if (homephone == '' || homephone == null || homephone.indexOf("+") == -1)
                                    Phones.push(item.name);
                            }
                            if (item.name == "business_phone") {
                                businessphone = item.value;
                                if (businessphone == '' || businessphone == null || businessphone.indexOf("+") == -1)
                                    Phones.push(item.name);
                            }              
                             if (vm.leadId > 0) {
                                var chkGroup = $.grep(vm.LeadData, function (element, index) {
                                        return element.name == 'existing_client_id';
                                    });
                                if (item.name == 'is_existing_client' && item.value == 0) {
                                    chkGroup[0].dependent_show_type = 0;
                                }
                                else if(item.name == 'is_existing_client' && item.value == 1){
                                    chkGroup[0].dependent_show_type = 1;
                                }
                            }                 
                            var chkGroup = $.grep(vm.GroupBy, function (element, index) {
                                return element.group_id == groupId;
                            });
                            if (chkGroup.length == 0) {
                                var returnedData = $.grep(vm.LeadData, function (element, index) {
                                    return element.group_id == groupId;
                                });          
                                 var returnedCheckBox = $.grep(vm.LeadData, function (element, index) {
                                    return element.dt_code == 'checkbox';
                                });
                                if (returnedCheckBox.length > 0) {
                                    returnedCheckBox.map(function (a) {
                                        a.select_value = [];
                                    })
                                }
                                if (vm.leadId  > 0) {
                                    $.each(returnedData, function (filedIndex, fieldItem) {
                                        if (fieldItem.dt_code == "select") {
                                            try {
                                                fieldItem.value = fieldItem.value.trim();
                                            } catch (ex) { console.log("value is null while trimming") }
                                        }
                                        if (fieldItem.dropdown_type == "custom" && fieldItem.picklist_options == "Lookup") {
                                            fieldItem.select_options = vm.MakeNormalArray(fieldItem.select_options);
                                            fieldItem.select_value = fieldItem.select_options;
                                            if(fieldItem.name=='existing_client_id' && (fieldItem.value=='' || fieldItem.value==null)){
                                                
                                            }
                                            else{
                                                if (fieldItem.select_options != null && fieldItem.select_options.length > 0) {
                                                fieldItem.value = fieldItem.select_options[0];
                                            }
                                            }
                                        }
                                        else if (fieldItem.dt_code == "decimal" && fieldItem.form_field_visibility == "YES" && fieldItem.value > 0) {
                                            fieldItem.value = parseFloat(fieldItem.value, 10).toFixed(2);
                                        }
                                        else if (fieldItem.dt_code == "date") {
                                            fieldItem.value = fieldItem.value;
                                        }
                                    });
                                }                                                          
                                if (returnedData.length > 0) {
                                    var objGroup = {
                                        group_id: groupId,
                                        group_name: returnedData[0].group_name,
                                        group_display_name: returnedData[0].group_display_name,
                                        Data: returnedData
                                    };
                                    vm.GroupBy.push(objGroup);
                                }
                            }
                        });
                        setTimeout(function () {
                            if (Phones.length > 0)
                                $.each(Phones, function (ind, phn) {
                                    
                                   var childs= document.querySelector("#divMainLeadData").childNodes;
                                    $.each(childs, function (indx, elements) {
                                        if (elements.children && elements.children.length > 0) {
                                            console.log(elements);
                                            if (elements.className=='vti__input')
                                                elements.countryCode = 'US';
                                            else if (elements.className=='vti__input')
                                                elements.countryCode = 'US';
                                        }
                                    });
                                });
                            
                            var cntrydata = JSON.parse(vm.CountryData.select_options);
                            if (vm.leadId == 0) {
                                $.each(cntrydata, function (index, item) {
                                    if (item.name == "United States")
                                        vm.CountryData.value = item.value;
                                });
                                vm.FillDropdown(vm.CountryData);
                            }
                        }, 300); 
                        if (vm.leadId > 0) {
                            setTimeout(function () {
                                var selectedName = $('select[data-vv-as="Lead source"] option:selected').text();
                                if (selectedName == "Partner" || selectedName == "Consultant" || selectedName == "Provider" || selectedName == "Customer") {
                                    $("#commission_type").show();
                                    $("#agreement_type").show();
                                    $("#campaign_id").hide();
                                    $.each(items, function (indx, child) {
                                        if (child.name == "external_source_id")
                                            child.value = "";
                                        if (child.name == "campaign_id")
                                            child.value = "";
                                    });
                                }
                                else if (selectedName == "Campaign") {
                                    $("#commission_type").hide();
                                    $("#agreement_type").hide();
                                    $("#campaign_id").show();
                                }
                                else {
                                    $("#commission_type").hide();
                                    $("#agreement_type").hide();
                                    $("#campaign_id").hide();
                                    $("#external_source_id").hide();
                                    $.each(vm.GroupBy, function (indx, child) {
                                        $.each(child.LeadData, function (ind, child1) {
                                            if (child1.name == "external_source_id")
                                                child1.value = "";
                                            if (child1.name == "campaign_id")
                                                child1.value = "";
                                        });
                                    });
                                }
                            }, 500);
                        }
                        else {
                            setTimeout(function () {
                                $("#external_source_id").hide();
                                $("#commission_type").hide();
                                $("#agreement_type").hide();
                                $("#campaign_id").hide();
                            }, 500);
                        }
                        setTimeout(function(){
                            vm.CheckBoxBootstrap();
                            vm.RadioButtonBootstrap();
                            // vm.ShowPopOver();
                        }, 500);
                        var frmFields={}
                        vm.LeadData.forEach((item)=>{
                             if(item.astype!='' && item.form_field_visibility == 'YES'){
                               
                             frmFields[item.name]=item.value;
                             }
                         })
                         //vm.$refs.form.resetForm({values:frmFields});
                }
          }  
        });
    },    
    onSearch: function onSearch(search, objItem) {
            if(search)     {
            this.searchSelectData(search, this, objItem);
            }
        },
        searchSelectData: function (search, vm, objItem) {
            this.ShowSearchData(search, vm, objItem,objItem.value);
        },
        ShowSearchData(search, vm, objItem,selctedValue) {
            if (typeof (selctedValue) == 'undefined') {
                    selctedValue = 0;
                }
            var url = "id=&moduleName="+this.moduleName+"&type="+objItem.field_code;
            if (objItem.dependent_type == 'fill') {
                    url += "&refId=" + selctedValue;
                }
            LeadDataService.getDDLData(url + "&search=" + escape(search))
                .then(response => {
                    var json = [];
                    if(response.data==''){
                       
                    }
                    if (response.data != '') {
                        json = response.data;
                    }                    
                    objItem.select_options = json.DATA;
                        return objItem.select_options;
                    
                });
        },
        setSelectedValue: function (item, val) {
                var vm = this;
                // vm.$refs.form.setFieldTouched(item.name, false); 
                try {
                    if (Array.isArray(val)) {
                        item.value = val[0];
                    }
                    else {
                        if (item.name == 'lead_owner_id' && vm.leadId=='0') {
                            vm.ShowSearchData('', vm, item);
                        }
                        else {
                         
                            if (item.has_dependent > 0) {
                                $.each(vm.GroupBy, $.proxy(function (key, parentItem) {
                                    $.each(parentItem.Data, function (childKey, childItem) {
                                        if (childItem.parent_id == item.custom_field_id && childItem.dependent_type == 'fill') {
                                            vm.ShowSearchData('', vm, childItem, val.value);
                                        }
                                    });
                                }, this));
                               
                            }
                            item.value = val.value;
                        }
                    }
                }
                catch (ex) {
                }
            },
    FillDropdown: async function (item, action) {
        var self=this;
        self.Loading= true;
        var refId = '';
        var typeCode = '';
        if (item.name == 'lead_country') {
            typeCode = 'ACCOUNT_STATE';
            if (action == null) {
                $.each(this.GroupBy, function (index, item) {
                    $.each(item.LeadData, function (index, element) {
                        if (element.name == 'lead_city' || element.name == "lead_state" || element.name == 'lead_zipcode' || element.name == 'lead_address1' || element.name == 'lead_address2') {
                            element.select_options = null;
                            element.value = "";
                        }
                    });
                });
            }
        }
        else if (item.name == 'external_source_id') {
            typeCode = "COMMISSION_AGREEMENT";
            action = 'Account';
        }
        var itemname = item.name;
        refId = item.value;
        if (refId != '') {
            var params = `id=&moduleName=${self.moduleName}&type=${typeCode}&refId=${refId}`;
           await LeadDataService.getDDLData(params).then(response => {
                self.Loading= false;
                var json = [];
                if (response.data != null) {
                    json = response.data.DATA;
                }
                $.each(self.GroupBy, function (index, item) {
                    $.each(item.Data, function (index, element) {
                        if (element.name == 'lead_state' && itemname == 'lead_country') {    
                                            
                            element.select_options = json;
                        }
                        else if (element.name == 'lead_city' && itemname == 'lead_state') {                                    
                            element.select_options = json;
                        }
                        else if (element.name == 'commission_type' && action == 'Account')
                            element.value = json[0].second_value;
                        else if (element.name == 'agreement_type' && action == 'Account')
                            element.value = json[0].first_value;
                    });
                });
                item.name = itemname;
                 
            });
        }
    },
    onchangechannel: function (item, items) {
        var vm=this;
        if (item.name == "channel_id") {
            var selectedName = $('select[name="channel_id"] option:selected').text();
            if (selectedName == "Partner" || selectedName == "Consultant" || selectedName == "Customer" || selectedName == "Provider") {
                $("#commission_type").hide();
                $("#agreement_type").hide();
                $("#campaign_id").hide();
                $("#external_source_id").show();
                $.each(items, function (indx, child) {
                    if (child.name == "external_source_id")
                        child.value = "";
                    if (child.name == "campaign_id")
                        child.value = "";
                });
            }
            else if (selectedName == "Campaign") {
                $("#commission_type").hide();
                $("#agreement_type").hide();
                $("#external_source_id").hide();
                $("#campaign_id").show();
                $.each(items, function (indx, child) {
                    if (child.name == "external_source_id")
                        child.value = "";
                });
            }
            else {
                $.each(items, function (indx, child) {
                    if (child.name == "external_source_id")
                        child.value = "";
                    if (child.name == "campaign_id")
                        child.value = "";
                });
                $("#external_source_id").hide();
                $("#commission_type").hide();
                $("#agreement_type").hide();
                $("#campaign_id").hide();
            }
        }
        else if (item.name == "external_source_id") {
            var selectedName = $('select[name="external_source_id"] option:selected').text();
            if (selectedName != 'Select') {
                $("#commission_type").show();
                $("#agreement_type").show();
            }
            else {
                $("#commission_type").hide();
                $("#agreement_type").hide();
            }
        }
        var typename = $('select[name="channel_id"] option:selected').text();
        var params = `id=&moduleName=${vm.moduleName}&type=ACCOUNTS_LIST&search=${typename}`;
         LeadDataService.getDDLData(params).then(response => {
            var json = [];
            if (response.data != null) {
                json = response.data.DATA;
            }
            $.each(vm.GroupBy, function (index, item) {
                $.each(item.Data, function (index, element) {
                    if (element.name == 'external_source_id') {
                        element.value = '';
                        element.select_options = json;
                    }
                });
            });
        })
    },
    onSubmit: function (values,values1) {
        var vm = this;  
   console.log(vm.$refs.form);
        vm.$refs.form.validate().then((result) => {            
      if (result) {             
                var vm=this;
                var fullJson = vm.LeadData;
                var data = [];
                var validation = [];
                var Isvalidcnt = 0;                        
                $.each(fullJson, function (key, item) {
                    var validationObject = {
                        field_name: item.name,
                        required: item.is_required,
                        length: item.length,
                        type: item.validation_type
                    };                           
                    var fieldValue = (item.name == "lead_id" ? vm.leadId : ((typeof item.value === "undefined") ? '' : item.value));
                    fieldValue=typeof fieldValue === "undefined"?'':fieldValue;
                    if (item.name == "is_existing_client") {
                        fieldValue = (item.value == null) ? 0 : item.value;
                    }
                    if(item.name=='created_by'){
                      fieldValue = vm.userid;
                    }
                    if(item.name=='modified_by'){
                      fieldValue = vm.userid;
                    }
                    if(item.name=='company_id'){
                      fieldValue = vm.companyId;
                    }
                    if (item.name == "contact_number" || item.name == "home_phone" || item.name == "business_phone") {
                       
                    }
                    if (item.dt_code == "checkbox") {
                            fieldValue = String(item.select_value);
                        }
                        if (item.dt_code == "select") {
                            try {
                                fieldValue = (typeof fieldValue === 'object' && fieldValue !== null) ? fieldValue.value : fieldValue
                            } catch (ex) { }
                        }
                    var dataObject = {
                        field_name: item.name,
                        field_value: fieldValue,
                        custom_field_id: item.custom_field_id
                    };
                    data.push(dataObject);
                    validation.push(validationObject);
                });
            if (Isvalidcnt > 0) {
                 
                vm.submited = false;
                return false;
            }
            var postJSON = {
                data: data,
                validation: validation
            };
            var objectPostString = JSON.stringify(postJSON);           
            LeadDataService.SaveLead(objectPostString).then(function (response) {
                if (response.data[0].Code == "1" || response.data[0].Code == 1)
                            {
                                var returnLeadId = response.data[0].Value;
                                if (vm.leadId > 0)
                                {
                                    vm.ShowAlert(vm.$t('UpdatedSuccess',[vm.$t('Lead')]),"success", true, vm.$t("Alert"));
                                }
                                else
                                {
                                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Lead')]), "success", true,  vm.$t("Alert"));
                                }
                              if(vm.callFrom=='Wizard'){
                                 vm.$emit('closewizard');
                              }else{
                                vm.$router.push(`/CRM/ViewLeadDetails/${returnLeadId}/CRM/CRM_LEADS`); 
                              }
                                                       
                            }
                            else
                            {
                                vm.ShowAlert(vm.$t('AddedError',[vm.$t('Lead')]), "failure", true, vm.$t("Alert"));
                            }
        })
      }else{
        console.log(this.$refs.form.errors);
      }   
      });
    },
    onCancel:function(){
        if (this.callFrom == 'Wizard') {
                this.$emit('closewizard');
            } else {
                this.$router.push({ name: 'CRMLead' });
            }
    },
    openaddIndustrypopup: function (item) {      
        this.IndustryItemObject = item;
        this.showaddindustryModal = true;
    },
    openaddRoleTitlepopup: function (item) {
        this.RoleTitleItemObject = item;
        this.showaddroletitleModal = true;
    },
    openaddLeadSourcepopup: function (item) {
        this.LeadSourceItemObject = item;
        this.showaddleadsourceModal = true;
    },
    UpdateSelectJson:function(name,json){
        $.each(this.GroupBy, function (index, item) {
            $.each(item.Data, function (index, element) {
              if (element.name == name) {
                element.select_options = json;
              }
            });
        });
   },
   onBlur(item){
        try {
            if(String(item.value).length>1){
            item.value = Number.parseFloat(item.value).toFixed(2);
            this.$refs.form.setFieldValue(item.name, item.value);
            }
        } catch (ex) {

        }
    },
    AssignToggleValues: function (event, item, array, parentIndex, index) {
                var value = "";
                if (event.target.checked) {
                    var chkItem = $.grep(array, function (element, index) {
                        return (String(element.value).toLowerCase() == 'yes' || String(element.value).toLowerCase() == '1' || String(element.value).toLowerCase() == 'true' || String(element.value).toLowerCase() == 'public' || String(element.value).toLowerCase() == 'male');
                    });
                    if (chkItem != null && chkItem.length > 0) {
                        if (typeof chkItem[0] === 'object' && chkItem[0] !== null) {
                            value = chkItem[0].value;
                        } else {
                            value = chkItem[0];
                        }
                    }
                }
                else {
                    var chkItem = $.grep(array, function (element, index) {
                        return (String(element.value).toLowerCase() == 'no' || String(element.value).toLowerCase() == '0' || String(element.value).toLowerCase() == 'false' || String(element.value).toLowerCase() == 'private' || String(element.value).toLowerCase() == 'female');
                    });
                    if (chkItem != null && chkItem.length > 0) {
                        if (typeof chkItem[0] === 'object' && chkItem[0] !== null) {
                            value = chkItem[0].value;
                        } else {
                            value = chkItem[0];
                        }
                    }
                }
                if (value == "0") {
                    value = 0;
                }
                else if (value == "1") {
                    value = 1;
                }
                item.value = value;
                
                var chkGroup = $.grep(this.LeadData, function (element, index) {
                    return element.name == 'existing_client_id';
                });
                if(value==1){
                    chkGroup[0].dependent_show_type = 1;
                    chkGroup[0].is_required = true;
                }
                else{
                    chkGroup[0].dependent_show_type = 0;
                    chkGroup[0].is_required = false;
                    chkGroup[0].value = "";
                }
                

            },         
  }
};
</script>