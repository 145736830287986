<template>
    <div class="my-popups">
        <loader :is-visible="isLoading"></loader>
        <div class="modal d-block">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header theme-primary partition-full d-flex">
                        <h4 class="modal-title">{{ $t('Pending Post') }}</h4>
                        <button type="button" class="close" v-on:click="Close">
                            <em class="fa fa-times" aria-hidden="true"></em>
                        </button>
                    </div>
                    <div class="modal-body">
                        <tg-list :IsShowAction="false" ModuleName="CRM" SubModuleCode="CRM_CAMPAIGNS" :LegendArray="[]"
                            :RenderRowActionMethod="() => { }" :widgets="[]" IdentityColumn="PostId"
                            :HeaderText="$t('Post')" :listType="['List']" :ListData="PostListData" :HeaderData="Headers"
                            :TotalRecords="TotalRecords" :ListDataCallBackFunction="FetchRecords" :showCheckBox="false"
                            :SortExp="SortExp" :SortBy="SortBy" :NorecordfoundText="$t('NoRecordfound')"
                            v-on:LeftsearchButtonClick="leftCommonSearch" v-on:PagerButtonClick="pagerMethod"
                            v-on:HeaderButtonClick="commonHeaderButtonClick" v-on:SortdataButtonClick="sortdata">
                            <template v-slot:slotdata="{ data }">
                                <template v-if="data.column.COLUMN_NAME == 'status_id'">
                                    <a class="inactive-disabled">
                                        <span v-bind:class="getStatusClassList(data.row.status_name, 'status_id')">
                                            {{ data.row.status_name }}
                                        </span>
                                    </a>
                                </template>
                                <template v-if="data.column.COLUMN_NAME == 'content'">
                                    <a href="javascript:;"
                                        v-on:click="EditCampaign(data.row.campaign_id, data.row.approvalId, data.row.is_draft, data.row.PostId)"
                                        :title="data.row[data.column.COLUMN_NAME]">
                                        {{ TruncateStr(data.row[data.column.COLUMN_NAME] ,150) }}
                                    </a>
                                </template>
                            </template>
                        </tg-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataServices from "@/services/DataService";
export default {
    props: {
        PageId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            PostListData: [],
            noRecords: false,
            SortBy: '',
            SortExp: '',
            PageSize: "10",
            PageNumber: "1",
            TotalRecords: 0,
            CurrentPage: 1,
            TotalPages: 0,
            Headers: [{
                "COLUMN_NAME": "content",
                "DATA_TYPE": "string",
                "SORTABLE": true,
                "VISIBLE": true,
                "DISPLAY_NAME": "content",
                "DISPLAY_ORDER": 1
            },
            {
                "COLUMN_NAME": "is_draft",
                "DATA_TYPE": "string",
                "SORTABLE": true,
                "VISIBLE": true,
                "DISPLAY_NAME": "Is Draft",
                "DISPLAY_ORDER": 1
            },
            {
                "COLUMN_NAME": "status_id",
                "DATA_TYPE": "string",
                "SORTABLE": true,
                "VISIBLE": true,
                "DISPLAY_NAME": "Status",
                "DISPLAY_ORDER": 1
            },
            ],
        }
    },
    created: async function () {
        this.FetchRecords();
    },
    methods: {
        FetchRecords: function () {
            var vueObj = this;
            var url = `PageId=${vueObj.PageId}`;
            DataServices.GetPostListing(url).then(
                function (response) {
                    vueObj.Headers.forEach((column) => {
                        column.settings = null;
                        if (column.COLUMN_NAME == 'status_id') {
                            column.settings = {
                                isInSlot: true
                            }
                        }
                        if (column.COLUMN_NAME == 'content') {
                            column.settings = {
                                isInSlot: true
                            }
                        }
                    });
                    vueObj.PostListData = [];
                    vueObj.TotalPages = Math.ceil(vueObj.TotalRecords / vueObj.PageSize);
                    vueObj.TotalRecords = 0;
                    if (response.data != null && response.data != "") {
                        vueObj.PostListData = response.data.data;
                        if (vueObj.PostListData.length > 0) {
                            vueObj.PostListData.forEach(x => {
                                x.content = vueObj.removeHTMLTags(x.content);
                            })
                        }
                        vueObj.TotalPages = Math.ceil(vueObj.TotalRecords / vueObj.PageSize);
                        vueObj.CurrentPage = vueObj.PageNumber;
                        if (vueObj.PostListData.length > 0) {
                            vueObj.TotalRecords = response.data.data[0].TOTALRECORDS;
                            vueObj.noRecords = false;
                        }
                        else {
                            vueObj.noRecords = true;
                        }
                        setTimeout(function () { vueObj.ResponsiveDataTable('tablelistingdata') }, 500);
                    }
                }
            );
        },
        EditCampaign(Cid, Pid, is_draft, PrevId) {
            if (is_draft == true) {
                var CampaignId = btoa(Cid);
                this.$router.push({ name: "AddSocialMediaPost", params: { id: CampaignId, PrevId: PrevId, reqForm: 'Editcampaign' } });
            } else {
                var PostId = btoa(Pid);
                this.$router.push({ name: "AddSocialMediaPost", params: { id: PostId, reqForm: 'FetchData' } });
            }
        },
        Close: function () {
            this.$parent.ShowUploadedPost = false;
        },
        removeHTMLTags(text) {
            return text.replace(/<[^>]*>/g, '');
        },
        pagerMethod: function (value) {
            this.PageSize = value.PageSize;
            this.PageNumber = value.PageNumber;
            this.FetchRecords();
        },
        getStatusClassList: function (status) {
            var returnClass = '';
            switch (status) {
                case 'Active':
                    returnClass = 'px-2 text-center text-white d-block bg-success';
                    break;
                case 'Inactive':
                    returnClass = 'px-2 text-center text-white d-block bg-danger';
                    break;
                default:
                    returnClass = '';
            }
            return returnClass;
        },
        commonHeaderButtonClick: function (event) {
            switch (event.callbackfunction) {
                case "commonDeleteData":
                    this.commonDeleteData();
                    break;
                case "openaddWorkType":
                    this.openPopupForSetupCrewList(null);
                    break;
            }
        },
        sortdata: function (sortBy) {
            this.SortBy = sortBy;
            var sortExp = 'ASC';
            if (this.SortExp == 'ASC') {
                sortExp = 'DESC';
            }
            this.SortExp = sortExp;
            this.SortBy = sortBy;
            this.SortExp = sortExp;
            this.PageNumber = 1;
            this.GroupStart = this.PageNumber;
            this.GroupEnd = this.PageNumber + 2;
            this.FetchRecords();
        },
        leftCommonSearch: function (value) {
            const searchParams = new URLSearchParams(value.searchConditionJson);
            const entries = searchParams.entries();
            const params = this.paramsToObject(entries);
            if (params.hasOwnProperty('search')) {
                this.search = params['search'];
            }
            else {
                this.search = '';
            }
            this.PageNumber = value.PageNumber;
            this.isPaged = 1;
            if (typeof value.searchCondition != 'undefined') {
                this.searchCondition = value.searchCondition;
            }
            else {
                this.searchCondition = "";
            }
            this.FetchRecords();
        },
        actionButtonClick(callbackfunction, item, index, event) {
            switch (callbackfunction) {
                case 'Edit':
                    this.openPopupForSetupCrewList(item.crew_id)
                    break;
                case 'ClickOnView':
                    this.CrewDeatail(item.crew_id);
                    break;
            }
        },
    }
}
</script>