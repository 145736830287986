<template>

<section id="divMainFormData">
    <loader :is-visible="isLoading"></loader>
    <input type="hidden" id="hdnType" value="" />
<input type="hidden" id="hdnTotalFiles" value="TotalFiles" />
<input type="hidden" id="hdnTotalFilesSize" value="TotalFilesSize" />
<input type="hidden" id="hdnPerFileSize" value="PerFileSize" />
<input type="hidden" id="hdnAllowedFileTypes" value="AllowedFileTypes" />
    <section id="main-content" class="main-content" v-if="productConfig!=null">
        <Form  ref="form">
            <section class="tab padding-t_8" id="tabs">
                <div class="container-fluid tab-style">
                    <input type="hidden" id="AttachmentName" name="AttachmentName" />
                    <div class="tab_container">
                        <div class="responsive-tabs-container accordion-xs accordion-sm">
                            <div class="tab-content">
                                <div class="col-md-12 p-0">
                                 <div class="tab-content fsmtabcontrolMobileView" id="myTabContent">
                                        <div class="tab-pane call-detail  show active border-0 p-0" id="tabs-1"
                                            role="tabpanel" aria-labelledby="CreateProduct-tab">
                                            <div class="container-fluid border pt-2">

                                                <div class="col-lg-12 mt-3 p-0">
                                                    <div class="heading-border mb-2">
                                                        <h5 class="h5">
                                                            <span id="TabHeading1">{{ $t('CreateItem') }}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="comapny-setup">
                                                    <div class="row">
                                                        <div class="col-lg-3">
                                                            <div class="form-group">
                                                                <label class="control-label"
                                                                    for="AssetTypeId">Product Type<span class="text-danger">*</span></label>                                                                
                                                                    <div class="input-group">
                                                                        <Field   class="w-100" name="Product Type" :rules="{'required':true}"   v-slot="{errors}">
                                                                        <select  id="assetTypeId" name="assetTypeId" @change="OnChangeAssetType"    v-model="productModel.assetTypeId"                                                                    
                                                                            v-bind:class="{ 'form-control': true, 'disabled_grey': assetCatalogueId > 0,'is-invalid': errors[0] }">
                                                                            <option value>Select</option>
                                                                            <option v-for="(item,itemIndex) in productConfig.IsEnableDropDownList" :key="itemIndex" :value="item.value"
                                                                            :data-first="item.firstAttribute" :data-second="item.secondAttribute" :data-third="item.thirdAttribute"
                                                                            :data-fourth="item.fourthAttribute" :data-fifth="item.fifthAttribute">{{item.text}}</option>
                                                                        </select>
                                                                        <span  v-if="errors[0]" name="assetTypeId" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                                        <div class="input-group-append"
                                                                            style="cursor:pointer; display: none;">
                                                                            <span
                                                                                class="input-group-text border-0 bg-transparent p-0 pl-2">
                                                                                <a href="javascript:void(0);"
                                                                                    class="round-icon-small btn-success-light">
                                                                                    <em class="fa fa-plus pt-7" id="AddProducttype" :title="$t('Add')+' '+ $t('ProductType')"></em>
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <div class="form-group">
                                                                <label class="control-label" for="Name">Product Name<span class="text-danger">*</span></label>
                                                                    <Field class="w-100" :rules="{'required':true}" name="Product Name" v-slot="{errors}">
                                                                        <input  v-bind:class="{'form-control' : true,'is-invalid': errors[0]}" id="Name" maxlength="100" 
                                                                        name="Name" type="text" v-model="productModel.name">
                                                                        <span  v-if="errors[0]" name="Name" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <div class="form-group">
                                                                <label class="control-label" for="BarcodeTypeId">Barcode Type<span class="text-danger">*</span></label>
                                                                    <Field class="w-100" :rules="{'required':true}" name="Barcode Type"  v-slot="{errors}">
                                                                <select  v-bind:class="{'form-control' : true,'is-invalid': errors[0]}" id="BarcodeTypeId"
                                                                    name="BarcodeTypeId" v-model="productModel.barcodeTypeId">
                                                                    <option value>Select</option>
                                                                    <option v-for="(item,index) in productConfig.GetBarcodeType"
                                                                        :key="index" :value="item.value">{{ item.text}}</option>
                                                                </select>
                                                                <span v-if="errors[0]"  name="BarcodeTypeId" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <div class="form-group">
                                                                <label class="control-label " for="StatusId">Status</label>
                                                                    <Field class="w-100" name="StatusId" :rules="{'required':false}" label="Status"  v-slot="{errors}">
                                                                <select  v-bind:class="{'form-control' : true,'is-invalid': errors[0]}" id="StatusId"
                                                                    name="StatusId"  v-model="productModel.statusId">
                                                                    <option value>Select</option>
                                                                    <option v-for="(item,index1) in productConfig.GetStatusDDLData"
                                                                        :key="index1" :value="item.statuS_ID">{{ item.status }}</option>
                                                                </select>
                                                                <span  v-if="errors[0]" name="StatusId" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-3">

                                                            <div class="form-group">
                                                                <label class="control-label " for="ItemCode">Product
                                                                    Code</label>
                                                                <a href="javascript:;"
                                                                    data-toggle="popoveruserguide"
                                                                    data-content="Please enter the product code. Else six digit item code will be generated by system.">
                                                                    <em class="fa fa-question-circle-o text-popover"
                                                                        style="font-size: 14px;"></em>
                                                                </a>
                                                                    <div class="input-group mb-3 tg-w-50"
                                                                        v-if="assetCatalogueId > 0">
                                                                        <div class="input-group-append">
                                                                            <input type="text" :value="typeCode"
                                                                                id="txtitemtypecode" disabled
                                                                                class="form-control disabled_grey"
                                                                                style="width:110px" />
                                                                        </div>
                                                                        <Field class="w-100" :rules="{'required':false}" name="Product Code" v-slot="{errors}">
                                                                        <input  class="form-control" id="ItemCode" maxlength="12" name="ItemCode" v-model="productModel.itemCode" type="text">
                                                                            <span v-if="errors[0]"  name="ItemCode" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                                    </div>
                                                                    <div class="input-group mb-3 tg-w-50" v-else>
                                                                        <div class="input-group-append">
                                                                            <input type="text" :value="typeCode"
                                                                                id="txtitemtypecode" disabled
                                                                                class="form-control disabled_grey"
                                                                                style="width:110px" />
                                                                        </div>
                                                                        <Field class="w-100"  :rules="{'required':false}" name="Product Code" v-slot="{errors}">
                                                                        <input   class="form-control" id="ItemCode"
                                                                            maxlength="6" name="ItemCode"
                                                                            type="text" v-model="productModel.itemCode">
                                                                            <span v-if="errors[0]"  name="ItemCode" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 ShowUnit">
                                                            <div class="form-group">
                                                                <label>UOM:</label>
                                                                <Field class="w-100" :rules="{'required':false}" name="UOM"  v-slot="{errors}">
                                                                <select   class="form-control " id="Unit" name="Unit" v-model="productModel.unit">
                                                                    <option value>Select</option>
                                                                    <option v-for="(item,index2) in productConfig.GetUnits" :key="index2"
                                                                        :value="item.value">{{ item.text }}</option>
                                                                </select>
                                                                <span v-if="errors[0]"  name="Unit" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <div class="form-group">
                                                                <label class="control-label "
                                                                    for="DisplayImage">Display Image</label>
                                                                <a href="javascript:;"
                                                                    data-toggle="popoveruserguide"
                                                                    :data-content="`${$t('MaximumPerFileSize')}: ${productConfig.PerFileSize} MB<br>${$t('AllowedFileTypes')}:${productConfig.AllowedFileTypes}`"
                                                                    data-original-title="" title="">
                                                                    <em class="fa fa-question-circle-o text-popover"
                                                                        style="font-size: 14px;"></em>
                                                                </a>
                                                                <div class="custom-file">
                                                                    <input type="file" class="custom-file-inputs" @change="setFile($event)" 
                                                                        size="4" name="FileSrc" id="flFileDisplay"
                                                                        tabindex="7" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <label></label>
                                                            <div class="form-group d-flex align-items-center">
                                                                <span class="d-inline-block mr-2">

                                                                    <div id="divimagedisplayImage"
                                                                        class="noimage-200"
                                                                        v-if="isBlank(AttachmentPath)">
                                                                        <img class="img-fluid mb-2"
                                                                            src="/Content/images/imagenotavailable.jpg"
                                                                            alt="Logo" width="400" height="200" />
                                                                    </div>
                                                                    <div v-else id="divimagedisplayImage"
                                                                        class="noimage-200">
                                                                        <img class="dark-border" alt=""
                                                                            :src="AttachmentPath" />
                                                                    </div>
                                                                    <input type="hidden" id="hdn_is_display_image"
                                                                        value="No" name="Is_DisplayImage" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-3 mb-3">
                                                            <div class="heading-border">
                                                                <h5 class="h5"><span>Tags:</span>
                                                                <small class="float-right">
                                                                    <a @click="AddTag" aria-hidden="true" >
                                                                        <em :title="$t('Add')" class="fa fa-plus pr-1"></em>{{$t('AddNew')}}
                                                                    </a>
                                                                </small>
                                                                </h5>
                                                            </div>
                                                            <div id="tagList" class="d-flex mt-2" style="flex-wrap: wrap;">
                                                                <div v-for="(item,index6) in tagData"  :key="index6">
                                                                    <a class="badge badge-secondary rounded-pill py-2 px-2 tag mr-1 mt-1" style="text-transform: capitalize;" @click="removeTag(item.tagId)" 
                                                                    :value="item.tagId"> {{item.tagname}} <em class="fa fa-times" aria-hidden="true"></em></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label class="control-label"
                                                                    for="Description">Description</label>
                                                                    <Field class="w-100" name="Description" :rules="{'required':false}" v-slot="{errors}">
                                                                    <textarea  autocomplete="off" class="form-control "
                                                                    cols="10" id="Description" maxlength="250"
                                                                    name="Description" rows="5" v-model="productModel.description"
                                                                    style="min-height:100px;"></textarea>
                                                                    <span  v-if="errors[0]" name="Description" class="invalid-feedback d-block">{{errors[0]}}</span>
                                                                    </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-3 float-left">
                                <div class="ShowUnit" style="display:none">

                                </div>
                            </div>
                            <div class="col-lg-6 text-right mt-3 float-left">
                                <a href="javascript:;" id="btnSave" class="btn btn-success " @click="OnSubmit" :title="$t('Save')" 
                                name="btnSave"><em class="fa fa-save pr-2"></em>{{ $t('Save') }} </a>&nbsp;
                                <a href="javascript:void(0)" class="btn btn-danger formbtn widthhalf" title="Cancel" @click="onCancel">
                                    <em class="fa fa-close pr-2"></em>Cancel
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Form>
    </section>
    <modalproducttag v-if="showTagModal" @close="showTagModal=false"></modalproducttag>
</section>

</template>
<script>
import DataService from "../../../services/DataService";
import modalproducttag from '../../../components/Common/Modal/modalproducttag.vue';
import $ from "jquery";
export default {
    props:{
     RequestType:null,
     callFrom:null
    },
    components:{
        modalproducttag
    },
    data() {
    return {
        reqFromId:'0',
        productConfig:null,      
        assetCatalogueId:0,
        selectNvarcharfieldList: [],
        TotalTermsConditionFilesCount: 0, 
        typeCode: '',          
        AttachmentPath: '',
        file:null,
        showTagModal:false,
        tagData:[],
        isLoading:false,
        productModel:{
            assetCatalogueId:0,
            assetTypeId:'',
            name:'',
            barcodeTypeId:'',
            statusId:'',
            itemCode:'',
            unit:'',
            description:'',
            assetTagIds:''
        }
    }
},
created: function () {
this.reqFromId=this.callFrom=='Wizard'?'0':this.$route.params.reqFromId;
    if(this.callFrom=='Wizard'){
    this.assetCatalogueId=0,
    this.moduleName='CRM',
    this.subModuleCode='CRM_ITEMS'
    }

    if(this.callFrom!='Wizard' && this.$route.params.id!='0'&& !this.RequestType){
        this.assetCatalogueId=this.$route.params.id;
        this.productModel.assetCatalogueId=this.$route.params.id;
        this.GetProduct(this.$route.params.id);
    }else{
        this.GetProductConfig();
    }
},
updated(){
    var vm=this;
    if(vm.productConfig && vm.productConfig.hasOwnProperty('assetCatalogue') && vm.productConfig.assetCatalogue){ 
    vm.productModel.assetTypeId=String(vm.productConfig.assetCatalogue.assetTypeId);
    vm.productModel.name= vm.productConfig.assetCatalogue.name;
    vm.productModel.barcodeTypeId=String(vm.productConfig.assetCatalogue.barcodeTypeId);
    vm.productModel.statusId=vm.productConfig.assetCatalogue.statusId;
    vm.productModel.itemCode=vm.productConfig.assetCatalogue.itemCode;
    vm.productModel.unit=vm.productConfig.assetCatalogue.unit;
    vm.productModel.description=vm.productConfig.assetCatalogue.description;  
    // let assetTags = JSON.parse(vm.productConfig.assetCatalogue.assetTagIds);
    // $.each(assetTags, function(i,item){
    //     vm.tagData.push({tagId:item.TAG_ID,tagname:item.TAG_NAME});
    // })     
    // var frmFields = {}
    // frmFields['assetTypeId'] =String(vm.productConfig.assetCatalogue.assetTypeId);
    // frmFields['Name'] = vm.productConfig.assetCatalogue.name;
    // frmFields['BarcodeTypeId'] =String(vm.productConfig.assetCatalogue.barcodeTypeId);
    // frmFields['StatusId'] = vm.productConfig.assetCatalogue.statusId;
    // frmFields['ItemCode'] = vm.productConfig.assetCatalogue.itemCode;
    // frmFields['Unit'] = vm.productConfig.assetCatalogue.unit;
    // frmFields['Description'] = vm.productConfig.assetCatalogue.description;
    // vm.$refs.form.resetForm({ values: frmFields });  
    }
},
methods:{
    AddTag:function(){
        var vm = this;
        vm.showTagModal=true;
    },
    removeTag: function(tagId) {
        var vm = this;
         vm.confirmR(vm.$t('ConfirmRemoveTag'), true, vm.$t("Delete"),
             function () {
                var tagIndex = vm.tagData.findIndex(x=>x.tagId===tagId);
                if(tagIndex > -1 ){
                    vm.tagData.splice(tagIndex, 1);
                }
           });
    },
    setFile:function(e){
    this.file=e.target.files[0];
    },
    GetProductConfig(){
        var vm=this;
        vm.isLoading=true;
        DataService.GetProductConfig('HitFrom=CRM_ACCOUNTS').then(function (response) {
            vm.isLoading=false;
          vm.productConfig=response.data;
        })
    },
    OnChangeAssetType(event){
        var vm=this;
        vm.typeCode=$("#assetTypeId option:selected").attr('data-second');
        if ($("#assetTypeId option:selected").attr('data-first') == "1") {
        vm.ShowAlert(vm.$t("AgreeOneImageRequired"), "warning", true, vm.$t("Alert"));
        if ($(this).find("option:selected").attr("data-second") == "0") {
                $("#CreateUniqueNameListing").attr("checked", "checked").next("label").removeClass("off").addClass("check");
            }
            else {
                $("#CreateUniqueNameListing").removeAttr("checked").next("label").removeClass("check").addClass("off");
            }
         }
    },
    onCancel:function(){
        var vm=this;
        if(vm.callFrom=='Wizard'){
         vm.$emit('closewizard');
        }else
        if(vm.RequestType && vm.RequestType=='popup'){
            vm.$emit('close');
        }else if(vm.reqFromId!='0'){
            vm.$router.push({name:'Accounts Details',params:{
                    id:vm.reqFromId,
                    moduleName:"CRM",
                    subModuleCode:'CRM_ACCOUNTS',
                    AccountCode:'NEW_PROVIDER'
                }}); 
        } 
        else{
            vm.$router.push({name:'Product'}); 
        }
    },
    OnSubmit() {
        var vm = this;
        // vm.$refs.form.setFieldTouched('assetTypeId', true); 
        // vm.$refs.form.setFieldTouched('Name', true); 
        // vm.$refs.form.setFieldTouched('BarcodeTypeId', true); 
        const formdata = new FormData();
        vm.productModel.reqFrom = 'CRM_ACCOUNTS';
        let tagIds = vm.tagData.map((item)=>item.tagId);
        vm.productModel.assetTagIds=tagIds.toString();
        if(vm.productModel.statusId==''){
            vm.productModel.statusId=1001;
        }
        formdata.append("postString", JSON.stringify(vm.productModel));
        formdata.append("file", vm.file); 
        vm.$refs.form.validate().then((result) => {
        if (result) {
            vm.isLoading = true;
            DataService.AddProduct(formdata).then(function (response) {
                vm.isLoading = false;
                if (response.data.msg == "add") {
                    if (vm.callFrom!='Wizard' && vm.$route.params.id > 0 && !vm.RequestType) {
                        vm.ShowAlert(vm.$t('UpdatedSuccess', [vm.$t('Product')]), "success", true, vm.$t("Alert"));
                    }
                    else {
                        vm.ShowAlert(vm.$t('AddedSuccess', [vm.$t('Product')]), "success", true, vm.$t("Alert"));
                    }
                    vm.onCancel();
                }
                else {
                    vm.ShowAlert(vm.$t('AddedError', [vm.$t('Product')]), "failure", true, vm.$t("Alert"));
                }
                vm.submited = false;
            });
        }else{
            console.log(this.$refs.form.errors);
        }
       });           
    },
    GetProduct(id){
        var vm=this;
        vm.isLoading=true;
        DataService.GetProduct('id='+id+'&HitFrom=CRM_ACCOUNTS').then(function (response) { 
            vm.isLoading=false;       
          vm.productConfig=response.data;
          let assetTags = JSON.parse(vm.productConfig.assetCatalogue.assetTagIds);
            $.each(assetTags, function(i,item){
                vm.tagData.push({tagId:item.TAG_ID,tagname:item.TAG_NAME});
            })     
          console.log(vm.productConfig);
        })
    }
            
        },
}

</script>