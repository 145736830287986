<template>
   <div class="modal d-block my-popups dialog">
     <loader :is-visible="isLoading"></loader>
     <div class="modal-dialog">
       <div class="modal-content">
         <div class="modal-header">
               <slot name="headerForModal">
                 <h5 class="modal-title">{{ $t('Bookmark') }}</h5>
               </slot>
               <button type="button" v-on:click="$emit('closebookmark')" href="javascript:;" class="close">
                 <em class="fa fa-times"></em>
               </button>
             </div> 
         <div class="modal-body">
               <div class="row form-head" style="display:none;">
    <span class="user-guide">
        <a class="ancuserguide" data-toggle="collapse" href="#collapseReply" role="button" aria-expanded="false" aria-controls="collapseExample">@Resources.UserGuide</a>
        <div id="collapseReply" class="divancuserguide collapse">
            <div id="scrollbarreplyuserguide" class="custom-scrollbar-js">
                <div class="con">
                    <!-- @Html.Raw(Resources.UserGuid_Save_Bookmark) -->
                </div>
            </div>
        </div>

    </span>
               </div>
    <div class="form-panel mt-0 p-0">  
        <div class="form-content">
            <Form id="frmSaveBookmark" ref="form" v-slot="{ valid,errors}">                      
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                     <label>{{ $t('Url') }}:<span class="text-danger">*</span></label>
                    <Field name="Url" class="w-100" :rules="{ 'required': false }" v-slot="{ errors }">
                    <input  name="Url" type="text" v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                     v-model="url" disabled />
                    <span name="Url" class="invalid-feedback">{{ errors[0] }}</span>
                   </Field>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="form-group">
                            <label>{{ $t('Title') }}:<span class="text-danger">*</span></label>
                            <Field name="Title" class="w-100" :rules="{ 'required': true, 'max': 200 }" v-slot="{ errors }">
                    <input  name="Title" type="text" v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }"
                     v-model="title" />
                    <span name="Title" class="invalid-feedback">{{ errors[0] }}</span>
                   </Field>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
        <div class="row flex-row-reverse mb-3">
            <div class="col-lg-6 text-right">
                <a :title="$t('Save')" href="javascript:;" class="btn btn-success formbtn mr-1" id="aSave" @click="SaveBookMark(valid,errors)"><i class="fa fa-save pr-2"></i>{{ $t('Save') }}</a>
                <a title="$t('Cancel')" href="javascript:;" class="btn btn-danger formbtn" id="aBMCancel" @click="vm.$emit('closebookmark')"><i class="fa fa-close pr-2"></i>{{ $t('Cancel') }}</a>
            </div>
            <div class="col-lg-6">
                <div class="py-2">
                    <!-- @HtmlHelpers.MandatoryStringFull("R") -->
                </div>
            </div>
        </div>
        <!-- @{
        Html.RenderAction("Bookmark", "MyProfile", new { showEdit = true, from = ViewBag.from });
    } -->
    </div>
    </div>
       </div>
</div>
    </div>
</template>
<script>
import DataService from '../../services/DataService';
export default{
    data(){
        return{
            isLoading: false,
            url:location.href,
            title:''
        }
    },
    methods:{
        SaveBookMark(valid,errors){
            var vm=this;
            vm.$refs.form.validate().then((result) => {    
                if (result) {
            vm.isLoading=true;
            var postJSON = {
                BookmarkId: 0,
                Title: vm.title,
                Url:vm.url
            };
            var objectPostString = JSON.stringify(postJSON);    
            DataService.SaveBookMark(objectPostString).then((response)=>{
                vm.isLoading=false;
                if(response.data==1){
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('BookMark')]),"success", true, vm.$t("Alert"));
                    vm.$emit('closebookmark');
                }else if(response.data== -2){
                    vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('BookMark')]),"failure", true, vm.$t("Alert"));
                }else{
                    vm.ShowAlert(vm.$t('AddedError',[vm.$t('BookMark')]),"failure", true, vm.$t("Alert"));
                }
           
        })
                }
    })
        }
}
}
</script>