<script>
export default {
  props: {
     fieldInfo: {
                type: Object,
                required: true
            }            
  },
  data: function () {
    return {
      checkOptions: [],
    };
  },
  watch: {
    fieldInfo:function(value){
      var self=this;
      var optionslist= value.picklist_options
    if (optionslist != null && optionslist!=undefined && optionslist!='') {
      var splitted = optionslist.split(',');
      for (let index = 0; index < splitted.length; index++) {
        const element = splitted[index];
        self.checkOptions.push({
            name:element.split('|')[0],
          value: element.split('|')[1],
        });
      }
      if(self.checkOptions.length==0){
        self.checkOptions=optionslist;
      }
      console.log(self.checkOptions);
    }
    }     
      },
 
}
</script>
<template>
  <Field :name="GetFormSchemaFieldName(fieldInfo.label,fieldInfo.display_name)" :rules="{'required':fieldInfo.is_required?true:false}"
     v-slot="{errors}">
    <label v-for="(check,checkIndex) in checkOptions" :key="checkIndex+'_'+check.value">
      <input type="checkbox" :name="check.name" :value="check.value" /> {{check.name}}
    </label>
    <span v-if="errors[0]"  class="invalid-feedback d-block">{{errors[0]}}</span>
  </Field>
</template>