<template>     
     <div class="modal-open my-popups">
       <div class="modal d-block">
         <div class="modal-dialog modal-dialog-centered">
           <div class="modal-content">
                <div class="modal-header theme-primary partition-full">
                    <h4 class="modal-title">Add Designation</h4>
                    <button type="button" class="close" v-on:click="ClosedDesignation($event)">
                         <em class="fa fa-times" aria-hidden="true"></em>
                    </button>
                </div> 
                <div class="modal-body">
                    <Form ref="form" v-slot="{ valid,errors}">
                        <div class="col-md-12 p-0">            
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">                          
                                            <label>Designation Name:<span class="text-danger">*</span></label>                                
                                              <Field name="DesignationName" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                                <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtDesignationName" type="text"  />                                           
                                                <span v-if="errors[0]" name="DesignationName" class="invalid-feedback">{{errors[0]}}</span>                                
                                             </Field>     
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Status:<span class="text-danger">*</span></label>                                      
                                        <Field :rules="{ 'required': true }" class="w-100" name="status" v-slot="{ errors }">      
                                            <select name="status" v-model="STATUS_ID" v-bind:class="{ 'form-control w-100': true }">                                               
                                                <option v-for="(item, itemIndex) in DDLList" :key="itemIndex" :value="item.STATUS_ID">{{ item.STATUS }}</option>
                                            </select>
                                            <span v-if="errors[0]" name="status" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                        </Field>  
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Description:</label>
                                        <Field :rules="{'required':false}" name="Description">
                                            <textarea  name="txtDescription" class="form-control"
                                                    v-model="txtDescription" style="resize:none" cols="10" rows="5">
                                            </textarea>
                                        </Field>                           
                                    </div>
                                </div>
                            </div>           
                        </div>
                        <div class="col-md-12 p-0">
                            <div class="row flex-row-reverse">
                                <div class="col-lg-6 text-right">
                                    <a href="javascript:;" v-on:click="SavedDesignation(valid,errors);" title="Save" class="btn btn-success save-btn mr-1">
                                        <em class="fa fa-save pr-2"></em>{{$t('Save')}}
                                    </a>
                                    <a href="javascript:;" v-on:click="ClosedDesignation(valid,errors)" class="btn btn-danger  delete-btn">
                                        <em class="fa fa-times"></em> {{$t('Cancel')}}
                                    </a>
                                </div>
                                <div class="col-lg-6">
                                        <div class="py-2"><small class="text-danger">{{$t('MandatoryString')}}</small></div>
                                </div>
                            </div>
                        </div>                
                    </Form>
                </div>              
           </div>
         </div>
       </div>
     </div>   
 </template>

 <script>
import DataService from '../../../services/DataService';
export default{
    data(){
        return{
            isLoading:false,
            txtDesignationName:'',
            txtDescription:'',
            STATUS_ID:'1001',
            DDLList:[]
        }
    },
    created: function () { 
        this.GetStatusList("Designation"); 
    },
    methods:{
        SavedDesignation:function(){               
            var vm=this;
            var params=`name=${this.txtDesignationName}&type=designation&id=${this.STATUS_ID}&refId=` 
            vm.$refs.form.validate().then((result) => {
                if(result){
                    DataService.CheckDuplicate(params).then(response=>{             
                if(response.data==0){
                    vm.SavedDesignationn();
                }
                else{
                    vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('CADesignationName')]), "failure", true,  vm.$t("Alert")); 
                }
            })
                }
            });           
            
        },
        ClosedDesignation:function(item){       
            this.$emit('ClosedDesignation',item);
        },
        GetStatusList:function(ths){
            DataService.DesignationGetStatusList(ths).then(response=>{              
                this.DDLList=response.data;
            });
        },
        SavedDesignationn:function(){         
            var vm=this;
            var params=`DesignationName=${this.txtDesignationName}&DesignationDescription=${this.txtDescription}&status_id=${this.STATUS_ID}`;           
            DataService.SavedDesignationn(params).then(response=>{             
                if(response.data==1)
                {
                    vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('CADesignationName')]), "success", true,  vm.$t("Alert")); 
                    vm.ClosedDesignation();
                }
            });
        }
    }
}
</script>