<template>     
    <div class="modal-open my-popups">
      <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
               <div class="modal-header theme-primary partition-full">
                   <h4 class="modal-title">Add Shift</h4>
                   <button type="button" class="close" v-on:click="ClosedDesignation($event)">
                        <em class="fa fa-times" aria-hidden="true"></em>
                   </button>
               </div> 
               <div class="modal-body">
                   <Form ref="ShiftForm" v-slot="{ valid,errors}">
                       <div class="col-md-12 p-0">            
                           <div class="row">
                               <div class="col-lg-6">
                                   <div class="form-group">                          
                                        <label>Shift Name:<span class="text-danger">*</span></label>                                
                                        <Field name="ShiftName" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtShiftName" type="text"  />                                           
                                           <span v-if="errors[0]" name="ShiftName" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>     
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>Start Time:<span class="text-danger">*</span></label>                                
                                        <Field name="StartTime" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtStartTime" type="time"  />                                           
                                           <span v-if="errors[0]" name="StartTime" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>End Time:<span class="text-danger">*</span></label>                                
                                        <Field name="EndTime" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtEndTime" type="time"  />                                           
                                           <span v-if="errors[0]" name="EndTime" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>Shift Hours:<span class="text-danger">*</span></label>                                
                                        <Field name="ShiftHours" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtShiftHours" type="text"  />                                           
                                           <span v-if="errors[0]" name="ShiftHours" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>Min Hours:<span class="text-danger">*</span></label>                                
                                        <Field name="MinHours" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtMinHours" type="text"  />                                           
                                           <span v-if="errors[0]" name="MinHours" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>Shift Rate:<span class="text-danger">*</span></label>                                
                                        <Field name="ShiftRate" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtShiftRate" type="text"  />                                           
                                           <span v-if="errors[0]" name="ShiftRate" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                   <div class="form-group">                                                                            
                                       <label>Over Time Rate:<span class="text-danger">*</span></label>                                
                                        <Field name="OverTime" :rules="{'required':true}" label="Trip Name" v-slot="{errors}">
                                           <input v-bind:class="{ 'form-control': true, 'is-invalid':errors[0] }" v-model="txtOverTime" type="text"  />                                           
                                           <span v-if="errors[0]" name="OverTime" class="invalid-feedback">{{errors[0]}}</span>                                
                                        </Field>  
                                   </div>
                               </div>
                               <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Location:<span class="text-danger">*</span></label>                                      
                                        <Field :rules="{ 'required': true }" class="w-100" name="Location" v-slot="{ errors }">      
                                            <select v-model="txtLocation" v-bind:class="{ 'form-control w-100': true }">                                               
                                                <option v-for="(item, itemIndex) in LocationList" :key="itemIndex" :value="item.COMPANY_LOCATION_ID">{{ item.LOCATION_CITY }}</option>
                                            </select>
                                            <span v-if="errors[0]" name="Location" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                        </Field>                                      
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Status:<span class="text-danger">*</span></label>                                      
                                        <Field :rules="{ 'required': true }" class="w-100" name="status" v-slot="{ errors }">      
                                            <select v-model="txtStatus" v-bind:class="{ 'form-control w-100': true }">                                               
                                                <option v-for="(item, itemIndex) in DDLList" :key="itemIndex" :value="item.STATUS_ID">{{ item.STATUS }}</option>
                                            </select>
                                            <span v-if="errors[0]" name="status" class="invalid-feedback d-block">{{ errors[0] }}</span> 
                                        </Field>  
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Shift Type (Is Flexible):</label>
                                        <div class="form-group">
                                            <label class="switch">
                                                <Field name="ShiftType"
                                                    :rules="{ 'required': false }" slim>
                                                    <input
                                                        class="form-check-input rdoticketfor custom-control-input dynamic"
                                                        type="checkbox"
                                                        name="ShiftType"
                                                        v-bind:model="txtShiftType"
                                                        v-bind:checked="txtShiftType == true">
                                                </Field>
                                                <span class="slider round">
                                                    <span class="slider-yes" value="1">Yes</span>
                                                    <span class="slider-no" value="0">No</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                               <div class="col-lg-12">
                                   <div class="form-group">
                                       <label>Shift Description:</label>
                                       <Field :rules="{'required':false}" name="Description">
                                           <textarea  class="form-control"
                                                   v-model="txtDescription" style="resize:none" cols="10" rows="5">
                                           </textarea>
                                       </Field>                           
                                   </div>
                               </div>
                           </div>           
                       </div>
                       <div class="col-md-12 p-0">
                           <div class="row flex-row-reverse">
                               <div class="col-lg-6 text-right">
                                   <a href="javascript:;" v-on:click="SavedShift(valid,errors);" title="Save" class="btn btn-success save-btn mr-1">
                                       <em class="fa fa-save pr-2"></em>{{$t('Save')}}
                                   </a>
                                   <a href="javascript:;" v-on:click="ClosedDesignation($event)" class="btn btn-danger  delete-btn">
                                       <em class="fa fa-times"></em> {{$t('Cancel')}}
                                   </a>
                               </div>
                               <div class="col-lg-6">
                                       <div class="py-2"><small class="text-danger">{{$t('MandatoryString')}}</small></div>
                               </div>
                           </div>
                       </div>                
                   </Form>
               </div>              
          </div>
        </div>
      </div>
    </div>   
</template>

<script>
import DataService  from '../../../services/DataService';
export default{
   data(){
       return{
           isLoading:false,
           txtShiftName:'',
           txtStartTime:'',
           txtEndTime:'',
           txtShiftHours:'',
           txtMinHours:'',
           txtShiftRate:'',
           txtOverTime:'',
           txtLocation:'',
           txtDescription:'',
           txtStatus:'1001',
           txtShiftType:'',
           DDLList:[],
           LocationList:[],
           Options:[]
       }
   },
   created: function () {               
       this.GetStatusList(); 
       this.GetLocationByCompanyId();
   },
   methods:{
    SavedShift:function(valid,errors){        
        var vm=this;
        let obj={
            ShiftName:vm.txtShiftName,
            StartTime:vm.txtStartTime,
            EndTime:vm.txtEndTime,
            ShiftHours:vm.txtShiftHours,
            MinHours:vm.txtMinHours,
            ShiftRate:vm.txtShiftRate,
            OverTime:vm.txtOverTime,
            Location:vm.txtLocation,
            Description:vm.txtDescription,
            Status:vm.txtStatus,
            ShiftType:vm.txtShiftType
        }
        var params=JSON.stringify(obj);
        vm.$refs.ShiftForm.validate().then((result) => {
            if(result){
                DataService.SavedShift(params).then(response=>{                    
                    if(response.data=='True'){
                        vm.ShowAlert(vm.$t('AddedSuccess',[vm.$t('Shift')]), "success", true,  vm.$t("Alert"));
                         vm.ClosedDesignation();
                    }
                    else{
                        vm.ShowAlert(vm.$t('AlreadyExists',[vm.$t('Shift')]), "failure", true,  vm.$t("Alert"));
                    }
                });
            }
        });
       },
       ClosedDesignation:function(item){       
            this.$emit('ClosedDesignation',item)
        },
       GetStatusList:function(){        
            DataService.DesignationGetStatusList().then(response=>{              
                this.DDLList=response.data;
            });
        },
        GetLocationByCompanyId:function(){
            var vm=this;
            var url=`reqForm=`
            DataService.GetMultiLocationByCompanyId(url).then(response=>{                
                vm.LocationList=response.data;               
            });
        }
   }
}
</script>
