<template>
    <section>
        <div class="modal my-popups right fade show" role="dialog" style="display: block;">
            <div class="modal-dialog mw-1100" role="document">
                <div class="modal-content">
                    <div class="modal-header mheader-user">
                        <button type="button" class="close" @click="$emit('closepopup')"><i class="fa fa-times"
                                aria-hidden="true"></i></button>
                        <h4 id="myModalLabel" class="modal-title">{{ $t("PreviewTemplate") }}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="mx-auto">
                            <div class="col-12 p-2 bg-light border" style="min-height:350px;">
                                <div class="col-12" id="textcontent" v-html="htmlContent"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
</template>
<script>
export default{
    props: { 
        htmlContent:  {
            type: String,
            required: true
        }
    }
}
</script>


