<script>
export default {
    props: {
        fieldInfo: {
            type: Object,
            required: true
        },
        dateformat: {
            type: String,
            required: false,
        },
        DependentOption: null
    },
    created: function () {
        console.log(this.DependentOption)
    },
    data: function () {
        return {
            popover: {
                visibility: 'click'
            }
        }
    },
    methods: {
        setDependentDate: function (ths,item) {         
        var vm=this;        
        setTimeout(function(){
            var ele=$(`div[name="${vm.fieldInfo.name}"]`);
            var eleval=$(`div[name="${vm.fieldInfo.name}"] input:first`);
                var value=ele.attr('value');
                if (value) {
                    eleval.removeClass('is-invalid');
                    eleval.parent().parent().find(`span[name='${vm.fieldInfo.name}']`).removeClass('d-block').addClass('d-none');
                } else {
                    eleval.addClass('is-invalid');
                    if (eleval.parent().next().is('span')) {
                        eleval.parent().next().removeClass('d-none');
                    } else {
                        eleval.parent().parent().append(`<span name="${vm.fieldInfo.name}" class="invalid-feedback d-block">${vm.GetFormSchemaFieldName(vm.fieldInfo.name, vm.fieldInfo.display_name)} is required.</span>`);
                    }
                }            },200);
            this.$emit('setDependentDate', item);
        },
        getMinmumDate(field) {
            if (field.name == 'campaign_end_date' || field.name == 'order_expiry_date' || field.name == 'contract_end_date' || 
           (this.$route.params.subModuleCode!='CRM_CONTRACTS' && field.name == 'billing_end_date') 
                || field.name == 'effective_end_date' || field.name == 'expected_close_date') {              
                return this.DependentOption[0].minDate;
            } else if (field.name == 'campaign_actual_end_date' || field.name == 'billing_end_date') {
                return this.DependentOption[1].minDate;
            } else if (field.name == 'order_date' || field.name == 'contract_start_date' || field.name == 'billing_start_date'
                || field.name == 'effective_start_date' || field.name == 'ref_date' || field.name == 'lead_date' || field.name == 'date_of_birth' 
                || field.name=='campaign_start_date' || field.name=='campaign_actual_start_date') {
                return '01-01-1990';
            }
            else {
                return null;// this.getMinDate();
            }
        },
        getMaxDate(field) {           
            if (field.name == 'order_date') {
                return this.getMinDate();
            } else if (field.name == 'date_of_birth') {

                return this.getMinDate();
            }
            else {
                return '01-01-2030';
            }
        },
      
    },

}
</script>
<template>
    <Field :name="GetFormSchemaFieldName(fieldInfo.name,fieldInfo.display_name)" :rules="{ 'required': fieldInfo.is_required }"
       
        v-slot="{errors,changed}" class="w-100">
        <v-date-picker :popover="popover" @dayclick="setDependentDate($event, fieldInfo)" :min-date="getMinmumDate(fieldInfo)"
            :max-date="getMaxDate(fieldInfo)" :minute-increment="5" style="flex-wrap:nowrap;" class="input-group w-100" v-model="fieldInfo.value" @update:modelValue="changed">        
            <template v-slot="{ inputValue, inputEvents, togglePopover }">           
                <input v-bind:class="{'form-control w-100' : true,'is-invalid': errors[0]}" :value="inputValue" v-on="inputEvents"
                    :placeholder="GetFieldPlaceHolder(fieldInfo.name, fieldInfo.display_name)" />
                <div @click="togglePopover()" class="input-group-append"><span class="input-group-text">
                    <em  class="fa fa-calendar"></em></span></div>
            </template>
        </v-date-picker>
        <span   :name="fieldInfo.name" class="invalid-feedback d-block">{{ errors[0] }}</span>
    </Field>
</template>
<style>
.field-label {
    display: none !important;
}

.field.has-value:not(.no-label) .field-input {
    padding-top: 0px !important;
}
</style>