<script>
    export default {
        props: {           
             fieldInfo: {
                type: Object,
                required: true
            },
            UserType:null,
            selectedQuotationType:null ,  
            subModuleCode:null,
            odnumber:null,     
        },
        data: function () {
            return {                
            }
        },
        methods: {
          showDropDown(){
             var isVisible=false;
             if(this.fieldInfo.picklist_options == 'Lookup' && this.fieldInfo.dt_code=='select' && this.fieldInfo.dropdown_type=='normal'){
              isVisible=true;
             }else if(this.fieldInfo.picklist_options != 'Lookup' && this.fieldInfo.dt_code=='select'){
              isVisible=true;
             }
             return isVisible;
          },
          getRuleObj() {
      var initialRule = {}
      if (this.fieldInfo.picklist_options == 'Lookup' && this.fieldInfo.dt_code == 'select' && this.fieldInfo.dropdown_type == 'normal') {
        if (this.subModuleCode == 'CRM_QUOTES') {
          initialRule.required = (this.fieldInfo.name == 'deal_id' || this.fieldInfo.name == 'client_id') ? ((this.selectedQuotationType == 2 && this.fieldInfo.name == 'deal_id') ? this.fieldInfo.is_required :
            ((this.selectedQuotationType == 1 && this.fieldInfo.name == 'client_id') ? this.fieldInfo.is_required : false)) : this.fieldInfo.is_required;
        } else {
          initialRule.required = this.fieldInfo.is_required;
        }
      }
      else if (this.fieldInfo.picklist_options != 'Lookup' && this.fieldInfo.dt_code == 'select') {
        initialRule.required = this.fieldInfo.is_required;
      }
      return initialRule;
    },
          getDisabledStatus(){
            var isDisabled=false;
            if(this.subModuleCode=='CRM_QUOTES'){
              isDisabled=this.fieldInfo.name == 'deal_id' && this.$route.params.reqFromId && this.$route.params.reqFromId!='0' || (this.odnumber == 'Drafted');
            }else  if(this.subModuleCode=='ManageAccount'){
          isDisabled=this.fieldInfo.name == 'account_type';
         }
            return isDisabled;
          },
            openaddIndustrypopup: function (item) {
               this.$emit('openaddIndustrypopup', item);
            },
             openaddLeadSourcepopup: function (item) {
               this.$emit('openaddLeadSourcepopup', item);
            },
             openaddRoleTitlepopup: function (item) {
               this.$emit('openaddRoleTitlepopup', item);
            },
            onchangechannel(item){
                this.$emit('onchangechannel', item);
            }, 
            FillDropdown(item){
                this.$emit('FillDropdown', item);
            },
            onchangeForNormalDDL(item){
              this.$emit('onchangeForNormalDDL', item);
            },
            onChange(item){
                this.$emit('onChange', item);
            },  
            onChangeOpenAddChannelTag(item){              
              this.$emit('onChangeOpenAddChannelTag', item);
            }, 
            onchangeDDL(event, item) {
      if (this.subModuleCode == 'CRM_PRICE_BOOKS') {
        this.$emit('onchangeForNormalDDL', item);
      } else if (this.subModuleCode == 'Account'|| this.subModuleCode =='ManageAccount' || this.subModuleCode == 'CRM_LEADS') {
        this.$emit('FillDropdown', item);
      } else if (this.fieldInfo.picklist_options == 'Lookup' && this.fieldInfo.dt_code == 'select' && this.fieldInfo.dropdown_type == 'normal' && (this.subModuleCode == 'CRM_QUOTES' ? this.selectedQuotationType == 2 : true)) {
        this.$emit('onchangechannel', item);
      } else if (this.fieldInfo.picklist_options != 'Lookup' && this.fieldInfo.dt_code == 'select' && (this.fieldInfo.dropdown_type == null || this.fieldInfo.name == 'display_icon')) {
        this.$emit('onChange', item);
      }
    }          
            },
          created(){
               console.log(this.subModuleCode)
            }
    }
</script>
<template>
 
  <div class="w-100 d-flex" v-if="showDropDown()"> 
      <Field :rules="getRuleObj()" class="w-100" :name="GetFormSchemaFieldName(fieldInfo.name, fieldInfo.display_name)" v-slot="{ errors }">
      
        <select :name="fieldInfo.name" v-on:change="onchangeDDL($event, fieldInfo)"  v-model="fieldInfo.value" :disabled="getDisabledStatus()"
        v-bind:class="{ 'form-control w-100': true, 'is-invalid': errors[0] }">
        <option value="" disabled>Select</option>
        <option v-for="(item, itemIndex) in fieldInfo.picklist_options != 'Lookup' ? MakeArray(fieldInfo.picklist_options) : MakeNormalArray(fieldInfo.select_options)" :key="itemIndex" :value="item.value"
        v-bind:selected="fieldInfo.value == item.value">{{ item.name }}</option>
        </select>
        <span :name="fieldInfo.name" class="invalid-feedback d-block">{{ errors[0] }}</span> 
        </Field>
    <div class="ml-2 mt-1" v-if="fieldInfo.isPlusIcon && UserType=='CA'" >
      <a class="round-icon-small btn-dark theme-primary">
          <em class="fa fa-plus text-white pt-7 font-18" v-on:click="openaddIndustrypopup(fieldInfo)" v-if="fieldInfo.name == 'industry_type_id'"></em>
          <em class="fa fa-plus text-white pt-7 font-18" v-on:click="openaddLeadSourcepopup(fieldInfo)" v-else-if="fieldInfo.name == 'channel_id' || fieldInfo.name == 'lead_source_id'"></em>
          <em class="fa fa-plus text-white pt-7 font-18" v-on:click="openaddRoleTitlepopup(fieldInfo)" v-else-if="fieldInfo.name == 'role_title_id'"></em>
          <em class="fa fa-plus text-white pt-7 font-18" v-on:click="openaddIndustrypopup(fieldInfo)" v-else-if="fieldInfo.name == 'industry_name'"></em>
          <em class="fa fa-plus text-white pt-7 font-18" v-on:click="onChangeOpenAddChannelTag(fieldInfo)" v-else-if="fieldInfo.name == 'channel_id' || fieldInfo.name == 'priority_id' || fieldInfo.name == 'tag_id' "></em>
      </a>
    </div>  
        </div>  
</template>