<template>
 <section class="main-content">
    <!-- start page heading -->
    <section :class="{'page-action':true,'d-none':callFrom='Wizard'}">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 padding-t_8">
                    <div class="theme-primary partition-full">
                        <span class="p-name text-white">{{$t('AddTicket')}}</span>
                        <span class="p-actions float-md-right float-right" style="display:none;" v-if="reqhFrom=='knowledgebase'">
                         
                                <a asp-controller="KnowledgeBase" asp-action="Index" asp-route-reqhFrom="reqhFrom"
                                :title="$t('BacktoList')" class="p-action-btn text-white d-md-inline-block w-100" id="btnback" data-toggle-tooltip="tooltip" :data-original-title="$t('BacktoList')">
                                    <i class="fa fa-angle-double-left"></i><br />{{$t('BacktoList')}}
                                </a>
                            
                           
                                <a asp-controller="Ticketing" asp-action="Index" asp-route-reqhFrom="reqhFrom" :title="$t('BacktoList')" class="p-action-btn text-white d-md-inline-block w-100" id="btnback" data-toggle-tooltip="tooltip" :data-original-title="$t('BacktoList')">
                                    <i class="fa fa-angle-double-left"></i><br />{{$t('BacktoList')}}
                                </a>
                          
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
 <div class="user-guide-custom d-none">
        <!-- @if (ViewBag.isTicketResolve == "yes" || ViewBag.isTicketHOD == "yes" || loggedUserInfo.UserType == "CA")
        {
            @Html.Raw(Resources.USER_GUIDE_TICKET_ADD_NOT_RESOLVER)
        }
        else if (loggedUserInfo.UserType == "CL")
        {
            @Html.Raw(Resources.USER_GUIDE_TICKET_ADD_CL)
        }
        else
        {
            @Html.Raw(Resources.USER_GUIDE_TICKET_ADD_NOT_RESOLVER)
        }
    </div> -->
    <!-- end page heading -->
    <Form ref="form" enctype="multipart/form-data" onsubmit="validateForm(event)">
        <div :class="{'container-fluid':true,'p-0':callFrom=='Wizard'}" id="divAddTicket">
            <div :class="{'px-4 float-left w-100':callFrom=='Wizard','border p-3':callFrom!='Wizard'}">
                <div class="row" id="theme-primary partition-full">
                        <input v-if="UserType =='CL'" type="hidden" asp-for="CompanyId" id="companyid" />
                    <input type="hidden" asp-for="CompanyId" id="companyid" />
                    <input type="hidden" asp-for="RaisedByName" id="RaisedByName" />
                    <input type="hidden" asp-for="RaisedByEmail" id="RaisedByEmail" />
                    <input type="hidden" asp-for="CompanyName" id="companyid" />
                    <input name="TicketId" type="hidden" value="@numericticketId" />
                    <input type="hidden" asp-for="IsSupportTicket" id="IsSupportTicket" />
                    <input type="hidden" asp-for="CreatedBy" id="CreatedBy" />
                    <input type="hidden" asp-for="Status" id="Status" />
                    <input type="hidden" asp-for="Ticketnumber" id="Ticketnumber" />
                    <input id="hdnissupttckt" name="hdnissupttckt" type="hidden" value="false" />
                    <input type="hidden" asp-for="IsTicketRequest" id="hdnType" />
                    <input type="hidden" id="hdnLocationNW" asp-for="LOCATION_NW" />
                    <input type="hidden" id="hdnLocationSE" asp-for="LOCATION_SE" />
                    <input type="hidden"  id="hdnAction" />
                    <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">


                        <div class="form-group">
                            <input asp-for="TicketSubject" asp-show-label="yes" asp-mandatory="yes" asp-show-input="yes" asp-input-class="" tabindex="1" maxlength="100"/>
                        </div>
                    </div>
                    <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group" v-if="UserType == 'CL'">
                           
                                <!-- <tg-select asp-for="DepartmentId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectDepartment" asp-items="@(new SelectList(TALYGEN.StatusClass.GetDepartmentListbyCompanyForClient(loggedUserInfo.CompanyId, false, true), "department_id", "department_name"))" tabindex="2"></tg-select> -->
                                <input type="hidden" asp-for="DepartmentName" />
                          
                           
                           
                                <!-- <tg-select asp-id="DepartmentId" asp-for="DepartmentId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectDepartment" asp-items="@(new SelectList(TALYGEN.StatusClass.GetDepartmentListbyCompany(loggedUserInfo.CompanyId, false, true), "department_id", "department_name"))" tabindex="2"></tg-select> -->
                                <input type="hidden" asp-for="DepartmentName" />
                        
                        </div>
                    </div>
                    <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group">

                            <!-- <tg-select asp-id="AssignedToId" asp-for="AssignedToId" asp-show-label="yes" asp-mandatory="no" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectUser" asp-items="@(new SelectList(ViewBag.UserList, "UserId", "UserName"))" tabindex="3"></tg-select> -->
                            <input id="hdnAssignedToId" type="hidden" asp-for="AssignedToId" />

                        </div>
                    </div>
                    <div class="clearfix d-lg-none"></div>
                    <div :class="{'col-lg-4' :callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group">
                            <tg-select asp-id="PriorityId" asp-for="PriorityId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectPriority" asp-items="@(new SelectList(TALYGEN.StatusClass.GetPrioritybyCompanyId(loggedUserInfo.CompanyId), "PRIORITY_ID", "PRIORITY_NAME"))" tabindex="4"></tg-select>
                            <input type="hidden" asp-for="Priority" />
                        </div>
                    </div>
                    <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group">
                            <!-- @if (ViewBag.GetTicketProductOptionByCompanyId == "yes")
                            { -->
                                <!-- <tg-select asp-id="ProductId" asp-for="ProductId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="mandatoryoption" asp-option-label="@Resources.SelectProduct" asp-items="@(new SelectList(ViewBag.ProductsList, "Value", "Name"))" tabindex="5"></tg-select> -->
                            <!-- }
                            else
                            { -->
                                <!-- <tg-select asp-id="ProductId" asp-for="ProductId" asp-show-label="yes" asp-mandatory="no" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectProduct" asp-items="@(new SelectList(ViewBag.ProductsList, "Value", "Name"))" tabindex="5"></tg-select> -->
                            
                        </div>
                    </div>
                    
                        <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" v-if="UserType == 'CL'">
                            <div class="form-group">
                                <!-- <tg-select asp-id="ProjectId" asp-for="ProjectId" asp-show-label="yes" asp-mandatory="no" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectProject" asp-items="@(new SelectList(TALYGEN.StatusClass.GetCLProjectList(loggedUserInfo.ID, loggedUserInfo.CompanyId, loggedUserInfo.UserType), "PROJECT_ID", "PROJECT_NAME"))" tabindex="6"></tg-select> -->
                                <input type="hidden" asp-for="Project" />
                                <input type="hidden" asp-for="ProjectName" />
                            </div>
                        </div>
                    
                   
                        <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" v-if="UserType == 'CL'">
                            <div class="form-group">
                                <label>{{$t('CCUsers')}}:</label>
                                <div class="row customize_ht">
                                    <div class="col-12 float-left">
                                        <div class="input-group multiselect-group ">
                                            <select asp-for="AssignedTo" class="form-control multiselectdd" id="SelectCcUserList" name="SelectCcUserList" multiple="multiple" asp-item="@ViewBag.GetCompanyUserListByCompanyId" tabindex="7"></select>
                                            <input type="hidden" asp-for="AssignedTo" id="hdnccticketuserlist" value="@(string.Join(",", Model.CCUserList.Select(x => x.Value)))" />
                                            <div class="input-group-append">
                                                <span class="input-group-text" v-if="CCUserList.Count > 0">
                                                  
                                                        <!-- string usernamelist = string.Join(",", Model.CCUserList.Select(x => x.Name)); -->
                                                        <div class="divuserdatacc" title="@usernamelist" style="display:none;">
                                                            <!-- @(usernamelist.Length > 40 ? Html.Truncate(usernamelist, 40) + "..." : usernamelist) -->
                                                        </div>
                                                        <a href="javascript:;" data-userlist="" data-ticketid="" class="ancuserdatacc">
                                                            <i class="fa fa-eye text-dark font-18" :title="$t('View')"></i>
                                                        </a>
                                                    
                                                   
                                                        <div class="divuserdatacc " style="display:none ;">
                                                        </div>
                                                        <a href="javascript:;" data-userlist="" data-ticketid="" class="ancuserdatacc">
                                                            <i class="fa fa-eye text-dark font-18" title="@Resources.View"></i>
                                                        </a>
                                                
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                  
                        <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" v-if="IsSupportTicket">
                            <div class="form-group">
                                <div class="input-group">
                                    <!-- @if (ViewBag.GetTicketCategoryOptionByCompanyId == "yes")
                                    {
                                        <tg-select asp-for="TicketCategoryId" asp-show-label="yes" asp-mandatory="yes" class="flex-grow" asp-show-select="yes" asp-select-class="mandatoryoption" asp-option-label="@Resources.SelectTicketCategory" asp-items="@(new SelectList(ViewBag.GetDDLTicketCategoryByCompanyId, "Value", "Name"))" tabindex="8"></tg-select>
                                    }
                                    else
                                    {
                                        <tg-select asp-for="TicketCategoryId" asp-show-label="yes" asp-mandatory="no" class="flex-grow" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectTicketCategory" asp-items="@(new SelectList(ViewBag.GetDDLTicketCategoryByCompanyId, "Value", "Name"))" tabindex="8"></tg-select>
                                    } -->
                                    <div class="input-group-append">
                                        <span class="input-group-text border-0 bg-transparent p-0 pl-2">
                                            <a href="javascript:;" class="mt-4 submitbtn btn-dark theme-primary round-icon-small clsAddTicketCategory" id="addmailBlackListed" title="" data-toggle-tooltip="tooltip" data-original-title="Add">
                                                <i class="fa fa-plus pt-7"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>

                                <input type="hidden" asp-for="TicketCategoryName" />

                            </div>
                        </div>
                    
                   
                        <div class="clearfix d-none d-lg-block"></div>
                        <div id="rdoTicketFor" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" :v-if="isTicketResolve == 'yes' || UserType == 'CA'">
                            <div class="form-group">
                                <label><label asp-for="TicketFor"></label>:<span class="text-danger mandatory">*</span></label>
                                <div class="form-control pl-0 border-0">
                                    <div class="custom-control custom-radio d-inline-block" :v-if="TicketId == 0">
                                       
                                            <input type="radio" asp-for="TicketFor" name="TicketFor" value="@((int)TicketOnBehalfs.Self)" class="form-check-input" disabled="disabled" checked="checked" tabindex="9" />
                                       
                                            <input type="radio" asp-for="TicketFor" name="TicketFor" value="@((int)TicketOnBehalfs.Self)" class="form-check-input rdoticketfor" checked="checked" tabindex="9" />
                                        
                                        <label class="mb-0" for="TicketFor">
                                           {{ $t('Self')}}
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio d-inline-block" :v-if="TicketId > 0">
                                      
                                            <input type="radio" asp-for="TicketFor" name="TicketFor" value="@((int)TicketOnBehalfs.OnBehalf)" class="form-check-input" disabled="disabled" tabindex="9" />
                                   
                                        
                                            <input type="radio" asp-for="TicketFor" name="TicketFor" value="@((int)TicketOnBehalfs.OnBehalf)" class="form-check-input rdoticketfor rdoBehalf" tabindex="9" />
                                    
                                        <label class="mb-0" for="OnBehalf">
                                       {{    $t('OnBehalf')}}
                                        </label>
                                    </div>
                                    <span asp-validation-for="TicketFor" class="invalid-feedback"></span>
                                </div>
                            </div>
                        </div>
                   
                  
                        <input type="hidden" asp-for="TicketFor" value="@((int)TicketOnBehalfs.Self)" />
                   



                    <div id="DivOnBehalf" style="display: @((Model.TicketFor == (int)TicketOnBehalfs.OnBehalf) ? "block;" : "none;")" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" 
                        <div class="form-group">
                            <label><label asp-for="OnBehalf"></label>:<span class="text-danger mandatory">*</span></label>
                            <div class="form-control pl-0 border-0">
                                <div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" asp-for="OnBehalf" name="OnBehalf" value="@((int)TicketOnBehalfs.User)" class="form-check-input rdoOnBehalf" checked="checked" tabindex="10" />
                                    <label class="mb-0" for="OnBehalf">
                                  {{  $t('User')}}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio d-inline-block">
                                    <input type="radio" asp-for="OnBehalf" name="OnBehalf" value="@((int)TicketOnBehalfs.Client)" class="form-check-input rdoOnBehalf rdoClient" tabindex="11" />
                                    <label class="mb-0" for="Client">
                                     {{  $t('Client')}}
                                    </label>
                                </div>
                                <span asp-validation-for="OnBehalf" class="invalid-feedback"></span>
                            </div>
                        </div>
                    </div>
                    <div id="divOnBehalfUser" :style="{'display':OnBehalfUserId > 0 ? 'block' : 'none'}" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">

                            <label><label asp-for="OnBehalfUserId"></label>:<span class="text-danger mandatory">*</span></label>
                            <select name="OnBehalfUserId" class="form-control" asp-for="OnBehalfUserId" :option="CompanyUserDropdDownList" tabindex="12">
                                <option value="">{{$t('SelectUser')}}</option>
                            </select>
                            <span asp-validation-for="OnBehalfUserId" class="invalid-feedback"></span>
                        </div>
                    </div>
                    <div id="divOnBehalfClient" :style="{display: OnBehalfClientId > 0 || OnBehalfClientId == -1 ? 'block' : 'none'}" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >

                        <div class="form-group">
                            <label><label asp-for="OnBehalfClientId"></label>:<span class="text-danger mandatory">*</span></label>
                            <div class="row" >
                                <!-- @if (loggedUserInfo.UserType == "NU")
                                {
                                    colClass = "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left";
                                }
                                else
                                {
                                    colClass = "col-9 col-sm-10 col-md-10 col-lg-10 col-xl-10 float-left";
                                } -->
                                <div class="'@colClass'">
                                    <select id="ddlOnBehalfClientId" name="ddlOnBehalfClientId" class="form-control" asp-items="@(ViewBag.CompanyClientDropdDownList as IEnumerable<SelectListItem>)">
                                    </select>
                                    <span asp-validation-for="OnBehalfClientId" class="invalid-feedback" id="spnOnBehalfClientId"></span>
                                    <input type="hidden" asp-for="OnBehalfClientId" id="hdnOnBehalfClientId" />
                                </div>
                             
                                    <div class="ml-0" v-if="UserType == 'CA'">
                                        <div class="form-group">
                                            <a id="aAddMoreClient" class="submitbtn   btn-dark theme-primary round-icon-small" :title="$t('AddClient')" href="javascript:;"
                                               popuptitle="@Resources.Add @Resources.Client">
                                                <i class="fa fa-plus pt-7 font-18"></i>
                                            </a>
                                        </div>
                                    </div>
                           
                            </div>
                        </div>
                    </div>
                    <div id="divUtility" style="display: none;" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <!-- <tg-select asp-for="UtilityId" asp-show-label="yes" asp-mandatory="no" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectUtilityName" asp-items="@(new SelectList(Enumerable.Empty<SelectListItem>()))" tabindex="2"></tg-select> -->
                        </div>
                    </div>
                    <div id="divCDCCode" style="display: none;" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <!-- <tg-select asp-for="CDCCodeId" asp-show-label="yes" asp-mandatory="no" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectCDCCode" asp-items="@(new SelectList(Enumerable.Empty<SelectListItem>()))" tabindex="2"></tg-select> -->
                        </div>
                    </div>
                    <div id="divWorkType" style="display:none;" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group">
                            <!-- <tg-select asp-for="WorkTypeId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.Select @Resources.WorkType" asp-items="@(new SelectList(Enumerable.Empty<SelectListItem>()))" tabindex="2"></tg-select> -->
                        </div>
                    </div>
                    <div class="clearfix d-none d-lg-block"></div>

                    <div :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <!-- <tg-select asp-for="Status_Id" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectStatus" asp-items="@(new SelectList(TALYGEN.StatusClass.GetStatusList(companyId: loggedUserInfo.CompanyId, statusType: "Ticketing", hasglobal: false), "STATUS_ID", "STATUS"))" tabindex="2"></tg-select> -->
                            <input type="hidden" asp-for="StatusValue" />
                        </div>
                    </div>
                    <div class="clearfix d-none d-lg-block"></div>

                    <div id="divOnBehalfUserChannel" style="{display: TicketFor == (int)TicketOnBehalfs.OnBehalf && Model.OnBehalf == (int)TicketOnBehalfs.Client) ? 'block': 'none;''}" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}">
                        <div class="form-group formminheight_90">
                            <label><label asp-for="TicketChannel"></label>:<span class="text-danger mandatory">*</span></label>
                            <select id="TicketChannel" name="TicketChannel" asp-for="TicketChannel" class="form-control" :option="GetDDLChannelByCompanyId"  tabindex="14">
                                <option value="">$t('SelectChannel')</option>
                            </select>
                            <span asp-validation-for="TicketChannel" class="invalid-feedback"></span>
                        </div>
                    </div>
                    <div id="firstName" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <label><label asp-for="FirstName"></label>:<span class="text-danger mandatory">*</span></label>
                            <input type="text" asp-for="FirstName" class="form-control" maxlength="200" tabindex="15" />
                            <span asp-validation-for="FirstName" class="invalid-feedback"></span>
                        </div>
                    </div>
                    <div id="lastName" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <label><label asp-for="LastName"></label>:<span class="text-danger mandatory">*</span></label>
                            <input type="text" asp-for="LastName" class="form-control" maxlength="200" tabindex="16" />
                            <span asp-validation-for="LastName" class="invalid-feedback"></span>
                        </div>
                    </div>
                    <div class="clearfix" v-if="TicketId==0"></div>
                   
                        <div id="divpassword" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                            <div class="form-group">
                                <label><label asp-for="Password"></label>:<span class="text-danger mandatory">*</span></label>
                                <div>
                                    <input type="password" asp-for="Password" class="form-control" maxlength="200" tabindex="17" autocomplete="off" />
                                    <span asp-validation-for="Password" class="invalid-feedback"></span>
                                </div>
                            </div>
                        </div>
                        <div id="divconfirmpassword" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                            <div class="form-group">
                                <label><label asp-for="ConfirmPassword"></label>:<span class="text-danger mandatory">*</span></label>
                                <div>
                                    <input type="password" asp-for="ConfirmPassword" class="form-control" maxlength="200" tabindex="18" autocomplete="off" />
                                    <span asp-validation-for="ConfirmPassword" class="invalid-feedback"></span>
                                </div>
                            </div>
                        </div>
                    }
                    <div id="emailId" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <label><label asp-for="EmailId"></label>:<span class="text-danger mandatory">*</span></label>
                            <div>
                                <input type="hidden" asp-for="OnBehalfConsumerId" />
                                <input type="text" asp-for="EmailId" class="form-control" maxlength="100" tabindex="19" autocomplete="off" />
                                <span asp-validation-for="EmailId" class="invalid-feedback"></span>
                            </div>
                        </div>
                    </div>
                    <div id="phoneNumber" style="display:none" :class="{'col-lg-4':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" >
                        <div class="form-group">
                            <label><label asp-for="ClientPhoneNumber"></label>:</label>
                            <div>
                                <input type="text" asp-for="ClientPhoneNumber" class="form-control" maxlength="18" tabindex="20" autocomplete="off" onkeypress="return IsValidChars(event, '0123456789.-+() ');" />
                                <span asp-validation-for="ClientPhoneNumber" class="invalid-feedback"></span>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div id="divAddress" class="w-100" style="display:none">
                        <div class="col-lg-12 mt-3 mb-2">
                            <div class="heading-border">
                                <h5 class="h5">
                                    <span>{{$t('Address')}}</span> <a href="javascript:;" data-toggle="popoveruserguide" data-content="@Html.Raw(TALYGEN.Models.CommonClass.GetMaximumFileSizeMessage(loggedUserInfo.CompanyId, loggedUserInfo.ID, "Ticketing"))">
                                        <i class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></i>
                                    </a>
                                </h5>
                            </div>
                        </div>
                        <div class="col-6 float-left">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <asp-label-mandatory asp-for="Address"></asp-label-mandatory>
                                        <input type="text" class="form-control" asp-for="Address" :placeholder="$t('Address')" />
                                        <span asp-validation-for="Address" class="invalid-feedback"></span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <tg-select asp-for="CountryId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectCountry" asp-items="@(new SelectList(TALYGEN.StatusClass.GetCountryList(), "ID", "COUNTRYNAME"))" tabindex="2"></tg-select>
                                        <input type="hidden" asp-for="CountryName" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <tg-select asp-for="StateId" asp-show-label="yes" asp-mandatory="yes" asp-show-select="yes" asp-select-class="" asp-option-label="@Resources.SelectStateName" asp-items="@(new SelectList(TALYGEN.StatusClass.GetStateListByCountryId(Model.CountryId), "Value", "Name"))" tabindex="2"></tg-select>
                                        <input type="hidden" asp-for="StateName" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <asp-label-mandatory asp-for="County"></asp-label-mandatory>
                                        <input type="text" class="form-control" asp-for="County" placeholder="@Resources.enter @Resources.County" />
                                        <span asp-validation-for="County" class="invalid-feedback"></span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <asp-label-mandatory asp-for="City"></asp-label-mandatory>
                                        <input type="text" class="form-control" asp-for="City" placeholder="@Resources.enter @Resources.City" />
                                        <span asp-validation-for="City" class="invalid-feedback"></span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 ">
                                    <div class="form-group">
                                        <asp-label-mandatory asp-for="Street"></asp-label-mandatory>
                                        <input type="text" class="form-control" asp-for="Street" placeholder="@Resources.enter @Resources.Street" />
                                        <span asp-validation-for="Street" class="invalid-feedback"></span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <asp-label-mandatory asp-for="ZipCode"></asp-label-mandatory>
                                        <input type="text" class="form-control" asp-for="ZipCode" placeholder="@Resources.enter @Resources.ZipCode" />
                                        <span asp-validation-for="ZipCode" class="invalid-feedback"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 float-left">
                           
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0" v-if="map == 'Google'">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left px-0" id="divcityshown">
                                        
                                        <input id="autocomplete" placeholder="Enter a city" type="text" class="form-control padleft30" tabindex="17" />
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 float-right pr-0 py-2" id="divcitysave">
                                        
                                        <a id="delete-button" :title="$t('Delete')" name="btnDelete" tabindex="18" class="btn btn-danger formbtn float-right text-right"><i class="fa fa-trash pr-2"></i>{{$t('Delete')}}</a>
                                    </div>
                                    <div id="map" class="map_canvas mt-1" style="min-height:300px;width:100%;"></div>
                                    <input type="hidden" name="vertices" value="" id="vertices" />
                                </div>
                     
                            
                                <div v-else-if="map == 'MapBox'" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0">
                                    <div class="col-12 p-0 float-left">
                                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left px-0" id="divmapcityshown">
                                            
                                            <div id="geocoder" class="geocoder"></div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 float-left pr-0" id="divmapcitysave">
                                         
                                        </div>
                                    </div>
                                    <div id="map1" class="map_canvas mt-1" style="min-height:300px;width:100%;"></div>
                                    <pre id="info"></pre>
                                </div>
                           
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0" v-if="MapType == 'LeafLet' || MapType == 'Esri'">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left px-0" id="divcityshown">
                                      
                                        <input id="autocomplete" placeholder="Enter a city" type="text" class="form-control padleft30" tabindex="19" />
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 float-left pr-0" id="divcitysave">
                                      
                                    </div>
                                    <div id="map" class="map_canvas mt-1" style="min-height:300px;width:100%;"></div>
                                    <input type="hidden" name="vertices" value="" id="vertices" />
                                </div>
                            
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-lg-12 mt-3 mb-2">
                        <div class="heading-border">
                            <h5 class="h5">
                                <span>{{$t('Attachment')}}</span> <a href="javascript:;" data-toggle="popoveruserguide" ==>
                                    <i class="fa fa-question-circle-o text-popover" style="font-size: 14px;"></i>
                                </a>
                            </h5>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left p-0" id="divFiles">
                        <div  :class="{'col-lg-6':callFrom=='Wizard','col-lg-3':callFrom!='Wizard','float-left':true}">
                            <div class="form-group mb-1">
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" name="FileSrc" id="flFiles" data-file="multi" class="ticketFiles" :class="{'col-lg-6':callFrom=='Wizard','col-lg-3':callFrom!='Wizard'}" />
                                    </div>
                                    
                                      
                                        <a v-if="AttachmentsCount>0" class="round-icon-small btn-info attachmentviewfiles mt-1" ticketId="@Model.TicketId" title="@Resources.ViewFiles" href="javascript:;">
                                            <i class="fa fa-eye text-white"></i>
                                        </a>

                                </div>
                                <span id="spnFile" class="text-danger" data-valmsg-for="FileSrc"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-3">
                        <div class="heading-border">
                            <h5 class="h5">
                                <span>{{$t('Description')}}<sup class="text-danger mandatory">*</sup></span>
                            </h5>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left mt-2">
                        <div class="form-group n_ckeditor">
                            <textarea type="text" asp-for="Description" class="form-control ckeditor" id="contentwiz" tabindex="20"></textarea>
                            <input type="hidden" id="hdnticketDescriptionMaxLength" value="4000" />
                            <span id="spnContent" class="font_80"></span>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <div class="col-12">
                        <div class="row flex-row-reverse">
                            <div class="col-lg-6 text-right">
                                <a href="javascript:;" :class="{'col-lg-6':callFrom=='Wizard','col-lg-3':callFrom!='Wizard','btn btn-warning ancsaveuserticket':true}"><i class="fa fa-save pr-2"></i>{{$t('SaveAsDraft')}}</a>
                                <a href="javascript:;" class="btn btn-success  ancsaveuserticket text-white" id="btnSaveTicket" :title="$t('ClickHereSubmitTicket')"><i class="fa fa-save pr-2 text-white"></i>{{$t('submit')}}</a>                               
                                    <a v-if="requestFrom == 'knowledgebase'" href="/KnowledgeBase/Index" class="btn btn-danger text-white" :title="$t('ClickHereCancelTicket')"><i class="fa fa-close pr-2 text-white"></i>{{$t('Cancel')}}</a>                            
                                    <a href="javascript:;" :class="{'btn btn-danger text-white':true,'d-none':callFrom=='Wizard'}" id="btnCancel" :title="$t('ClickHereCancelTicket')"><i class="fa fa-close pr-2 text-white"></i>{{$t('Cancel')}}</a>
                               
                            </div>
                            <div class="col-lg-6"><div class="py-2"></div></div>
                        </div>
                    </div>

                    <input type="hidden" id="ControllerName" value="Ticketing" />
                    <input type="hidden" id="ActionName" value="Add" />
                </div>

            </div>

    </Form>
</div>
</section>
</template>
<script>
import DataService from '../../services/DataService';
export default{
    props:{
        callFrom:null,
    },
    data(){
        return{
           reqhFrom:this.$route.params.reqhFrom,
           UserType:null,
           OnBehalfUserId:0,
           CompanyUserDropdDownList:[],
           GetTicketCategoryOptionByCompanyId:[],
           GetDDLChannelByCompanyId:[],
           TicketFor:null,
           TicketId:null,
           map:"",
           MapType:"",
           GetMaximumFileSizeMessage:[],
           AttachmentsCount:null

        }
    },
    created(){
        this.UserType = this.GetUserInfo.UserType;
    },
    methods:{
        
    }
}
</script>