<script>
import LeadDataService from "../../../services/DataService";
import $ from "jquery";
export default {
  props:{
    callFrom:null
  },
  data() {
    return {
      leadsource: "",
      leadsourcedesc: "",
    };
  },

  methods: {
    SaveIndustry: function (valid,errors) {
      var vm = this;
      var jsonobj = {
        leadsource: vm.leadsource,
        leadsourcedesc: vm.leadsourcedesc,
      };
      //  vm.$refs.leadSourceForm.setFieldTouched("LeadSource", true);
      vm.$refs.leadSourceForm.validate().then((result) => {
        if (result) {
          LeadDataService.SaveLeadSource(jsonobj).then(function (response) {
            if (response.data.Status == "SUCCESS") {
            
                vm.ShowAlert(vm.$t("Lead Source has been saved successfully."));
              
              var param = "id=&moduleName=CRM&type=CHANNEL";
               
              LeadDataService.getDDLData(param).then(function (response1) {
                var json = [];
                if (response1.data != null) {
                  json = response1.data.DATA;
                }
                console.log(vm.$parent);
                 vm.$emit('updatedata',vm.callFrom=='lead'?'channel_id':'lead_source_id', json);    
                 vm.$emit("close");           
                 
              });
            } else if (response1.data.Status == "EXIST") {
             
              vm.ShowAlert(vm.$t("Lead Source name already exist"),"failure",true,vm.$t('Alert'));
            }
             
          });
        }
      });
    },
  },
};
</script>
<template>
  <transition name="modal">
    <div class="my-popups">
      <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered" style="max-width:900px !important">
          <div class="modal-content">
            <div class="modal-header theme-primary partition-full">
              <h4 class="modal-title">{{$t('Add')}} {{$t('LeadSource')}}</h4>
              <button type="button" class="close" v-on:click="$emit('close')">
                <em class="fa fa-times" aria-hidden="true"></em>
              </button>
            </div>
            <div class="modal-body">
              <Form ref="leadSourceForm">
                <div class="col-md-12 float-left px-0">
                  <div class="row" style="max-height: 450px; overflow-y: auto;">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>
                          {{$t('LeadSource')}}:
                          <span class="text-danger">*</span>
                        </label>
                        <Field
                          name="LeadSource"
                          :rules="{'required':true}"
                          :label="$t('LeadSource')"
                          v-slot="{errors,touched,invalid}"
                        >
                          <input
                            v-bind:class="{'form-control':true, 'is-invalid':touched && invalid}" 
                            id="Name"
                            maxlength="200"
                            name="LeadSource"
                            v-model="leadsource"
                          />
                          <span
                             
                            name="LeadSource"
                            class="invalid-feedback"
                          >{{errors[0]}}</span>
                        </Field>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>{{$t('Description')}} :</label>
                        <Field
                          name="Description"
                          
                          label="Description"
                          v-slot="{errors,touched,invalid}"
                        >
                        <textarea
                          v-bind:class="{'form-control':true}"
                          maxlength="1000"
                          id="description"
                          name="Description"
                          v-model="leadsourcedesc"
                          type="text"
                          style="max-height:300px!important;min-height:100px!important"
                        ></textarea>
                          <!-- <span
                                  
                            name="Description"
                            class="invalid-feedback"
                          >{{errors[0]}}</span> -->
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div class="col-md-12 p-0">
                <div class="row flex-row-reverse">
                  <div class="col-lg-6 text-right">
                    <a
                      href="javascript:;"
                      v-on:click="SaveIndustry(valid,errors);"
                      title="Save"
                      class="btn btn-success save-btn mr-1"
                    >
                      <em class="fa fa-save pr-2"></em>
                      {{$t('Save')}}
                    </a>
                    <a
                      href="javascript:;"
                      v-on:click="$emit('close')"
                      class="btn btn-danger  delete-btn"
                    >
                      <em class="fa fa-times"></em>
                      {{$t('Cancel')}}
                    </a>
                  </div>
                  <div class="col-lg-6">
                    <div class="py-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>