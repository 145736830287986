 <script>
export default {
    props: {          
             fieldInfo: {
                type: Object,
                required: true
            }        
        },
	data: function() {
		return {            
			color: '#000000'		
		}
	},
	created(){
		this.fieldInfo.value=this.fieldInfo.value?this.fieldInfo.value:'#000000';
	}  
}
</script>
<template>
	<Field class="w-100 input-group" :name="fieldInfo.name" :rules="{ 'colorCode': true }" v-slot="{ errors }">
		<input type="text" :name="fieldInfo.name" v-bind:class="{'form-control' : true,'is-invalid': errors[0]}" 
		:value="fieldInfo.value" />
		<vue2-color-picker v-model="fieldInfo.value"></vue2-color-picker>
		<span v-if="errors[0]" :name="fieldInfo.name" class="invalid-feedback">{{ errors[0] }}</span>
	</Field>
</template> 
<style>
#color-picker-arrow{display: none;}
.color-box-inside{height: 31px!important; min-height: 31px!important;}
</style>