<template>
    <div class="w-100 float-left">
        <loader :is-visible="isLoading"></loader>
        <div class="row">
            <div class="w-100 float-left">
                <dynamic-form  v-if="campaignType == 'sms'" ref="AddForm" :schema="FormSchema" :buttons="buttons" v-on:OnSubmit="onSubmit">
                </dynamic-form>
            </div>
        </div> 
        <ModalManageSMSSchedule v-if="showModalManageSMSSchedule" :campaignId="CampaignId" :campaignType="campaignType" :reqFrom="reqForm"  @close="showModalManageSMSSchedule = false"></ModalManageSMSSchedule>
    </div>
</template>

<script>
import DataServices from "../../../services/DataService";
import ModalManageSMSSchedule from "./modalManageSMSSchedule.vue";
export default {
    components:{
        ModalManageSMSSchedule
    },
    props: { 
        CampaignId:  {
            type: Number,
            required: true
        },
        reqType:  {
            type: String
        },
        reqForm:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            StaticObj:
            {
                layoutType: "double",
                Data: [
                {
                    astype: "SelectField",
                    label: this.$t('Template'),
                    name: "Template",
                    value: "",
                    config: {
                        options: [],
                        showAddIcon: true,
                        onAddButtonClick: this.AddSMSTemplate,
                    },
                    validationRules: { "required": true }
                }
            ]
            },
            buttons: [
                {
                    type: "submit",
                    class: "btn btn-primary",
                    text: "<i class='fa fa-send pr-2'></i>Send"
                },
                {
                    type: "button",
                    class: "btn btn-danger",
                    text: "<i class='fa fa-close pr-2'></i>Cancel",
                    onClick: this.$parent.onCancel
                },
            ],
            FormSchema: [],
            campaignType: 'sms',
            CampaignData: [],
            GroupBy: [],
            isLoading: false,
            CampOwner:'',
            CampaignTypeList:[],
            UserId:0,
            statusId: 1001,
            TemplateId:0,
            showModalManageSMSSchedule: false
        };
    },
   async created () {
        var vm = this;
        vm.UserId= vm.GetUserInfo.ID;
        await vm.GetCampaignTypes();
        await vm.FetchData(); 
        await vm.GetDropDownData();
        await vm.GetSMSTemplate();
        vm.$refs.AddForm.UpdateKeyValue();      
    },
    methods: {
        AddSMSTemplate: function(){
            window.open('TemplateListing', '_blank');
        },
        FetchData: async function () {
            var vm = this;
            vm.isLoading = true;
            const params = `id=${vm.CampaignId}&moduleName=CRM&subModuleCode=CRM_CAMPAIGNS&langCode=en`;
            await DataServices.getFormLayout(params).then(function (response) {
                vm.isLoading = false;
                vm.CampaignData = response.data.data;
                if (vm.CampaignData != null && vm.CampaignData.length > 0) {
                    var groupId = vm.CampaignData[0].group_id;
                    $.each(vm.CampaignData, $.proxy(function (index, item) {
                        groupId = item.group_id;
                        var chkGroup = $.grep(vm.GroupBy, function (element, index) {
                            return element.group_id == groupId;
                        });
                        if (chkGroup.length == 0) {
                            var returnedData = $.grep(vm.CampaignData, function (element, index) {
                                return element.group_id == groupId;
                            });
                            var finalData = [];
                            returnedData.forEach(item => {
                                if(vm.CampaignId > 0){
                                    if(item.name=='template_id'){
                                        vm.TemplateId=item.value;
                                    }
                                }
                                if (item.form_field_visibility == 'YES') {
                                    let DataObj = {};
                                    if (item.astype == 'TextField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'CustomSelectField') {
                                        if(vm.CampaignId > 0){
                                            vm.CampOwner=item.value;
                                        }else{
                                            vm.CampOwner=vm.UserId;
                                        }
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            config: {
                                                options: []
                                            },
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                    if (item.astype == 'TextAreaField') {
                                        DataObj = {
                                            astype: item.astype,
                                            label: vm.$t(item.display_name),
                                            name: item.name,
                                            value: item.value,
                                            disabled: false,
                                            validationRules: { "required": item.system_is_required },
                                        }
                                        finalData.push(DataObj);
                                    }
                                }
                            })
                            if (returnedData.length > 0) {
                                var objGroup = {
                                    group_id: groupId,
                                    layoutType: "double",
                                    group_name: returnedData[0].group_name,
                                    group_display_name: returnedData[0].group_display_name,
                                    Data: finalData
                                };
                                vm.GroupBy.push(objGroup);
                            }
                        }
                    }))
                    vm.GroupBy.push(vm.StaticObj);
                    vm.FormSchema = vm.GroupBy;
                    if(vm.CampaignId > 0){
                        vm.FormSchema.forEach(x=>{
                            x.Data.forEach(y=>{
                                if(y.name=='Template'){
                                    y.value=vm.TemplateId;
                                }
                            })
                        })
                    }
                }
                vm.isLoading = false;
            });
        },
        async GetDropDownData() {
            var vm = this;
            var url = "id=&moduleName=CRM&type=CRM_USERS";
            await DataServices.getDDLData(url + "&search=").then(response => {
                var json = [];
                if (response.data == '') {
                }
                if (response.data != '') {
                    json = response.data;
                }
                var campDDLData = [];
                vm.CampaignOwnerData = json.DATA;
                if (vm.CampaignOwnerData != null) {
                    vm.CampaignOwnerData.forEach(x => {
                        campDDLData.push({
                            name: x.name,
                            value: x.value
                        })
                    })
                    vm.CampaignOwnerData = campDDLData;
                    vm.GroupBy.forEach(x => {
                        if (x.group_name == 'campaign_details') {
                            x.Data.filter(x=>x.name=='campaign_owner')[0].astype='MultiSelectField';
                            //x.Data.filter(x=>x.name=='campaign_owner')[0].mode='tag';
                            x.Data.filter(x => x.name == 'campaign_owner')[0].config.options = vm.CampaignOwnerData;
                        }
                    })
                    var cObj=vm.CampaignOwnerData.filter(x=>x.value==vm.CampOwner);  
                    var CampObjChild=null;
                    cObj.forEach(item=>{
                        CampObjChild={
                            label:item.name,
                            value:item.value
                            }
                        })
                    vm.FormSchema[0].Data[1].value=CampObjChild;
                }
            });
        },
        async GetCampaignTypes() {
            var vm = this;
            var url = `id=&moduleName=CRM&type=CRM_CAMPAIGN_TYPES&search=&refId=`;
            await DataServices.getDDLData(url).then(function (response) {
                vm.CampaignTypeList = [];
                if(response.data !=''){
                    var types = response.data.DATA;
                    types.forEach((item, index) => {
                        vm.CampaignTypeList.push({
                            name: item.name,
                            value: item.value
                        });
                    });
                }
            });
        },
        async GetSMSTemplate() {
            var vm = this;
            var url = `id=&moduleName=CRM&type=CRM_SMS_TEMPLATE&search=&refId=`;
            await DataServices.getDDLData(url).then(function (response) {
                vm.FormSchema[2].Data[0].config.options = [];
                if(response.data !=''){
                    var templates = response.data.DATA;
                    templates.forEach((item, index) => {
                        vm.FormSchema[2].Data[0].config.options.push({
                            name: item.name,
                            value: item.value
                        });
                    });
                }
            });
        },
        async onSubmit(formfields) {
            var VueObj = this;
            VueObj.isLoading = true;
            var data = [];
            var fullJson = VueObj.CampaignData;
            $.each(fullJson, function (key, item) {
                if (item.name == 'campaign_name') {
                    item.value = formfields.campaign_name;
                }
                else if (item.name == 'campaign_owner') {
                    item.value = formfields.campaign_owner;
                }
                else if (item.name == 'campaign_description') {
                    item.value = formfields.campaign_description;
                }
                else if (item.name == 'campaign_type') {
                    item.value= VueObj.CampaignTypeList.filter(x=>x.name.toLowerCase()==VueObj.campaignType)[0].value;
                }
                else if (item.name == 'status_id') {
                    item.value = VueObj.statusId;
                } else if (item.name == 'template_id') {
                    item.value = formfields.Template;
                }
                var fieldValue = (item.name == "campaign_id" ? VueObj.CampaignId : item.value);
                if (item.name == "campaign_id" && VueObj.reqType=='Clone') {
                    fieldValue = 0;
                }
                if (item.dt_code == "date") {
                    fieldValue = item.value;
                }
                if (item.dt_code == "checkbox") {
                    var filtered = item.select_value.filter(function (el) {
                        return (el != null && el != "");
                    });
                    fieldValue = filtered.join(',');
                }
                if (item.dt_code == "select") {
                    try {
                        fieldValue = (typeof fieldValue === 'object' && fieldValue !== null) ? fieldValue.value : fieldValue
                    } catch (ex) { console.log(ex) }
                }
                var dataObject = {
                    field_name: item.name,
                    field_value: fieldValue,
                    custom_field_id: item.custom_field_id
                };
                data.push(dataObject);
            });
            var postJSON = {
                data: data,
            };
            var objectPostString = JSON.stringify(postJSON);
            await DataServices.PostCampaign(objectPostString).then(function (response) {
                VueObj.isLoading = false;
                if (response.data[0].Code == "1") {
                    if (VueObj.CampaignId > 0 && VueObj.reqType=='Update') {
                        VueObj.ShowAlert(VueObj.$t('UpdatedSuccess', [VueObj.$t('Campaign')]), "success", true, VueObj.$t("Alert"));
                        if(VueObj.$parent.reqForm!='wizard'){
                            VueObj.$parent.$parent.CampaignId = response.data[0].Value;
                            VueObj.$parent.$parent.ShowManageSMSSchedule =true;
                            VueObj.$parent.onCancel();
                        }
                    }
                    else if (VueObj.CampaignId > 0 && VueObj.reqType=='Clone') {
                        VueObj.ShowAlert(VueObj.$t('CloneSuccess', [VueObj.$t('Campaign'),formfields.campaign_name]), "success", true, VueObj.$t("Alert"));
                        VueObj.$parent.onCancel();  
                    }
                    else {
                        VueObj.ShowAlert(VueObj.$t('AddedSuccess', [VueObj.$t('Campaign')]), "success", true, VueObj.$t("Alert"));
                        if(VueObj.$parent.reqForm!='wizard'){
                            VueObj.$parent.$parent.CampaignId = response.data[0].Value;
                            VueObj.$parent.$parent.ShowManageSMSSchedule =true;
                            VueObj.$parent.onCancel();
                        }else if(VueObj.$parent.reqForm == 'wizard'){
                            VueObj.showModalManageSMSSchedule = true;
                        }
                    }
                    if(VueObj.$parent.reqForm!='wizard'){
                        VueObj.$parent.$parent.FetchData();
                    }
                }
                else {
                    VueObj.ShowAlert(VueObj.$t('AddedError', [VueObj.$t('Campaign')]), "failure", true, VueObj.$t("Alert"));
                }
            });           
        },
    },
}
</script>
